import * as React from 'react';
import { RadialGauge } from 'canvas-gauges';
import { Gauge } from '../../../_models/';
import './Gauge.css';
import { forEach, includes, isNaN } from 'lodash';
import { DateTool, DrawGauge } from '../../../_helpers';
import * as content from '../../../content/content.json';

interface IModalProps {
	index: number;
}

export class GaugeComponent extends React.Component<IModalProps> {
	gauge?: RadialGauge;
	types?: object[];

	constructor(props: any) {
		// Props need to include chardCurrent Value, title of the graph,
		// max value, unit of the value, and the tricks color
		super(props);
		this.state = {
			variation: undefined,
			lastUpdate: 0,
			content: content.pages.dashboard.en
		}
	}

	public componentDidUpdate(prevProps: any): void {
		if (prevProps !== this.props) {
			this.gauge = undefined;
			this.drawGauge();
			let {dashboard}: any = this.props;
			this.setState({
				dashboard
			})
		}
	}

	componentDidMount(): void {
		this.drawGauge();
	}

	public componentWillMount(): void {
		const {dashboard, index}: any = this.props;
		const {lastMonthPlantData, latestPlantData}: any = dashboard.dataPreview[index];
		const lastMonthVal: any = (lastMonthPlantData || {} as any).dataValue;
		const value: any = (latestPlantData || {} as any).dataValue;

		this.setState({
			dashboard,
			variation: (value - lastMonthVal) / lastMonthVal * 100
		})
	}

	// TODO: Refactor Me. I am a monster of ugliness
	private drawGauge(): void {
		const {content}: any = this.state;
		const {index, code, dashboard}: any = this.props;
		const {latestPlantData, operationLimit, tachoMinValue, tachoMaxValue}: any = dashboard.dataPreview[index];
		const value: any = (latestPlantData || {} as any).dataValue;
		let maxValue = parseFloat((operationLimit || {} as any).maxValue) || 0;
		let minValue = parseFloat((operationLimit || {} as any).minValue) || 0;

		let canvas1 = document.createElement('canvas');
		canvas1.setAttribute("class", "canvas1");

		//draw the needle but hide the default guage by setting the color transparent
		let maxPoint: number = Number(tachoMaxValue);
		let minPoint: number = Number(tachoMinValue);
		let gaugesType = [{"from": minPoint, "to": maxPoint, "color": "transparent"}];
		let linearGaugeOptions = new Gauge(canvas1, value, minPoint, maxPoint, gaugesType);

		//add overlay gradience on top
		let canvas2: any = document.createElement('canvas');
		let context2: any = canvas2.getContext('2d');
		canvas2.setAttribute("class", "canvas2");
		let centerX = 151 / 2 + 50;
		let centerY = 151 / 2 + 12; //create extra room if min or max text on the center top positon of gauge
		let radius = 65;
		let grad1 = DrawGauge.assignLinearGradient(context2, centerX, centerY, radius);
		let grad2 = DrawGauge.assignLinearGradient(context2, centerX, centerY, radius);
		let grad3 = DrawGauge.assignLinearGradient(context2, centerX, centerY, radius);
		let grad4 = DrawGauge.assignLinearGradient(context2, centerX, centerY, radius);
		let grad5 = DrawGauge.assignLinearGradient(context2, centerX, centerY, radius);
		let colorArr1 = ['#999999', '#999999'];
		let colorPoint1 = [0, 1];
		let colorArr2 = ['#6297ef', '#6cd7e9', '#8bdbc5', '#c3f249', '#23bd3b'];
		let colorPoint2 = [0, 0.52, 0.73, 0.88, 1];
		let colorArr3 = ['#ef7962', '#dae376', '#8cdac7', '#6cd7e9', '#8bdbc5', '#f2ec49', '#f0755a'];
		let colorPoint3 = [0, 0.13, 0.29, 0.52, 0.73, 0.88, 1];
		let colorArr4 = ['#6297ef', '#6cd7e9', '#8bdbc5', '#c3f249', '#23bd3b'];
		let colorPoint4 = [0, 0.52, 0.73, 0.88, 1];
		let colorArr5 = ['#ef7962', '#efe661', '#c2ef61', '#23bd3b'];
		let colorPoint5 = [0, 0.07, 0.15, 1];
		DrawGauge.addStop(grad1, colorArr1, colorPoint1);
		DrawGauge.addStop(grad2, colorArr2, colorPoint2);
		DrawGauge.addStop(grad3, colorArr3, colorPoint3);
		DrawGauge.addStop(grad4, colorArr4, colorPoint4);
		DrawGauge.addStop(grad5, colorArr5, colorPoint5);
		let gradArr: any = [grad1, grad2, grad3, grad4, grad5];
		context2.beginPath();
		context2.arc(centerX, centerY, radius, -Math.PI, 0, false);
		context2.lineWidth = 5;
		context2.strokeStyle = gradArr[index];
		context2.stroke();

		//draw white gap to display min, max and warranty
		let minPercentage: any;
		let maxPercentage: any;
		let minAngle, maxAngle;

		// draw warranty for corresponding gauges as needed:
		if (code == "Ser_EB_Conversion" || code == "Ser_EB_MOL_Conversion" || code == "Ser_EB_MOL_WT_Conversion") {
			minPercentage = minValue == undefined ? NaN : ((minValue - minPoint) / (maxPoint - minPoint));
			maxPercentage = maxValue == undefined ? NaN : ((maxValue - minPoint) / (maxPoint - minPoint));
			minAngle = Math.PI + minPercentage * Math.PI;
			maxAngle = Math.PI + maxPercentage * Math.PI;
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, minAngle, true);
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, maxAngle, true);
		} else if (code == "Ser_SM_Selectivity" || code == "Ser_SM_MOL_Selectivity" || code == "Ser_SM_MOL_WT_Selectivity") {
			minPercentage = minValue == undefined ? NaN : ((minValue - minPoint) / (maxPoint - minPoint));
			minAngle = Math.PI + minPercentage * Math.PI;
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, minAngle, true);
		} else if (code == "Ser_SM_Prod_Rate" || code == "Ser_SM_MOL_Prod_Rate" || code == "Ser_SM_MOL_WT_Prod_Rate") {
			minPercentage = minValue == undefined ? NaN : ((minValue - minPoint) / (maxPoint - minPoint));
			maxPercentage = maxValue == undefined ? NaN : ((maxValue - minPoint) / (maxPoint - minPoint));
			minAngle = Math.PI + minPercentage * Math.PI;
			maxAngle = Math.PI + maxPercentage * Math.PI;
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, minAngle, true);
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, maxAngle, true);
		} else if (code == "Ser_STO") {
			minPercentage = minValue == undefined ? NaN : ((minValue - minPoint) / (maxPoint - minPoint));
			minAngle = Math.PI + minPercentage * Math.PI;
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, minAngle, true);
		} else {
			maxPercentage = minValue == undefined ? NaN : ((maxValue - minPoint) / (maxPoint - minPoint));
			maxAngle = Math.PI + maxPercentage * Math.PI;
			DrawGauge.generateWhiteGap(context2, centerX, centerY, radius, maxAngle, true);
		}

		// draw text min, max and warranty
		context2.font = "bold 12px Arial";
		context2.fillStyle = "black";
		let baseVal = [centerX, centerY, radius];
		if (code == "Ser_EB_Conversion" || code == "Ser_EB_MOL_Conversion" || code == "Ser_EB_MOL_WT_Conversion") {
			let xyMin = DrawGauge.getXY(minAngle, minPercentage, baseVal, true);
			let xyMax = DrawGauge.getXY(maxAngle, maxPercentage, baseVal);
			context2.fillText(content.warranty, xyMin[0], xyMin[1]);
			context2.fillText(content.max, xyMax[0], xyMax[1]);
		} else if (code == "Ser_SM_Selectivity" || code == "Ser_SM_MOL_Selectivity" || code == "Ser_SM_MOL_WT_Selectivity") {
			let xyMin = DrawGauge.getXY(minAngle, minPercentage, baseVal, true);
			context2.fillText(content.warranty, xyMin[0], xyMin[1]);
		} else if (code == "Ser_SM_Prod_Rate" || code == "Ser_SM_MOL_Prod_Rate" || code == "Ser_SM_MOL_WT_Prod_Rate") {
			let xyMin = DrawGauge.getXY(minAngle, minPercentage, baseVal, true);
			let xyMax = DrawGauge.getXY(maxAngle, maxPercentage, baseVal);
			context2.fillText(content.min, xyMin[0], xyMin[1]);
			context2.fillText(content.max, xyMax[0], xyMax[1]);
		} else if (code == "Ser_STO") {
			let xyMin = DrawGauge.getXY(minAngle, minPercentage, baseVal, true);
			context2.fillText(content.min, xyMin[0], xyMin[1]);
		} else {
			let xyMax = DrawGauge.getXY(maxAngle, maxPercentage, baseVal);
			context2.fillText(content.max, xyMax[0], xyMax[1]);
		}

		context2.closePath();

		this.gauge = new RadialGauge(linearGaugeOptions);
		if ((this.refs.gauge as HTMLDivElement).hasChildNodes()) {
			forEach((this.refs.gauge as HTMLDivElement).childNodes, (el: any) => {
				if (el) {
					(this.refs.gauge as HTMLDivElement).removeChild(el);
				}
			})
		}
		(this.refs.gauge as HTMLDivElement).appendChild(this.gauge.options.renderTo as HTMLDivElement);
		(this.refs.gauge as HTMLDivElement).appendChild(canvas2 as HTMLDivElement);
		this.gauge.draw();

		/* 2 gauge appear after gauge data update
		   - the guage has class .canvas1
		   - initial mode:  chlild nodes class of this.guage are .canva2, .canvas1, .canvas2
		   - updated mode: chlild nodes class of this.guage are .canva1, .canvas1, .canvas2
		   so when detect it is at updated mode by detecting the class of first and sec node,
		   we can remove the first element with .canvas1, so one gauge left
		  */
		let gaugeRef = this.refs.gauge as HTMLDivElement;
		let firstChild: any = gaugeRef.childNodes[0];
		let secChild: any = gaugeRef.childNodes[1];
		if (firstChild.className == "canvas1" && secChild.className == "canvas1") {
			gaugeRef.removeChild(firstChild);
		}
		if (firstChild.className == "canvas2" && secChild.className == "canvas1") {
			gaugeRef.removeChild(firstChild);
		}
	}

	render() {
		let {variation}: any = this.state;
		let {name, latestPlantData, operationLimit, content}: any = this.props;
		let {lastModifiedDate}: any = latestPlantData || {} as any;
		let value: any = latestPlantData && latestPlantData.dataValue;
		let props: any = this.props;
		let unit: any = props.unit || "";
		let serialCode: any = latestPlantData && latestPlantData.serialCode;


		variation = parseFloat((Math.round(variation * 100) / 100).toString()).toFixed(2);
		value = parseFloat((Math.round(value * 100) / 100).toString()).toFixed(1);
		let isValueInRed: boolean = false;
		if (operationLimit.code == "ol_selectivity" && parseFloat(operationLimit.minValue) > value) {
			isValueInRed = true;
		} else if (parseFloat(operationLimit.maxValue) < value) {
			isValueInRed = true;
		}
		// "lastUpdate": "Last updated",
		// "hoursago": "hours ago",
		// "hoursago": "No change from last month",
		// "fromLastMonth": "from last Month"
		const {lastUpdate, hoursago, noChange, fromLastMonth} = content;

		return (
			<div className="single-gauge-outer-container">
				<div className="single-gauge-container">
					<h4> {name} </h4>
					<div className="update"> {lastUpdate} : {DateTool.timeBetween(Date(), lastModifiedDate, "hour")} {hoursago} </div>
					<div className="single-gauge">
						<div id="gauge"
						     ref="gauge"/>
						<div className={'gauge-value-container ' + (isValueInRed ? 'hight-value' : '')}>
							<div className='gauge-value'>{includes(serialCode, 'Average') ? Math.ceil(value) : value}<span>{unit}</span></div>
							<div className="variation">
								{(parseInt(variation) === 0) && <p> {noChange} </p>}
								{(parseInt(variation) !== 0 && !isNaN(parseInt(variation))) && <p> {Math.abs(variation)} % {(variation > 0) ? '↑' : '↓'} {fromLastMonth} </p>}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
