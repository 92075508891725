import { BaseClass } from './BaseClass';
import * as moment from 'moment';
import { DateTool } from '../_helpers';
import { forEach, isUndefined, filter, orderBy } from 'lodash';

export class CommentContainer {
  comments!: Comment[];
  plantFirstDay!: any;
  unReadComments!: Comment[];
  constructor(catalystIgnitionDetails:any, comments:any){
    let listComments :Comment[] = [];
    forEach(orderBy(comments, ['readInd', 'createdDate'], ['asc', 'desc']), (item:any) => {
      let newComment :Comment = new Comment(catalystIgnitionDetails,item);
      listComments.push(newComment);
    });
    this.comments = listComments;
    this.getUnreadComments();
  }
  public filterComment(subCategoryCode:string) :Comment[] {
    return filter(this.comments, (comment: Comment) => {
      return subCategoryCode === comment.subCategoryCode;
    })
  }
  private getUnreadComments(): void {
    this.unReadComments = filter(this.comments, (comment: Comment) => {
      return !comment.readInd;
    })
  }
}

export class Comment extends BaseClass{
  id!: number;
  subject!: string;
  body!: string;
  imageURL!: string;
  categoryCode!: string;
  plantDataId!: number;
  categoryName!: string;
  subCategoryCode!: string;
  subCategoryName!: string;
  starStatus!: string;
  status!: string;
  postUserName!: string;
  postUserRoleName!: string;
  plantCode!: string;
  postUserId!: number;
  postUserType!: string;
  dataDate!: string;
  serialName!: string;
  serialCode!: string;
  readInd!: boolean;
  creationDate!: string;
  fullCreationDate!: string;
  favoriteIcone!: string;
  dayOfComment!: number;
  constructor(catalystIgnitionDetails:any,data: any){
    super();
    this.id = data.id ;
    this.body = data.body;
    this.imageURL = data.imageURL;
    this.subject = data.subject;
    this.categoryCode = data.categoryCode;
    this.categoryName = data.categoryName;
    this.subCategoryCode = data.subCategoryCode;
    this.subCategoryName = data.subCategoryName;
    this.plantCode = data.plantCode;
    this.plantDataId = data.plantDataId;
    this.postUserId = data.postUserId;
    this.postUserName = data.postUserName;
    this.postUserType = data.postUserType;
    this.readInd = data.readInd;
    this.serialCode = data.serialCode;
    this.serialName = data.serialName;
    this.starStatus = data.starStatus;
    this.status = data.status;
    this.dataDate = data.dataDate;
    this.createdDate = data.createdDate;
    let date = moment(new Date(data.createdDate.toString().replace(/ /, 'T')+'Z'));
    this.creationDate = date.format('MM-DD');
    this.fullCreationDate = date.format('YYYY-MM-DD HH:mm');
    this.dayOfComment = data.dataDate && DateTool.getDayOnStream(catalystIgnitionDetails, data.dataDate);
    this.favoriteIcone = this.getFavoriteIcone(data.starStatus);
    this.postUserRoleName = data.postUserRoleName;
  }
  public getFavoriteIcone(status: string) :string {
    return (!status || status === 'U' || isUndefined(status) ) ? '/assets/images/btn-unstared.png' : '/assets/images/btn-stared.png';
  }
  public setFavoriteIcone(status: string) :void {
    this.favoriteIcone = (!status || status === 'U' || isUndefined(status) ) ? '/assets/images/btn-unstared.png' : '/assets/images/btn-stared.png';
  }
  public setFavoriteStatus(status: string) :void {
    this.starStatus = (!status || status === 'U') ? 'S' : 'U';
    this.setFavoriteIcone(this.starStatus)

  }
  public getPlantType() :string {
    if(this.subCategoryName){
      return this.subCategoryName
    }else {
      let subCategoryName: string = this.subCategoryCode === 'csc_recommendations' ? 'Miscellaneous' : this.subCategoryCode;
      return (subCategoryName || this.plantCode).replace(/^[a-z]+_/, '')
    }

  }
  public getUserAcronmy() :string {
    return this.postUserType === 'B' ? 'BASF' : this.postUserType
  }
}
