import * as React from 'react';
import './SpecificComment.css';
import { isEmpty } from 'lodash';
import { CommentService } from '../../_services';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';


interface IModalProps {
    handleUpdateContent: (addComment: boolean, updatedContent: boolean) => void;
    handleInterprateComment: (status: any) => Promise<void>;
}

class SpecificComment extends React.Component<IModalProps>{
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props: any)  {
        super(props);
    }

    private addfavoriateComment(commentId: number, status: any): any {
        const {match}: any = this.props;
        // console.log("selectedComment starStatus : " ,selectedComment.starStatus)
        // selectedComment.setFavoriteStatus(!status || status === 'U' ? 'S' : undefined)
        // console.log("addfavoriateComment",selectedComment)
        // console.log("current status : ", status, "  update status : ", !status || status === 'U' ? 'S' : undefined)
        this.updatedContent(status);
        CommentService.addFavoriteComment(match.params.id, commentId).then(() => {

        })
    }

    private updatedContent(status: any): void {
        const {handleInterprateComment}: any = this.props;
        handleInterprateComment(status);
    }

    render() {
        const { selectedComment }: any = this.props;

        if (isEmpty(selectedComment)) {
            return (
                <div className="specific-comment"></div>
            )
        }

        selectedComment.body = selectedComment.body.replace(/<br\/>/gi, '\n');

        return (
            <div className="specific-comment">
                <div>
                    <span className="comment-user">{ selectedComment.getUserAcronmy() }</span>
                    <span className="comment-title">{ selectedComment.getPlantType() }</span>
                    {selectedComment.serialName &&
                        <span className="comment-title small-left-margin">{ selectedComment.serialName }</span>
                    }
                    <div className="right">
                        <img src={selectedComment.favoriteIcone}
                             alt=""
                             onClick={ () => this.addfavoriateComment(selectedComment.id, selectedComment.starStatus) }/>
                    </div>
                </div>
                <div className="title"><h3>{ selectedComment.subject}</h3></div>
                <div className="date">
                    { selectedComment.dataDate && <span> D-{ selectedComment.dayOfComment } / </span> }
                    { selectedComment.dataDate }
                </div>
                <br/>
                <div className="source">Comment from <span>{ selectedComment.postUserRoleName }</span>: { selectedComment.postUserName }</div>
                <div className="source">Comment added : { selectedComment.fullCreationDate }</div>
                <div className="comment-body">
                    <p>{ selectedComment.body }</p>
                    { selectedComment.imageURL &&
                        <img alt="" src={selectedComment.imageURL} />
                    }
                </div>
          </div>
        )
    };
};


const SpecificCommentRouted = withRouter(SpecificComment as React.ComponentType<any>);
export { SpecificCommentRouted as SpecificComment };
