import { forEach, find } from 'lodash';

export class SelectSubCategory {
  elements: object[] = [];
  currentElement!: object;
  constructor(categories: any, type?: string) {
    forEach(categories, (category) => {
      category.subCategoryList.map((subcategory:any) => {
        this.elements.push({label: subcategory.code === 'csc_recommendations' ? 'Miscellaneous' : subcategory.name, value: subcategory.code, category: category.code})
      })
    })
    this.elements.push({label: 'All', value: 'All'});
    this.currentElement = find(this.elements, {value: type || 'All'}) || {};
  }
}
