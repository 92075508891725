import * as React from 'react';
import './UserProfile.css';
import { first } from 'lodash';

interface IModalProps {
  handleUpdateProfile: () => void;
};

export class UserProfile extends React.Component<IModalProps> {
  constructor(props:any) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user') || '');
    this.state = {
      user: user,
      name: user.name,
      role: first(user.roles)
    };
    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount() :void {
  }

  private openUpdateProfile() :void {
    const { handleUpdateProfile }: any = this.props;
    handleUpdateProfile();
  }

  private handleChange(event:any) :void {
    const target :any = event.target;
    this.setState({
      [target.name]: event.target.value
    });
  }

  render() {
    const { user, role }: any = this.state;
    return (
      <div className="account-update-popup">
        <div className="account-creation-popup-header admin-popup-title">
          Profile
          <img className="close-popup" src="/assets/images/btn-close-white.png" onClick={() => this.openUpdateProfile()}/>
        </div>
        <div className="account-creation-popup-form">
          <div className="account-creation-popup-form-fields with-border-bottom-grey inline">
              <div className='select-user-type select-user-type-no-border'>
                { user.type === 'B' ?
                  <span><span className="basf-first">B</span> BASF</span>
                  :
                  <span><span className="customer-first">C</span> Customer</span>
                }
              </div>
          </div>
          <div className="grey-separation some-margin-select-user-type"></div>
          <div className="account-creation-popup-form-fields">
            <p className="account-creation-popup-form-label">Account email (Unchangeable):</p>
            <input type="email" name="email" value={user.email}
              className='account-creation-popup-input-big readonly' readOnly/>
          </div>
          <div className="account-creation-popup-form-fields inline">
            <div  className="inlineBlock">
              <p>Role:</p>
              <input type="text" name="role" value={role.name}
                className='account-update-popup-fields readonly' readOnly/>
            </div>
            <div className="relative normal-input-field inlineBlock">
              <p>Expire Date: </p>
              <input type="text" name="role" value={user.expireDate}
                className='account-update-popup-fields readonly' readOnly/>
            </div>
          </div>
          <p className="account-creation-popup-form-fields">
          <p>Note:</p>
          { user.type === 'C' && <p>If you want to update your profile or change password, please <u><a href="https://external-profile-management.basf.com/mydata/indexui5.html#SubSct3" target="_blank">click here</a></u>.</p> }
          <p>If you want to change your role or due date, please <u><a href="mailto:my-catalysts@basf.com">contact administrator</a></u>.</p>
          </p>
        </div>
      </div>
    );
  }
}
