import * as React from 'react';
import './Loader.css';
import { Redirect, withRouter } from 'react-router';
import * as PropTypes from 'prop-types';

interface IModalProps {
  isLoading: boolean;
  code: any;
}

class Loader extends React.Component<IModalProps>{
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor(props:any) {
    super(props);
  }

  public componentWillReceiveProps(nextProps: any) :void {
    if(nextProps.code === 802) {
      localStorage.clear();
    }
  }

  render() {
    const { isLoading, code } :any = this.props;
    return (
      <div>
        { code === 802 &&
          <Redirect to={{pathname: "/login", state: {message: 'expired'}}} />
        }
        { isLoading &&
          <div className="loader-background">
             <div className="lds-ripple">
               <div></div>
               <div></div>
             </div>
          </div>
        }
       </div>
    );
  }
}

const LoaderRouted = withRouter(Loader as React.ComponentType<any>);
export { LoaderRouted as Loader };
