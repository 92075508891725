import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from "history";

import { store } from './_helpers';
import { Ressource, GetUser, ErrorHandler } from './_helpers';
import { App } from './App';

import { PlantService } from './_services';
import { UserActions } from './_actions';

// console.log("Current URL = " + window.location.href )

// initialize App Insights  
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let appInsights = null;

if (!window.location.href.startsWith("http://127.0.0.1")) {
	const aiResourceUrl = new Ressource('/frontendconfig');
	fetch(aiResourceUrl.fullUrl).then(async (response) => {
		if (response.ok) {
			const resp = await response.json();
			if (resp.app_insights_key) {
				appInsights = new ApplicationInsights({
					config: {
						instrumentationKey: resp.app_insights_key,
						extensions: [reactPlugin],
						extensionConfig: {
							[reactPlugin.identifier]: {history: browserHistory}
						}
					}
				});
				appInsights.loadAppInsights();
				console.info('Successfully initialized Application Insights.');
			} else {
				console.warn('Received wrong Application Insights config response.');
			}
		} else {
			console.warn('Failed to load Application Insights.');
		}

	}).catch(() => {
		console.warn('Failed to load Application Insights.');
	});

	ErrorHandler.handleErrors();
	const token: string = GetUser.getQueryParameterByName('token');
	if (token) {
		UserActions.authenticateToken(token).then((resp:any) => {
			if (resp.response == 'success') {
				PlantService.getAllEnrich(resp.token).then((response: any) => {
					if (response.code == 200 && response.message == 'SUCCESS') {
						localStorage.setItem('plants', JSON.stringify(response.data));
						if (typeof window !== undefined) window.location.href = '/plants/' + (((response.data || [])[0]) || {} as any).code + '/dashboard';
					}
				})
			}
		})
	}
} else {
	console.info('Application Insights not initiated for local deployment.');
}

render(
	<Provider store={store}>
		<App/>
	</Provider>,
	document.getElementById('app')
);
