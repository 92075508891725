import * as React from 'react';
import './BreadCrumb.css';
import {pick, values, last, isEmpty, find} from 'lodash';
import {breadCrumbConstant} from '../../../_constants';

export class BreadCrumb extends React.Component {
	constructor(props: any) {
		super(props);

		let plant: any = this.getCurrentPlant()
		this.state = {
			menu: this.getCurrentLocation(),
			plant: plant,
			currentCategory: this.getCurrentCategory(plant)
		};

		this.getCurrentLocation = this.getCurrentLocation.bind(this);
		this.getCurrentCategory = this.getCurrentCategory.bind(this);
	}

	public componentDidMount(): void {
		this.getCurrentLocation();
		this.replaceByProperValue();
	}

	componentWillReceiveProps(): void {
		const {currentCategory}: any = this.state
		const latestCategory = this.getCurrentCategory(this.getCurrentPlant())

		if (currentCategory === latestCategory) {
			return
		} else {
			this.setState({
				plant: this.getCurrentPlant(),
				currentCategory: latestCategory
			});
			this.replaceByProperValueNew(latestCategory)

		}
	}

	// TODO: make me prettier later we had to make
	// this special case for recommendations
	private getCurrentLocation(): string[] {
		const {match}: any = this.props;
		let currentLocation: any[];

		if (match.params.category) {
			if (match.params.type) {
				currentLocation = values(pick(match.params, ['type', 'category']));
			} else {
				currentLocation = ['Recommendation'];
				currentLocation.push(values(pick(match.params, ['category']))[0].replace('cc_', ''));
			}
		} else {
			let test = !isEmpty(last(match.path.split('/'))) && last(match.path.split('/')) || '';
			currentLocation = breadCrumbConstant.menuItem[test as string];
		}

		return currentLocation;
	}

	private deemFirstElement(index: number): string {
		return index === 0 ? 'first' : 'secondaryElement';
	}

	// TODO: move me to helper tools
	private getCurrentPlant(): void {
		const {match}: any = this.props;
		let plants: any = JSON.parse(localStorage.getItem('plants') || '')

		return find(plants, (item: any) => {
			return item.code === match.params.id;
		});
	}

	private getCurrentCategory(plant: any): void {
		const {match}: any = this.props;

		return find(plant.categoryList, (category: any) => {
			return category.code === match.params.category;
		});
	}

	private replaceByProperValue(): any {
		const {currentCategory}: any = this.state;
		const {match}: any = this.props;

		if (!match.params.type) {
			return;
		}

		let menu: string[] = [];
		menu.push(find(currentCategory.subCategoryList, (submenu: any) => {
			return submenu.code === match.params.type;
		}).name);
		menu.push(currentCategory.name);

		this.setState({
			menu: menu
		})
	}

	private replaceByProperValueNew(currentCategory: any): any {
		const {match}: any = this.props;

		if (!match.params.type) {
			return;
		}

		let menu: string[] = [];
		menu.push(find(currentCategory.subCategoryList, (submenu: any) => {
			return submenu.code === match.params.type;
		}).name);
		menu.push(currentCategory.name);

		this.setState({
			menu: menu
		})
	}

	render() {
		const {menu}: any = this.state;
		const {additionalText}: any = this.props;

		return (
			<React.Fragment>
				<ol className="breadcrumb">
					{menu.map((item: string, idx: number) => {
							return (
								<li key={'bread_' + idx}
									className={this.deemFirstElement(idx)}>
									<span>{item}</span>
								</li>
							);
						})
					}
				</ol>
				<span className="breadcrumb-additionalText">{additionalText}</span>
			</React.Fragment>
		);
	}
}
