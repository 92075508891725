import {BaseService} from './base.services'
import {Plant, CustomerAndPlant} from '../_models';
import {Ressource} from '../_helpers';

export class PlantService extends BaseService {

	static async getAllEnrich(token?: string): Promise<Plant[]> {
		const ressource = new Ressource();
		ressource.shapeUrl('/plant/list/detail', []);

		return this.getAxios
            .get(ressource.fullUrl, this.requestOptionsGet(token))
			.then((item: any) => {
				return item.data;
			})
	}

	static async getAllCustomersAndPlants(token?: string): Promise<CustomerAndPlant[]> {
		const ressource = new Ressource();
		ressource.shapeUrl('/user/plants', []);

		return this.getAxios
            .get(ressource.fullUrl, this.requestOptionsGet(token))
			.then((item: any) => {
				return item.data;
			})
	}

}
