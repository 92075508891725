import React from 'react';
import Highcharts from 'highcharts/highstock';
import {
    HighchartsStockChart,
    Chart,
    withHighcharts,
    XAxis,
    YAxis,
    SplineSeries,
    Navigator,
    RangeSelector,
    Tooltip,
    PlotLine,
    Scrollbar
} from 'react-jsx-highstock';
import moment from 'moment';
import {
    first, last, groupBy, map, keys, forEach, maxBy, minBy,
    find, max, min, isUndefined, cloneDeep
} from 'lodash';
import './LinearChartNotTs.css';
import {crosshair} from '../../_constants';
import {toolTipStyle} from '../../_constants';
import {hexToRgb} from '../../_helpers';
import {Loader} from '../../_components/Common/Loader/Loader';

require('highcharts/modules/exporting')(Highcharts);
import DateRangePickers from './DateRangePickers';


class LinearChartNotTs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: undefined,
            leftSeries: undefined,
            rightSeries: undefined,
            graphData: props.graphData,
            device: window.innerWidth < 1180 ? "ipad" : "desktop",
            showPopupChoise: false,
            zoomOptions: props.graphData.zoomOptions,
            indexSelector: undefined,
            from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
        // console.log('LinearChartNotTs.constructor()', this.state);

        this.getData = this.getData.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.renderSeries = this.renderSeries.bind(this);
        this.getChart = this.getChart.bind(this);
        this.renderSelectOptions = this.renderSelectOptions.bind(this);
        this.exporting = this.exporting.bind(this);
    }

    componentDidMount() {
        const {graphData} = this.state;
        this.getData(graphData);
        this.loadStatic();
    }

    componentWillReceiveProps(nextProps) {
        let firstDay = moment().subtract(nextProps.graphData.selectedOption.count, 'd').format('YYYY-MM-DD');
        let toDay = moment().format('YYYY-MM-DD');

        if (nextProps.graphData && nextProps.graphData.data && nextProps.graphData.data.length === 0) {
            firstDay = nextProps.currentPlant.ignitionDateStart;
        } else if (nextProps.graphData.selectedOption.type === 'all') {
            let startDate = min(nextProps.graphData.data, 'firstDay');
            if (startDate != null && startDate != undefined) {
                firstDay = startDate.firstDay.format('YYYY-MM-DD');
            }
        } else if (nextProps.graphData.selectedOption.startDate !== undefined) {
            firstDay = nextProps.graphData.selectedOption.startDate;
            toDay = moment(firstDay).add(nextProps.graphData.selectedOption.count, 'days').format('YYYY-MM-DD');
        }

        this.setState({
                graphData: nextProps.graphData,
                zoomOptions: nextProps.graphData.zoomOptions,
                from: firstDay,
                to: toDay
            }, () => {
                const {exporting} = nextProps;
                if (!exporting) return;

                let format = Object.keys(exporting)[0];
                this.exporting(format);
            }
        );

        this.getData(nextProps.graphData);
    }

    renderSeries({name, data, color, code}) {
        return (
            <SplineSeries id={code}
                          name={name}
                          key={code}
                          data={data}
                          onClick={this.handleClick}
                          color={color}/>
        );
    }

    generateNavigator(item) {
        return (
            <Navigator.Series seriesId={item}
                              key={item}/>
        );
    }

    renderSelectOptions({count, type, label, index}) {
        // console.log('renderSelectOptions()', count, type, label, index);
        return (
            <RangeSelector.Button count={count}
                                  index={index}
                                  type={type}>{label}</RangeSelector.Button>
        );
    }

    renderPlotLines({operationLimit, color, id}) {
        return (
            [operationLimit && operationLimit.name === "Conversion" ?
                <div>
                    <PlotLine value={operationLimit.minValue}
                              id={id}
                              color={color}
                              dashStyle="shortdash"
                              width={2}>
                        <PlotLine.Label color={color}/>
                    </PlotLine>
                    <PlotLine value={operationLimit.maxValue}
                              id={id}
                              color={color}
                              dashStyle="shortdash"
                              width={2}>
                        <PlotLine.Label color={color}/>
                    </PlotLine>
                </div>
                :
                <PlotLine value={operationLimit.valueType && operationLimit.valueType == "MIN" ? operationLimit.minValue : operationLimit.maxValue}
                          id={id}
                          color={color}
                          dashStyle="shortdash"
                          width={2}>
                    <PlotLine.Label color={color}/>
                </PlotLine>
            ]
        );
    }

    getTitle(groupedData, key) {
        const units = map(groupedData[key], 'unit');
        const names = map(groupedData[key], 'name');
        const colors = map(groupedData[key], 'color');

        let title = '';
        forEach(units, (el, idx) => {
            var r = hexToRgb(colors[idx]).r;
            var g = hexToRgb(colors[idx]).g;
            var b = hexToRgb(colors[idx]).b;
            title += '<span style="background-color:rgb(' + r + ',' + g + ',' + b + ',0.3); word-break: keep-all; padding:3px; margin-right:3px;color:' + colors[idx] + ';border-radius:3px;">' + names[idx] + '[' + units[idx] + ']</span>';
        });

        return title;
    }

    getData(graphData) {
        let groupByRange = groupBy(graphData.data, 'range');
        let firstKey = first(keys(groupByRange));
        let lastKey = keys(groupByRange).length > 1 && last(keys(groupByRange));

        let leftLimit = graphData.chartsLimit.filter((item) => {
            return item.range === firstKey
        });

        let rightLimit = graphData.chartsLimit.filter((item) => {
            return item.range === lastKey
        });

        this.setState({
            series: graphData.data,
            plotLines: graphData.chartsLimit,
            leftSeries: groupByRange[firstKey] || [],
            leftPlotLines: leftLimit,
            rightSeries: lastKey && groupByRange[lastKey],
            rightPlotLines: rightLimit,
            title: this.getTitle(groupByRange, firstKey),
            leftTitle: this.getTitle(groupByRange, firstKey),
            rightTitle: lastKey && this.getTitle(groupByRange, lastKey),
            allName: map(graphData.data, 'code'),
            lastDay: graphData.data && graphData.data.length && (maxBy(first(graphData.data).data, 'x') || {}).x,
            firstDay: graphData.data && graphData.data.length && (minBy(first(graphData.data).data, 'x') || {}).x
        });
    }

    // When we click on any point of the chart we should be able to edit the
    // a comment on it or see the current comments on it
    handleClick(e) {
        const {graphData} = this.props;
        const serialCode = (find(graphData.data, {'name': e.point.series.name}) || {});
        let date = moment(new Date(e.point.x));
        this.setGraphDataForComment(serialCode, date, e.point.id, !!e.point.description);
    }

    setGraphDataForComment(serialCode, date, pointId, create) {
        const {match, graphData, setCommentData, firstDay} = this.props;

        let data = {
            plantCode: match.params.id,
            categoryCode: match.params.category,
            subCategoryCode: match.params.type,
            plantDataId: pointId,
            serialCode: serialCode.code,
            dataDate: date.format('Y-MM-D'),
            selectedChart: {
                label: serialCode.name,
                value: serialCode.code,
                color: serialCode.color
            },
            create: create,
            firstDay: firstDay
        }
        setCommentData(data);
    }

    // TODO: move me somewhere else
    loadStatic() {
        const {match} = this.props;
        const formatter = function () {
            let numberOfComment = this.point.keys && this.point.keys[0] > 1 ? '<div style="font-size:12px; line-height:25px">' + this.point.keys[0] + ' Comments on this day</div>' : '';
            let comment = this.point.description ? '<div style="font-size:12px;line-height:15px; margin:3px 0px; padding:6px;background-color:rgba(255,255,255,0.1)!important;color:white">' + this.point.description + '</div>' : '';
            return '<div style="font-size:14px; line-height:25px">' + this.series.name.replace(/\[.{4,7}\]/, '') + '</div><div style="font-size:12px; line-height:22px"><span style="color:grey">Content ' + this.series.name.replace(/^[a-zA-Z]{4,24}/, '') + ' :  </span> ' + this.y.toFixed(3) + '</div><div style="font-size:12px; line-height:22px"><span style="color:grey">Day : </span> ' + new Date(this.x).getDate() + new Date(this.x).toLocaleString('en-us', {month: "short"}) + ' </div> ' + comment + numberOfComment;
        }
        this.setState({
            formatter: formatter
        })
    }

    //make chart available to call as object for export feature
    getChart = chart => {
        this.chart = chart;
    }

    exporting(format) {
        let {exporting} = this.props;
        // console.log("export", exporting)

        let imageType = format === "svg" ? "svg+xml" : format;
        let type = format === "pdf" ? "application/pdf" : "image/" + imageType;

        if (exporting && exporting[format]) {
            this.chart.exportChart({type: type, rangeSelector: {enabled: false}});
        }
        this.props.onResetExport();
    }

    render() {
        const {
            series, leftSeries, rightSeries, title, leftTitle, rightTitle, allName,
            formatter, graphData, navigators, indexSelector, zoomOptions, lastDay, firstDay,
            showPopupChoise, device, plotLines, leftPlotLines, rightPlotLines
        } = this.state;
        let {index, showMe} = this.props;

        let width = device === "ipad" ? window.innerWidth - 250 : window.innerWidth - 260;
        let height = device === "ipad" ? window.innerHeight - 165 : window.innerHeight - 165;

        if (graphData && graphData.data && graphData.data.length === 0) {
            height = height * 0.6;
        }

        let x = device === "ipad" ? -5 : -15;
        let y = device === "ipad" ? -10 : -25;

        if (!leftSeries && showMe === 'none') {
            return <Loader> </Loader>
        }

        // console.log('LinearChartNotTs.render()', firstDay, moment(firstDay).format('YYYY-MM-DD'), lastDay, moment(lastDay).format('YYYY-MM-DD'));

        return (
            <div className="app">
                <HighchartsStockChart callback={this.getChart}
                                      plotOptions={{series: {showInNavigator: true, turboThreshold: 2000}, column: {stacking: 'normal'}}}>
                    <Chart zoomType="xy"
                           width={width}
                           height={height}
                           marginTop={40}
                           resetZoomButton={{ zIndex: 9999 }}
                    />

                    <RangeSelector selected={isUndefined(index) ? indexSelector : index}
                                   enabled={false}
                    >
                        {zoomOptions.map(this.renderSelectOptions)}
                    </RangeSelector>

                    <DateRangePickers axisId="xAxis" {...this.state} {...this.props}/>

                    <Tooltip backgroundColor="#4e4e4e"
                             borderColor="none"
                             borderRadius="5"
                             split={false}
                             useHTML={true}
                             valueDecimals="3"
                             style={toolTipStyle}
                             formatter={formatter}/>

                    {firstDay !== undefined && firstDay !== 0 ?
                        <XAxis crosshair={crosshair}
                               min={firstDay}
                               max={lastDay}
                               startOnTick={false}
                               top={40}/> :
                        <XAxis crosshair={crosshair}
                               startOnTick={false}
                               top={40}/>
                    }


                    {!rightSeries &&
                        <YAxis crosshair={crosshair}
                               labels={{x: 18}}
                               top={40}>
                            <YAxis.Title useHTML
                                         align='high'
                                         textAlign='left'
                                         offset='0'
                                         rotation='0'
                                         reserveSpace={false}
                                         x={12}
                                         y={y}>{title}</YAxis.Title>
                            {series && series.map(this.renderSeries)}
                            {plotLines.map(this.renderPlotLines)}
                        </YAxis>
                    }

                    {rightSeries &&
                        <YAxis crosshair={crosshair}
                               labels={{x: 18}}
                               top={40}>
                            <YAxis.Title useHTML
                                         align='high'
                                         textAlign='left'
                                         offset='0'
                                         rotation='0'
                                         reserveSpace={false}
                                         x={12}
                                         y={y}>{leftTitle}</YAxis.Title>
                            {leftSeries && leftSeries.map(this.renderSeries)}
                            {leftPlotLines.map(this.renderPlotLines)}
                        </YAxis>
                    }

                    {rightSeries &&
                        <YAxis opposite
                               crosshair={crosshair}
                               labels={{x: 0}}
                               top={40}>
                            <YAxis.Title useHTML
                                         align='high'
                                         textAlign='right'
                                         offset='0'
                                         rotation='0'
                                         reserveSpace={false}
                                         x={x}
                                         y={y}>{rightTitle}</YAxis.Title>
                            {rightSeries && rightSeries.map(this.renderSeries)}
                            {rightPlotLines.map(this.renderPlotLines)}
                        </YAxis>
                    }

                    <Navigator height={height * 0.10}
                               margin={40}>
                        {allName.map(this.generateNavigator)}
                    </Navigator>
                    <Scrollbar enable={true}/>

                </HighchartsStockChart>
            </div>
        );
    }
}

export default withHighcharts(LinearChartNotTs, Highcharts);
