import * as React from 'react';
import { Redirect } from 'react-router-dom';

export class LogoutPage extends React.Component {
    constructor(props:any) {
        super(props);
        this.logOut()
    }

    logOut() :void{
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('plants');
    }

    render() {
        return (
          <div>
             <Redirect push to={{ pathname: '/login' }} />
          </div>
        );
    }
}
