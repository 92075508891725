import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { UserService } from '../../_services';
import { isEmpty, includes } from 'lodash';
import { Glyphicon } from 'react-bootstrap'
import './Login.css';
import * as content from '../../content/content.json';

const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

class ForgotPasswordPage extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }
    constructor(props:any) {
        super(props);
        this.state = {
            email: '',
            errors: [],
            serverError: undefined,
            success: false,
            serverSuccess: undefined,
            content: content.pages.login.en,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
    }

    private handleChange(e:any) :void{
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    private goToLogin() :void {
      const { history }: any = this.props;
      // console.log('we go to login');
      history.push('/login');
    }

    private handleSubmit(e: any): any{
      e.preventDefault();
      const { email } :any = this.state;
      let errors :string[] = [];
      if (isEmpty(email) || !re.test(email)) {
        errors.push('email');
      }
      this.setState({
        errors: errors
      })
      if (!isEmpty(errors)){
        return;
      }
      UserService.forgotPassword(email).then((resp: any) =>{
        if(resp.data.code !== 200) {
          this.setState({
            serverError: resp.data.message,
            errors: ['email'],
            success: false
          })
        } else {
          this.setState({
            serverSuccess: resp.data.message,
            success: true
          })
        }
      })
    }

    render() {
        const { email, errors, serverError, success, serverSuccess, content } :any = this.state;
        const { forgetpwd, leavemail,  pemail, b2login, requreemail, send }:any = content;

        return (
            <div className="col-md-6 col-md-offset-6 left-align welcome-container">
                <div className="go-to-login">
                  <p onClick={() => this.goToLogin()}> <Glyphicon glyph="chevron-left"/> <span className="go-to-login-text"> { b2login } </span> </p>
                </div>
                <div className="forgot-caption">
                  <h2> { forgetpwd } </h2>
                  <p> { leavemail }
                  </p>
                </div>
                <form name="form"  className="login-form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (includes(errors, 'email') ? ' has-error' : '')}>
                        <label htmlFor="username" className="label-basf-version login-email"> { pemail } </label>
                        <input type="text" className="form-control input-basf-version " name="email" value={email} onChange={this.handleChange} />
                        { includes(errors, 'email') &&
                            <div className="help-block"> { serverError || requreemail }</div>
                        }
                        { success &&
                            <div className="alert alert-success success-email"> { serverSuccess|| '' }</div>
                        }
                    </div>
                    <div className="form-group log-in-row">
                        <button className="btn btn-primary submit-basf-version"> {send} </button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state:any) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const ForgotPasswordPageRouted = withRouter(connect(mapStateToProps)(ForgotPasswordPage) as React.ComponentType<any>);
export { ForgotPasswordPageRouted as ForgotPasswordPage };
