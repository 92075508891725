import * as React from 'react';
import './SideBarLoggedOut.css';
import { Logo } from '../Common/Logo/Logo';

export class SideBarLoggedOut extends React.Component {
  constructor(props:any) {
    super(props);
  }

  render() {
    return (
      <div className="intro-container" >
          <Logo></Logo>
      </div>
    );
  }
}
