import * as content from '../content/content.json';

export class ErrorInfo {
	code!: string;
	headline!: string;
	message!: string;
	constructor(code:string) {
		const { headline, message }: any = this.getContent(code);
		this.code = code;
		this.headline = headline;
		this.message = message;
	}

	private getContent(code: string): any {
		if(code == "401" || code == "403"){
			return content.pages.error["401"];
		} else if (code == "404"){
			return content.pages.error["404"];
		} else if (code == "600"){
			return content.pages.error["600"];
		} else if (code == "601"){
			return content.pages.error["601"];
		} else if (code == "602"){
			return content.pages.error["602"];
		} else if (code == "610"){
			return content.pages.error["610"];
		} else if (code == "612"){
			return content.pages.error["612"];
		} else if (code == "810"){
			return content.pages.error["810"];
		} else {
			return content.pages.error["generic"];
		}
	}
}
