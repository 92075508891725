import React from 'react';
import Highcharts from 'highcharts/highstock';
require('highcharts/highcharts-more')(Highcharts);
import {
  HighchartsStockChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  AreaSplineRangeSeries,
  SplineSeries,
  ScatterSeries,
  Navigator,
  Tooltip,
  PlotLine
} from 'react-jsx-highstock';
import './ForecastChart.css';
import { map, maxBy } from 'lodash';
import { crosshair } from '../../_constants';
import { toolTipStyle } from '../../_constants';
import * as moment from 'moment';


class ForecastChart extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      crosshair: crosshair,
      toolTipStyle: toolTipStyle,
      alignTicks: false,
      titles: map(props.chart, (item) => {
        return item.name
      }),
      lastDay: (maxBy(props.chart.realData.data, (item) => {
        return item[0]
      }) || [])[0]
    }
  }

  handleClick() {
    // console.log('loutre');
  }

  getYAxisLabel(chartName, unit) {
    if(chartName == 'Temperature' || chartName == 'Selectivity'){
      return chartName+' ('+unit+')';
    } else if (chartName == 'PredictedTemperature'){
      return 'Predicted Temperature'+' ('+unit+')';
    } else {
      return 'Predicted Selectivity'+' ('+unit+')';
    }
  }

  generateNavigator(item) {
    return (
      <Navigator.Series seriesId={item} key={item} />
    );
  }

  // TODO: move me somewhere else
  loadStatic() {
    let chart = this.props.chart;
    let unit  = this.props.unit;
    let chartNameData =  chart.realData.name;
    let chartName = chartNameData.charAt(0).toUpperCase() + chartNameData.substr(1);

    let scaleLimits = chart.scale.yAxes.scaleLimits;

    if(chartName == 'Selectivity' || chartName == 'PredictedSelectivity'){
      this.setState({
        min: scaleLimits.get('selectivityMin'),
        max: scaleLimits.get('selectivityMax')
      })
    } else if (chartName == 'Temperature' || chartName == 'PredictedTemperature'){
      this.setState({
        min: scaleLimits.get('temperatureMin'),
        max: scaleLimits.get('temperatureMax')
      })
    }

    const formatter = function() {
      let month = new Date(this.x).getMonth() + 1;
      month = month < 9 ? '0'+ month : month;
      let day =  new Date(this.x).getDate()
      day = day < 9 ? '0'+ day : day;
      let date = month + '-' + day;
      let forecastedData = chart.realData.data.filter(item => item[0] == this.x)[0][1];     
      
      return '<div style="font-size:14px; line-height:25px">' + chartName + ' </span> '  + '[' + unit + '] : '+ ' </span> ' + forecastedData.toFixed(3) + '</div><div style="font-size:12px; line-height:22px"><span style="color:grey">Day : </span> ' + date + ' </div> ';
      
    }

    this.setState({
      formatter, chartName
    })
  }

  componentDidMount() {
    this.loadStatic();
  }

  //make chart available to call as object for export feature
  getChart = chart => {
    this.chart = chart;
  }

  render() {
    const { formatter, crosshair, titles, lastDay, chartName, min, max, alignTicks } = this.state;
    const { chart, chartWidth, unit } = this.props;
    let dateTimeLabelFormats = {
        day: '%Y<br/>%m-%d',
        week: '%Y<br/>%m-%d',
        month: '%Y-%m',
        year: '%Y'
    }

    let yAxisLabel  = this.getYAxisLabel(chartName, unit);

    return (
      <div className="forecast-chart-graphs">
      { (chartName == 'Temperature' || chartName == 'Selectivity') ?
      [
        <HighchartsStockChart callback={this.getChart} plotOptions={{scatter: { stacking: 'normal' }}}>
          <Chart zoomType="x" width={chartWidth/2 - 20}/>

          <Tooltip backgroundColor="#4e4e4e" borderColor="none"
            borderRadius="5" split={false} useHTML={true} valueDecimals="3" style={toolTipStyle}
            formatter={formatter}/>
          <XAxis/>
          <YAxis crosshair={crosshair} min= {min} max= {max} alignTicks= {alignTicks}>
          <YAxis.Title>{yAxisLabel}</YAxis.Title>
            <ScatterSeries id={chart.realData.name} name={chart.realData.name} key={chart.realData.name} data={chart.realData.data} color={chart.realData.color}/>
          </YAxis>
          <Navigator height={50}
            xAxis={dateTimeLabelFormats={dateTimeLabelFormats}}>
            {titles.map(this.generateNavigator)}
          </Navigator>
        </HighchartsStockChart>
        ]
        :
        [(chartName == 'PredictedTemperature' || chartName == 'PredictedSelectivity') ? 
        [
          <HighchartsStockChart callback={this.getChart} plotOptions={{column: { stacking: 'normal' }}}>
         <Chart zoomType="x" width={chartWidth/2 - 20}/>

        <Tooltip backgroundColor="#4e4e4e" borderColor="none"
          borderRadius="5" split={false} useHTML={true} valueDecimals="3" style={toolTipStyle}
          formatter={formatter}/>

        <XAxis/>
        <YAxis crosshair={crosshair} labels={{x: 0, align: 'left', y: 17}} id="unit" min= {min} max= {max} alignTicks= {alignTicks}>
        <YAxis.Title>{yAxisLabel}</YAxis.Title>
          <AreaSplineRangeSeries id={chart.rangeLow.name} name={chart.rangeLow.name} key={chart.rangeLow.name} data={chart.rangeLow.data} color={chart.rangeLow.color} zones={[{className: 'simulator-chart-range'}]}/>
          <SplineSeries id={chart.lowOuterData.name} name={chart.lowOuterData.name} key={chart.lowOuterData.name} data={chart.lowOuterData.data} color={chart.lowOuterData.color}/>
          <SplineSeries id={chart.lowInnerData.name} name={chart.lowInnerData.name} key={chart.lowInnerData.name} data={chart.lowInnerData.data} color={chart.lowInnerData.color}/>
          <AreaSplineRangeSeries id={chart.rangeMiddle.name} name={chart.rangeMiddle.name} key={chart.rangeMiddle.name} data={chart.rangeMiddle.data} color={chart.rangeMiddle.color}/>
          <SplineSeries id={chart.realData.name} name={chart.realData.name} key={chart.realData.name} data={chart.realData.data} color={chart.realData.color}/>
          <AreaSplineRangeSeries id={chart.rangeHigh.name} name={chart.rangeHigh.name} key={chart.rangeHigh.name} data={chart.rangeHigh.data} color={chart.rangeHigh.color} zones={[{className: 'simulator-chart-range'}]}/>
          <SplineSeries id={chart.highInnerData.name} name={chart.highInnerData.name} key={chart.highInnerData.name} data={chart.highInnerData.data} color={chart.highInnerData.color}/>
          <SplineSeries id={chart.highOuterData.name} name={chart.highOuterData.name} key={chart.highOuterData.name} data={chart.highOuterData.data} color={chart.highOuterData.color}/>
        </YAxis>
        <Navigator height={50}
          xAxis={dateTimeLabelFormats={dateTimeLabelFormats}}>
          {titles.map(this.generateNavigator)}
        </Navigator>
      </HighchartsStockChart>
      ] : []
      ]
      }
      </div>
    );
  }
}

export default withHighcharts(ForecastChart, Highcharts);
