import * as React from 'react';
import './OperationLimits.css';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import {EditOperationLimit} from './EditOperationLimit';
import {User} from '../../_models';
import {PlantHelper} from '../../_helpers';
import {permissionsConstants} from '../../_constants';

class OperationLimits extends React.Component {

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired
	}

	constructor(props: any) {
		const user = JSON.parse(localStorage.getItem('user') || '');
		super(props);
		this.state = {
			isModalOperationLimitOpen: false,
			user: new User(user)
		}
		this.openOperationLimitModal = this.openOperationLimitModal.bind(this);
		this.updateOperationData = this.updateOperationData.bind(this);
	}

	private openOperationLimitModal(): void {
		const {isModalOperationLimitOpen}: any = this.state;
		this.setState({
			isModalOperationLimitOpen: !isModalOperationLimitOpen
		})
	}

	private updateOperationData(): void {
		const {match}: any = this.props;
		if (typeof window !== undefined) window.location.href = '/plants/' + match.params.id + '/dashboard';
	}

	render() {
		const {dashboard, match, location}: any = this.props;
		const {user, isModalOperationLimitOpen}: any = this.state;

		return (
			<div className="operation-container">
				<div className="operations-title inline-block">
					<h2>Operation Limits</h2>
					{user.lookUpPermission(permissionsConstants.EDIT_OPERATION_LIMITS, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
						<span className="edit-operation-limit"
							  onClick={() => this.openOperationLimitModal()}>Edit limits</span>
					}
				</div>
				<div className="operation-wrapper inline-block">
					{dashboard.operationLimitList.map((operation: any) =>
						operation.showInd && <Operation key={operation.name} {...{data: operation}} />
					)}
				</div>
				{isModalOperationLimitOpen &&
					<div className="admin-popup">
						<EditOperationLimit {...this.state}
											{...this.props}
											{...{match: match}}
											updateOperationData={this.updateOperationData}
											handleCloseModal={this.openOperationLimitModal} />
					</div>
				}
			</div>
		);
	}

}


const OperationLimitsRouted = withRouter(OperationLimits as React.ComponentType<any>);
export {OperationLimitsRouted as OperationLimits};

export class Operation extends React.Component {
	constructor(props: any) {
		super(props);
	}

	render() {
		const {data}: any = this.props;
		let {name, maxValue, minValue, unit, valueType}: any = data;

		// unit = unit.replace(/[0-9]/g, '');  ??
		let num: string = maxValue;
		if ( (minValue !== "" && minValue !== null) && (maxValue !== "" && maxValue !== null) ) {
			num = minValue + " - " + maxValue
		} else if ( (minValue !== "" && minValue !== null) && (maxValue == "" || maxValue == null) ) {
			num = minValue
		}

		// 286986 - Adjust "RANGE" to "Range" on Dashboard page
		valueType = valueType[0].toUpperCase() + valueType.slice(1).toLowerCase();

		return (
			<div className="inline-block operation">
				<div className="operation-name"> {name} </div>
				<div className="operation-value">
					<div className="operation-num inline-block">{num}</div>
					{unit &&
                    	<div className="operation-unit inline-block">{unit}</div>
					}
					<span className="operation-max">{valueType}</span>
				</div>
			</div>
		);
	}
}
