import * as React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Forecast, ForecastResultClass, ForecastParam } from '../../_models';
import { PredictionServiceV1 } from '../../_services';
import { Header } from '../../_components/Header/Header';
const ForecastChart = require('../../_components/Forecast/ForecastChart');
import * as content from '../../content/content.json';
import './ForecastGraph.css';
import { cloneDeep, forEach, findIndex, some, map, find } from 'lodash';
import { Toaster } from '../../_components/Common/Toaster/Toaster';
import { Loader } from '../../_components/Common/Loader/newLoader';
import * as moment from 'moment';
import { maxBy } from 'lodash';

class ForecastGraphPage extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }

    constructor(props:any) {
      super(props);
        this.state = {
            content: content.pages.forecast.en,
            dayOfPredicition: moment(),
            isLoading: true,
            toaster: undefined,
            noData: false,
            isCalculating: false
        }
        this.setDayOfPredicition = this.setDayOfPredicition.bind(this);
        this.setFormData = this.setFormData.bind(this);
    }

    public componentWillMount(): void {
      const { plants, match }: any = this.props;
      const plant: any = find(plants, (plant: any) => {
        return plant.code === match.params.id;
      })
      this.setState({
        plant: plant
      })
      this.getPredictionData(plant);
      
    }

    private getPredictionData(plant: any): void {
      const { history } :any = this.props;
      const { lastDay, dayOfPredicition }: any = this.state;
      const currentIgnition: any =  find(plant.catalysts, { status: 'A'});
      const ignitionDateStart =  currentIgnition && currentIgnition.ignitionDateStart;
      const catalyst_type: any = plant.catalystName.indexOf('S6-') ? plant.catalystName.substr(plant.catalystName.indexOf('S6-')) : undefined;
  
      if (plant !== undefined) {
        PredictionServiceV1.getInitial(plant.code, plant.nreactors, plant.catVol, catalyst_type, ignitionDateStart, dayOfPredicition.format('YYYY-MM-DD')).then((resp: any) => {
          this.setState({
            initialData: resp.code !== 200 || !resp.data.df_processed || !resp.data.df_processed.length ?  {} as any : new Forecast(resp.data, dayOfPredicition.format('YYYY-MM-DD')),
            isLoading: false,
            code: resp.code,
            toaster: resp.code !== 200 ? { type: 'ERROR' , content: resp.message } : !resp.data.df_processed || !resp.data.df_processed.length ? { type: 'ERROR' , content: 'No data for this day' } : undefined,
            noData: resp.code !== 200 || !resp.data.df_processed || !resp.data.df_processed.length,
            lastDate: lastDay
          })
          this.setResult(true);
        }) 
      } else {
        history.push('/logout');
      }
    }

    private setDayOfPredicition(date: any): any {
      const { initialData }: any = this.state;
      this.setState({
        initialData: new Forecast(initialData, date.format('YYYY-MM-DD')),
        dayOfPredicition: date
      })
      return new Forecast(initialData, date.format('YYYY-MM-DD'));
    }

    private setFormData(formData: any): void {
      this.setState({
        initialData: formData
      })
    }

    private setResult(initial?: boolean): Promise<any> {
      const { initialData, plant }: any = this.state;
      const { match }: any = this.props;
      this.setState({ isCalculating: true });
      
      let data: any = this.shapeFormData(initial);
      const additionalData = {
        catalyst_type: plant.catalystName.indexOf('S6-') ? plant.catalystName.substr(plant.catalystName.indexOf('S6-')) : undefined,
        plantCode: plant.code
      };

      // The forecast scenario (df_scenario) will be constructed by fetching the values from Transformation api response.
      // (i.e. From last data point ordered by Date of df_processed). Each entry in the resulting "df_scenario" array
      // resembles a week (! not a day !)
      let lastEntryByDate: any = maxBy(data.previousData, 'date');
      let scenario = {
        "SOR": lastEntryByDate['SOR'],
        "LHSV": lastEntryByDate['LHSV'],
        "conversion": lastEntryByDate['conversion']
      };
      let df_scenario = [];
      // clone the latest conditions a few times (length of df_scenario array defines the length of the forecast result in weeks: default 15 weeks input / output)
      for (var i = 0; i < 52; i++) {
        df_scenario.push(scenario);
      }

      return PredictionServiceV1.getCalculateForecastData({ catalyst_type: additionalData.catalyst_type, plantCode: additionalData.plantCode, df: data.previousData, df_scenario }, match.params.id).then((resp: any) => {
        this.setState({
          result: resp.data.forecast,
          config: resp.data.config,
          predictions: resp.data.predictions,
          isCalculating: false,
          charts: new ForecastResultClass(resp.data.forecast, initialData.convertedPreviousData),
          toaster: resp.code !== 200 ? {type: 'ERROR', content: resp.message} : undefined
        });
      })
    }

    private shapeFormData(initial?: boolean): any {
      const { initialData }: any = this.state;
      let previousData: any = cloneDeep(initialData.previousData);
      let data: any = {};
      forEach(initial ? initialData.initialParams : initialData.params, (item: ForecastParam) => {
        data[item.code] = item.unit === '%' ? item.value / 100 : item.value;
      })
      const index: number = findIndex(previousData, {date: data.date})
      if (some(previousData, ['date', data.date])) {
        map(previousData, (idx: number) => {
            previousData[idx] = data
        })
      }
      return { previousData: previousData, index: index };
    }

    public componentDidMount(): void {
      const { contentGraph }: any = this.refs;
      this.setState({
        chartWidth: contentGraph.offsetWidth
      })
    }

    render() {
      const { charts, config, chartWidth, toaster, content, isCalculating}: any = this.state;

      let temperature: any = config && find(config, { code: 'temperature'});
      let selectivity: any = config && find(config, { code: 'selectivity'});

      return (
        <div className="predicition-page">
        <Header {...this.props} key="header" showOptions={true} hideConsult={true} additionalText={content.breadcrumbAdditionalText} isPrediction={true}></Header>
        <div  key="content" ref="contentGraph">
            { toaster && <Toaster {...toaster}/> }
            <div ref="forecastContent">
              { charts &&
                [
                  <ForecastChart.default {...{chart: charts.temperature, unit: temperature.unit, chartWidth: chartWidth }} ></ForecastChart.default>,
                  <ForecastChart.default {...{chart: charts.predictedTemperature, unit: temperature.unit, chartWidth: chartWidth }} ></ForecastChart.default>,
                  <ForecastChart.default {...{chart: charts.selectivity, unit: selectivity.unit, chartWidth: chartWidth }} ></ForecastChart.default>,
                  <ForecastChart.default {...{chart: charts.predictedSelectivity, unit: selectivity.unit, chartWidth: chartWidth }} ></ForecastChart.default>
                ]
              }
          </div>
              {isCalculating ? <Loader isLoading={isCalculating}/> : null}
          </div>
        </div>
      );
    }
}

function mapStateToProps(state:any) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedForecastGraphPage = withRouter(connect(mapStateToProps)(ForecastGraphPage) as React.ComponentType<any>);
export { connectedForecastGraphPage as ForecastGraphPage };
