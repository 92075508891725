import * as React from 'react';
import './More.css';


interface IModalProps {
	onClickExporting: (e: any) => void;
}


// class Card extends Component {
export class More extends React.Component<IModalProps> {
	constructor(props: any) {
		super(props);

		this.state = {
			showMenu: false,
		};

		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	showMenu(event: any): void {
		event.preventDefault();

		this.setState({showMenu: true}, () => {
			document.addEventListener('click', this.closeMenu);
		});
	}

	closeMenu() {
		this.setState({showMenu: false}, () => {
			document.removeEventListener('click', this.closeMenu);
		});
	}

	selectExport(event: string) {
		let props: any = this.props;
		props.onClickExporting(event);
	}

	generateOption(eventName: string, text: string): any {
		return <div className="export-option"
					onClick={() => this.selectExport(eventName)}>{ text }</div>
	}

	render() {
		return (
			<div className="header-export header-placeholder" />
		);

		// let {showMenu}: any = this.state;
		//
		// return (
		// 	<div className="header-export header-icons"
		// 		 onClick={this.showMenu}>
		// 		<img src="/assets/images/btn-more.png" alt="" />
		// 		{ showMenu ?
		// 			<div className="header-export-list">
		// 				{this.generateOption("png", "Download PNG image")}
		// 				{this.generateOption("jpeg", "Download JPEG image")}
		// 				{this.generateOption("pdf", "Download PDF image")}
		// 				{this.generateOption("svg", "Download SVG image")}
		// 			</div>
		// 			:
		// 			undefined
		// 		}
		// 	</div>
		// );
	}
}
