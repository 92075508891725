import { BaseClass } from './BaseClass';

export class Gauge extends BaseClass {
  renderTo!:HTMLCanvasElement;
  width!:number;
  height!: number;
  minValue!: number;
  maxValue!: number;
  // The current value of the element needs to be dynamic
  rawValue!: number;
  value!: number;
  valueDec!: number;
  valueInt!: number;
  // For the unit of the graph
  majorTicks!: Array<string>;
  minorTicks!: number;
  exactTicks!: boolean;
  ticksWidth!: number;
  startAngle!: number;
  ticksAngle!: number;
  // For the title box
  colorValueBoxRect!: string;
  colorValueBoxRectEnd!: string;
  colorValueBoxBackground!: string;
  colorValueBoxShadow!: string;
  valueTextShadow!: boolean;
  // The current value of the element needs to be dynamic
  valueText!: string;
  // Everything for needle (to be adujst later)
  colorNeedle!: string;
  needleType!: string;
  colorNeedleEnd!: string;
  colorNeedleShadowUp!: string;
  colorNeedleShadowDown!: string;
  needleShadow!: boolean;
  needleWidth!: number;
  needleCircleInner!: boolean;
  needleCircleOuter!: boolean;
  needleCircleSize!: number;
  borderRadius!: number;
  // For the tricks border and graph background
  colorMajorTicks!: string;
  // For the global graph background
  colorPlate!: string;
  borderOuterWidth!: number;
  borderMiddleWidth!: number;
  borderInnerWidth!: number;
  borderShadowWidth!: number;
  highlights!: any;
  title!: boolean;

  constructor(canvas:HTMLCanvasElement, value: number, rangeMin: number, rangeMax: number, gaugesType:any) {
     super();
     this.renderTo = canvas;
     this.width = (151 + 30)*0.6;
     this.height = (151 + 12)*0.6;
     this.rawValue = value;
     this.minValue = rangeMin;
     this.maxValue = rangeMax;

     //the tick cannot go out of the guage except no rangeMax and rangeMin
     if (this.rawValue > rangeMax ){
       this.value = this.maxValue;
     }else if(this.rawValue < rangeMin){
       this.value = this.minValue;
     }else{
       this.value = this.rawValue;
     };

     this.valueDec = 2;
     this.valueInt =  2;
     // For the unit of the graph
     this.majorTicks =  ['', '', '', '', '', '',''];
     this.minorTicks =  0;
     this.exactTicks =  false;
     this.ticksWidth =  5;
     this.startAngle =  90;
     this.ticksAngle =  180;
     // For the title box
     this.colorValueBoxRect = 'transparent';
     this.colorValueBoxRectEnd =  'transparent';
     this.colorValueBoxBackground =  'transparent';
     this.colorValueBoxShadow =  'transparent';
     this.valueTextShadow =  false;
     // The current value of the element needs to be dynamic
     this.valueText = "";
     // Everything for needle (to be adujst later)
     this.colorNeedle = '#c8cbd2';
     this.needleType = "arrow";
     this.colorNeedleEnd = '#c8cbd2';
     this.colorNeedleShadowUp = 'transparent';
     this.colorNeedleShadowDown = 'transparent';
     this.needleShadow = true;
     this.needleWidth = 8;
     this.needleCircleInner = true;
     this.needleCircleOuter = true;
     this.needleCircleSize = 1;
     this.borderRadius = 50;
     // For the tricks border and graph background
     this.colorMajorTicks = 'transparent';
     // For the global graph background
     this.colorPlate = 'transparent';
     this.borderOuterWidth = 0;
     this.borderMiddleWidth =  0;
     this.borderInnerWidth =  0;
     this.borderShadowWidth =  0;
     this.highlights =  gaugesType;
     this.title =  false;
   }
}
