import { User } from './';

export class BaseClass {
  createdBy!: User;
  createdDate!: Date;
  description!: string;
  lastModifiedBy!: User;
  lastModifiedDate!: Date;
  name!: string;
  code!: string;
  version!: number;
}
