import * as React from 'react';
import './SimulatorChartTitle.css';

export class SimulatorChartTitle extends React.Component {
  constructor(props:any){
    super(props);
    this.extractProperColour = this.extractProperColour.bind(this);
  }

  private extractProperColour () : any {
    const { color, title }: any = this.props;
    const bgColorURL = color == '#d1b254' ? '/assets/images/yellow-bg.png' : '/assets/images/blue-bg.png';
    return '<div style="background-image:url(' + bgColorURL + '); word-break: keep-all; border-radius:3px; padding: 2px 4px;">' + title  + '</div>'
  }

  render() {
    const { color, content }: any = this.props;
    return (
      <div className="chart-header-titles row" style={{color: color}}>
        <p className="chart-header-title" dangerouslySetInnerHTML={{__html: this.extractProperColour()}}></p>
        <p className="chart-header-title"> { content.measure } <hr className="line" style={{ borderTop: '3px solid '+color}}/></p>
        <p className="chart-header-title"> { content.predicted }  <hr className="line" style={{ borderTop: '3px dashed '+color}}/></p>
      </div>
    )
  }
  }
