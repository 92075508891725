import * as React from 'react';
import { OperationLimit } from '../../_models';
import './EditOperationLimit.css';
import { OperationLimitService } from '../../_services';
import { Loader } from '../Common/Loader/newLoader';
import { isEmpty, includes } from 'lodash';


interface IModalProps {
  handleChangeOperationLimit?: () => void,
  handleCloseModal: () => void,
  updateOperationData: () => void,
}

export class EditOperationLimit extends React.Component<IModalProps> {
  constructor(props:any) {
    super(props);
    this.state = {
     operationLimits: props.operationLimits,
     isLoading: true
   }
    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount() :void {
   const { match } :any = this.props;
   OperationLimitService.getOperationLimit(match.params.id).then((resp:any) => {
     this.setState({
       isLoading: false,
       operationLimits: resp.data,
       code: resp.code
     })
   })
  }

  private handleChange(event:any, index: number) :void {
    const { operationLimits } :any = this.state;
    const target :any = event.target;
    operationLimits[index][target.name] = event.target.value
    this.setState({
      operationLimits: operationLimits
    });
  }

  private closeModal(): void {
    const { handleCloseModal }: any = this.props;
    handleCloseModal();
  }

  private changeOperationLimit(): any {
    const { handleCloseModal, match, updateOperationData}: any = this.props;
    const { operationLimits } :any = this.state;
    const errors = this.checkForm();
    if (!isEmpty(errors)) {
      return true;
    }
    OperationLimitService.updateOperationLimit(operationLimits, match.params.id).then(() => {
      handleCloseModal();
      updateOperationData();
    })
  }

  private checkForm(): string[] {
    let errors :string[] = new Array();
    const { operationLimits}: any = this.state;
    operationLimits.map((operationLimit:OperationLimit) => {
      if(operationLimit.code=="ol_so_ratio" && operationLimit.minValue >= operationLimit.maxValue)
      {
        errors.push('invalidInput');
        this.setState({
          inputErrorMessage: "Steam-to-oil ratio: Max value should be greater than min value"
        })
      } else if(operationLimit.code=="ol_conversion" && operationLimit.minValue >= operationLimit.maxValue){
          errors.push('invalidInput');
          this.setState({
            inputErrorMessage: "Conversion: Max value should be greater than min value"
          })
      } 
    })
    
    this.setState({
      errors: errors
    })
    return errors;
  }

  render() {
    const { operationLimits, isLoading, code, errors, inputErrorMessage } :any = this.state;
    if (isLoading || code === 802){
      return <Loader isLoading={false} code={code}> </Loader>
    }

    return (
      <div className="row user-listing">
        <div className="row admin-popup-title admin-popup-header">
          <span>Edit Operation Limit</span>
          <img className="close-popup" src="/assets/images/btn-close-white.png" onClick={() => this.closeModal()}/>
        </div>
        <div className="operation-limit-listing">
          {
            operationLimits.map((operationLimit:OperationLimit, index:number) => {
              let type;
              if(operationLimit.valueType === 'MIN'){
                type = 'Min'
              } else if(operationLimit.valueType === 'MAX'){
                type = 'Max'
              } else if(operationLimit.valueType === 'RANGE'){
                type = 'Range'
              }
              return(
                <div className="single-operation-limit" key={index}>
                  <div className="single-operation-limit-title">
                    {
                      <span className="single-operation-limit-max">{type}</span>
                    }
                    <span className="single-operation-limit-title">{operationLimit.name} ( {operationLimit.unit})</span>
                  </div>
                  <div className="single-operation-limit-input">
                      { operationLimit.valueType === 'MAX' ?
                          <input type="numer" key={index+'_maxValue'} name="maxValue" value={operationLimits[index].maxValue} onChange={(e:any) => this.handleChange(e, index)}/>
                          :
                        [
                          operationLimit.valueType === 'MIN' ?
                          <input type="numer" key={index+'_minValue'} name="minValue" value={operationLimits[index].minValue} onChange={(e:any) => this.handleChange(e, index)}/>
                          :
                          [<input type="numer" key={index+'_minValue'} name="minValue" value={operationLimits[index].minValue} onChange={(e:any) => this.handleChange(e, index)}/> ,
                           <span key={index+'_span'}> - </span>,
                           <input type="numer" key={index+'_maxValue'} name="maxValue" value={operationLimits[index].maxValue} onChange={(e:any) => this.handleChange(e, index)}/>,
                          ]
                        ]
                      }
                  </div>
                </div>
              )
            })
          }
        </div>
        <div>
          <input type="submit" value="Save" className="single-operation-limit-input-button" onClick={() => this.changeOperationLimit()}/>
          <p className="alert alert-danger"> { includes(errors, 'invalidInput') && inputErrorMessage}</p>
        </div>
      </div>
    );
  }
}
