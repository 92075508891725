import * as React from 'react';
import './Header.css';
import {Logo} from '../Common/Logo/Logo';
import {PlantSelection} from '../Common/PlantSelection/PlantSelection';
import {Glyphicon} from 'react-bootstrap';
import Switch from "react-switch";
import {User} from '../../_models';
import {permissionsConstants} from '../../_constants';
import {UnitConversionService} from '../../_services';
import {PlantHelper} from '../../_helpers';
import * as content from '../../content/content.json';

interface IModalProps {
	toggleBubble: (close?: boolean) => void;
};

export class Header extends React.Component<IModalProps> {
	constructor(props: any) {
		super(props);
		const user = JSON.parse(localStorage.getItem('user') || '');

		this.state = {
			user: new User(user),
			checked: !(user.userProfileId == user.defaultUserProfileId) ? true : false,
			content: content.pages.login.en
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(checked: boolean) {
		const {user}: any = this.state;
		const pathArray = window.location.pathname.split('/');
		const plantCodeValue = pathArray[2];
		const url = window.location.href;
		const plantCode = checked ? plantCodeValue : null;

		UnitConversionService.updateUnitConversion(plantCode).then((resp: any) => {
			if (resp.data.code === 200) {
				if (resp.data.data === "PLANT_PROFILE") {
					this.setState({
						checked: true
					});
				} else if (resp.data.data === "USER_PROFILE") {
					this.setState({
						checked: false
					})
				}
				user.setDefaultUserProfileId(checked);
				localStorage.setItem('user', JSON.stringify(user));
				if (typeof window !== undefined) window.location.href = url;
			}
		})
	}

	render() {
		const {toggleBubble}: any = this.props;
		const {checked, content, user}: any = this.state;
		const {userProfile, plantProfile}: any = content;

		const isunitSwitchEnabled: boolean = user.lookUpPermission(permissionsConstants.UNIT_SWITCH, permissionsConstants.USER, PlantHelper.getPlantCode(location));
		const menuHeader: string = isunitSwitchEnabled ? "menu-header unit-switch-enabled" : "menu-header unit-switch-disabled";

		return (
			<div className={menuHeader}>
				<div className="">
					<Logo/>
					<Glyphicon glyph="cog"
							   className="user-settings"
							   onClick={(e) => toggleBubble(e)} />
				</div>

				<div className="plant-selection-container">
					<PlantSelection {...this.props} />
				</div>

				{isunitSwitchEnabled &&
					<div className="toggle">
						{userProfile}
						<Switch checked={checked}
								onChange={this.handleChange}
								onColor="#86d3ff"
								onHandleColor="#2693e6"
								handleDiameter={13}
								uncheckedIcon={false}
								checkedIcon={false}
								height={15}
								width={30}
								className="react-switch"
								id="material-switch" />
						{plantProfile}
					</div>
				}
			</div>
		);
	}
}
