export const gaugesType: any = {
  type1: [
    {"from": 0, "to": 100, "color": "transparent"},
    // {"from": 0, "to": 45, "color": "rgba(35,189,59,.75)"},
    // {"from": 40, "to": 50, "color": "rgba(187,223,69,.75)"},
    // {"from": 45, "to": 60, "color": "rgba(234,234,72,.75)"},
    // {"from": 55, "to": 70, "color": "rgba(240,240,90,.75)"},
    // {"from": 65, "to": 75, "color": "rgba(240,127,90,.75)"},
    // {"from": 70, "to": 80, "color": "rgba(240,127,90,.75)"},
    // {"from": 75, "to": 100, "color": "rgba(232,52,34,.75)"}
  ],
  type2: [
    {"from": 0, "to": 100, "color": "transparent"},
    // {"from": 0, "to": 45, "color": "rgba(98,151,239,.75)"},
    // {"from": 40, "to": 50, "color": "rgba(108,215,233,.75)"},
    // {"from": 45, "to": 60, "color": "rgba(130,228,188,.75)"},
    // {"from": 45, "to": 60, "color": "rgba(139,219,197,.75)"},
    // {"from": 55, "to": 70, "color": "rgba(240,240,90,.75)"},
    // {"from": 65, "to": 75, "color": "rgba(245,148,126,.75)"},
    // {"from": 70, "to": 80, "color": "rgba(240,117,90,.75)"},
    // {"from": 75, "to": 100, "color": "rgba(232,52,34,.75)"}
  ],
  type3: [
    {"from": 0, "to": 100, "color": "transparent"},
    // {"from": 0, "to": 180, "color": "rgba(239,121,98,.75)"},
    // {"from": 150, "to": 320, "color": "rgba(218,227,118,.75)"},
    // {"from": 300, "to": 480, "color": "rgba(140,218,199,.75)"},
    // {"from": 450, "to": 580, "color": "rgba(108,215,233,.75)"},
    // {"from": 550, "to": 700, "color": "rgba(139,219,197,.75)"},
    // {"from": 680, "to": 880, "color": "rgba(242,236,73,.75)"},
    // {"from": 850, "to": 1000, "color": "rgba(240,117,90,.75)"}
  ]
}
