import * as React from 'react';
import { UserService } from '../../_services';
import { isEmpty, includes, intersection } from 'lodash';
import * as content from '../../content/content.json';
import { GetUser } from '../../_helpers';

export class ResetPasswordPage extends React.Component {
    constructor(props:any) {
        super(props);

        this.state = {
            password: '',
            confirmPassword: '',
            errors: [],
            errorAPI: null,
            content: content.pages.login.en,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private handleChange(e:any) :void{
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    private handleSubmit(e: any): any {
        e.preventDefault();

        const {password, confirmPassword}: any = this.state;
        const uuid: string = GetUser.getQueryParameterByName('uuid');
        let errors: string[] = [];

        if (isEmpty(password)) {
            errors.push('password');
        }

        if (isEmpty(confirmPassword)) {
            errors.push('confirmPassword');
        }

        if (password && confirmPassword && password !== confirmPassword) {
            errors.push('unmatchPassword');
        }

        this.setState({ errors: errors })
        if (!isEmpty(errors)) {
            return;
        }

        UserService.submitNewPassword(password, uuid).then((resp: any) => {
            if (resp.data.code === 200) {
                window.location.href = '/login';
            }
        });
    }

    render() {
        const { password, confirmPassword, errors, errorAPI, content } :any = this.state;
        const { resetpwd, newpwd, pwdmatch, done, pwdrequire, confirmpwd, pwdconfirm }: any = content;

        return (
            <div className="col-md-6 col-md-offset-6 left-align welcome-container">
                <div className="welcome-caption">
                  <h2> { resetpwd }</h2>
                </div>
                <form name="form" className="login-form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (includes(errors, 'password') ? ' has-error' : '')}>
                        <label htmlFor="password" className="label-basf-version login-email"> { newpwd } </label>
                        <input type="password" className="form-control input-basf-version " name="password" value={password} onChange={this.handleChange} />
                        { includes(errors, 'password') &&
                            <div className="help-block"> { pwdrequire } </div>
                        }
                    </div>
                    <div className={'form-group' + (intersection(errors, ['confirmPassword','unmatchPassword']).length ? ' has-error' : '')}>
                        <label htmlFor="confirmPassword" className="label-basf-version login-email"> { confirmpwd }</label>
                        <input type="password" className="form-control input-basf-version " name="confirmPassword" value={confirmPassword} onChange={this.handleChange} />
                        { includes(errors, 'confirmPassword') &&
                            <div className="help-block"> { pwdconfirm } </div>
                        }
                        { includes(errors, 'unmatchPassword') &&
                            <div className="help-block"> { pwdmatch }</div>
                        }
                    </div>
                    { includes(errors, 'apiErrors') &&
                        <div className="help-block has-error"> { errorAPI } </div>
                    }
                    <div className="form-group log-in-row">
                        <button className="btn btn-primary submit-basf-version"> { done } </button>
                    </div>
                </form>
            </div>
        );
    }
}
