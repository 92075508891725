export class String{

  public static capitalizeFirstLetter(myString:string) :string{
    if(myString == 'EB' || myString == 'SM'){
      return myString;
    } else {
      return myString.charAt(0).toUpperCase() + myString.slice(1).toLowerCase();
    }
  }
}
