import * as React from 'react';
import { connect } from 'react-redux';

class HomePage extends React.Component {
  constructor(props:any) {
    super(props);
  }

  componentDidMount(): void{
    const { plants } :any = this.props;
    if (typeof window !== undefined) window.location.href = '/plants/' + plants[0].code + '/dashboard';
  }

  render() {
    return null; 
  }
}

function mapStateToProps(state:any) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
