import * as React from 'react';
import './Form.css';
import {CommentService, ChartService} from '../../_services';
import Select from 'react-select';
import {first, includes, isEmpty, extend, find} from 'lodash';
import {SelectSubCategory} from '../../_models';
import DatePicker from 'react-datepicker';
import {ModalFormDelete} from './ModalFormDelete';
import * as moment from 'moment';
import {DateTool} from '../../_helpers';

require("react-datepicker/dist/react-datepicker-cssmodules.css");
import {Toaster} from '../Common/Toaster/Toaster';
import {PlantDataService} from '../../_services/plant-data.services';

interface IModalProps {
	handleAddChange: (addComment: boolean) => void;
	preloadedData?: any;
	setCommentData?: (data: any) => void;
	updateData?: (nbrOfPage?: number) => void;
	updateChartData?: () => void;
	showAlert?: any;
	showModal?: () => void
}

export class Form extends React.Component<IModalProps> {
	constructor(props: any) {
		super(props);
		// console.log('Form.constructor() - props', props);

		// Options are all the subcategory of the current plant this method will
		// retrieve it and add all to it if this a plant level comment

		// derek: sometimes `props.plantFirstDay` is undefined (maybe caused by cache issues),
		// and as the comments page can be opened in two ways:
		// 1. by clicking a data point:
		//   a field `preloadedData.firstDay` is exposed in `setGraphDataForComment`
		//   method in `LinearChartReact.js` and used here.
		// 2. by clicking the upper-right comment button:
		//   a field `firstDay` is appended in <Form> component
		//   in `Comment.tsx` and used here.

		// dataData from the data source returns a non standard format like "2018-08-3",
		// which cannot be parsed to a valid Date object on Safari, thus crash the page.
		// a workaround is applied here to make the string parsable.
		const preloadedDateStr: any = props.preloadedData && props.preloadedData.dataDate.replace(/-/g, "/");
		const selectOptions: any = this.getAllSelectOption();
		this.state = {
			subject: '',
			body: '',
			imageURL: '',
			errors: [],
			fileUploaded: undefined,
			plantCode: props.plantCode,
			selectedOption: selectOptions.currentElement,
			allSelectOptions: selectOptions.elements,
			categoryCode: props.match.params.category || undefined,
			subCategoryCode: props.match.params.type || props.match.params.recommandation || undefined,
			dataDateFormat: preloadedDateStr && moment(new Date(preloadedDateStr)),
			dataDate: preloadedDateStr && moment(new Date(preloadedDateStr)).format('YYYY-MM-DD'),
			plantFirstDay: props.plantFirstDay,
			allCharts: props.allCharts ? props.allCharts : '',
			toaster: undefined,
			selectedChart: props.preloadedData && props.preloadedData.selectedChart,
			serialCode: props.preloadedData && props.preloadedData.selectedChart.value,
			days: props.preloadedData ? DateTool.getDayOnStream(props.plantFirstDay, preloadedDateStr) : undefined,
			selectableDates: []
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeCategory = this.changeCategory.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.deleteForm = this.deleteForm.bind(this);
		this.updatedContent = this.updatedContent.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.changeChart = this.changeChart.bind(this);

		// this.changeCategory(selectOptions.currentElement)
	}

	private changeCategory(event: any): void {
//      const { match } :any = this.props;
		// The value is all or General comment this is the higher level of the
		// plant so no need to send the value of subcategory
		this.setState({
			selectedOption: event,
			selectedChart: null,
			serialCode: null
			// selectedChart: {'value': undefined, 'label': '-'}
		}, this.updateSelectableDates);

		if (event.label === "All") {
			this.setState({
				allCharts: ''
			})
		} else {
			this.getAllSerial(event.category, event.value);
		}

		this.setState(
			includes(['All'], event.value) ? {categoryCode: undefined, subCategoryCode: undefined} : {categoryCode: event.category, subCategoryCode: event.value}
		)
	}

	private changeChart(event: any): void {
		this.setState({
			selectedChart: event,
			serialCode: event.value
		}, this.updateSelectableDates);
	}

	private updateSelectableDates(): void {
		const { plantCode, serialCode }: any = this.state;

		if (serialCode !== null) {
			PlantDataService.getValidDates(plantCode, serialCode).then((dates: Date[]) => {
				this.setState({
					selectableDates: dates.map((date: Date) => moment(date))
				});
			});
		} else {
			this.setState({
				selectableDates: undefined
			});
		}
	}

	public componentWillReceiveProps(nextProps: any): void {
		const nextPreloadedDateStr: any = nextProps.preloadedData && nextProps.preloadedData.dataDate.replace(/-/g, "/");

		this.setState({
			days: nextProps.preloadedData ? DateTool.getDayOnStream(nextProps.plantFirstDay, nextPreloadedDateStr) : undefined,
			dataDateFormat: nextPreloadedDateStr && moment(new Date(nextPreloadedDateStr)),
			dataDate: nextPreloadedDateStr,
			selectedChart: nextProps.preloadedData && nextProps.preloadedData.selectedChart,
			serialCode: nextProps.preloadedData && nextProps.preloadedData.selectedChart.value
		})
	}

	private deleteForm(): void {
		const {addComment, handleAddChange}: any = this.props;
		this.setState({
			subject: '',
			body: '',
			imageURL: ''
		})
		handleAddChange(addComment);
	}

	private getAllSerial(categoryCode: string, subCategoryCode: string): void {
		const {match}: any = this.props;
		ChartService.getAllSerial(match.params.id, categoryCode, subCategoryCode).then((serial: any) => {
			const allCharts: any = serial && serial.length && serial.map((item: any) => {
				return {value: item.code, color: item.color, label: item.name}
			})
			this.setState({
				allCharts: allCharts
			})
		})
	}

	private updatedContent(): void {
		const {updateData}: any = this.props;
		updateData();
		this.deleteForm();
	}

	private handleChange(event: any): void {
		const target: any = event.target;
		this.setState({
			[target.name]: event.target.value
		});
	}

	private handleSubmit(event: any): any {
		event.preventDefault();
		const {updateChartData}: any = this.props;
		let errors: string[] = new Array();
		let {
			subject, body, imageURL, plantCode, categoryCode, subCategoryCode,
			fileUploaded, serialCode, dataDate
		}: any = this.state;

		// Because title and body are both required
		if (isEmpty(subject)) errors.push('subject');
		if (isEmpty(body)) errors.push('body');

		this.setState({errors: errors})
		if (!isEmpty(errors)) {
			return true;
		}

		if (fileUploaded) {
			imageURL = fileUploaded
		}

		const data: any = {subject, body, imageURL, plantCode, categoryCode, subCategoryCode};
		if (serialCode && dataDate) {
			extend(data, {serialCode: serialCode, dataDate: moment(dataDate).format('YYYY-MM-DD')})
		}

		CommentService.postComment(data, plantCode).then((resp: any) => {
			if (resp.code === 200) {
				this.updatedContent();
				if (dataDate) {
					updateChartData();
				}
			} else {
				this.setState({
					toaster: {type: 'ERROR', content: resp.message}
				})
			}
		});
	}

	private uploadFile(file: any): void {
		CommentService.uploadImageComment(first(file)).then((fileUploaded: any) => {
			this.setState({
				fileUploaded: fileUploaded.data.data.location
			})
		})
	}

	private handleChangeDate(e: any): void {
		const {plantFirstDay}: any = this.state;
		this.setState({
			dataDate: e.format('YYYY-MM-DD'),
			dataDateFormat: e,
			days: DateTool.getDayOnStream(plantFirstDay, e.format('YYYY-MM-DD'))
		})
	}

	// TODO: refactor me for something more clean
	private getAllSelectOption(): any {
		const {match}: any = this.props;

		let allPlants = JSON.parse(localStorage.getItem('plants') || '');
		const plant = find(allPlants, (item: any) => item.code = match.params.id);
		let allSelectOptions = new SelectSubCategory(plant.categoryList, match.params.type || match.params.recommandation);

		return allSelectOptions;
	}

	render() {
		const {
			allSelectOptions, selectedOption, subject, allCharts, selectedChart,
			body, fileUploaded, errors, days, dataDateFormat, toaster, selectableDates
		}: any = this.state;
		const {user, preloadedData, match, showModal, showAlert}: any = this.props;

		return (
			<div className="form-container">
				{toaster &&
					<Toaster {...toaster} />
				}

				{showAlert &&
					<ModalFormDelete {...this.state}
									 handleDeleteForm={this.deleteForm}
									 handleHideForm={showModal} />
				}

				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<span className="new-comment">New Comment</span>

						<div className="right">
							<span><img src="/assets/images/trash.png" onClick={showModal} /></span>
							<input type="submit"
								   value="Send"
								   className="send-button" />
						</div>
					</div>

					<div className="source">
						<span className="comment-user">{user.name.substr(0, 1).toUpperCase()}</span>
						Comment from <span className="org">{(user.org || 'BASF').toUpperCase()}</span> : {user.name}
					</div>

					<div className={'row category ' + (preloadedData ? '' : 'with-bottom-border with-margin-bottom')}>
						<img src="/assets/images/btn-category.png"
							 alt="category button"
							 className="category-img"/>
						<span>Category :</span>

						<Select value={selectedOption}
								onChange={this.changeCategory}
								options={allSelectOptions}
								className='select-category-drop-down'
								isSearchable={false} />

						{allCharts && allCharts.length !== 0 && (match.params.category || includes(match.url, 'dashboard')) && !includes(["csc_recommendations", "'All'"], selectedOption.value) &&
							<React.Fragment>
								<Select value={selectedChart}
										onChange={this.changeChart}
										options={allCharts}
										className='select-subcategory-drop-down'
										isSearchable={false} />
                            </React.Fragment>
						}
					</div>

					{selectedChart && selectedChart.value && selectedOption.value !== 'All' &&
						<div className="row days with-bottom-border with-margin-bottom">
							<img src="/assets/images/btn-date.png" alt="date options" className="category-img icone-calendar margin-top-5" />
							<strong className="small-left">Date : </strong>
							<span className="day">Day - {days}</span>
							<DatePicker selected={dataDateFormat}
										selectsStart
										onChange={this.handleChangeDate}
                                        includeDates={selectableDates || []}
										className="date-picker comment-picker"
										utcOffset={0} />
						</div>
					}

					<input name="subject"
						   value={subject}
						   onChange={this.handleChange}
						   className="title-comment-form"
						   placeholder="Comment Title "/>
					<div className="row">
						{ includes(errors, 'subject') ? <p className="alert alert-danger"> Subject is required field in this form </p> : '' }
					</div>

					<div className="row comment-content-textarea">
						<textarea name="body"
								  value={body}
								  onChange={this.handleChange}
								  className="body"
								  placeholder="Please enter comment detail ..."/>
						<div className="col-md-12">
							{ fileUploaded ? <img src={fileUploaded} className="comment-image-uploaded" alt="image uploaded"/> : '' }
						</div>

						<div className="col-md-12 right-align">
							<div className="upload-btn-wrapper">
								<input type="file"
									   id="file"
									   name="file"
									   accept="image/png, image/jpeg"
									   onChange={(e) => this.uploadFile(e.target.files)} />
								<img src="/assets/images/btn-add-pic.png" alt="add picture" className="upload-image"/>
							</div>
						</div>
					</div>
					<div className="row">
						{ includes(errors, 'body') ? <p className="alert alert-danger"> Body is required field in this form </p> : '' }
					</div>
				</form>
			</div>
		)
	};
};
