import { BaseService } from './base.services'
import { Ressource } from '../_helpers';


export class PredictionServiceV2 extends BaseService {
    static async getInitial(code: string, nreactors: number, catVol: number, catalyst_type: string, startDate: string, endDate: string) :Promise<any[]>{
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/prediction/transformation/v2', [code], { nreactors, catVol, catalyst_type, startDate, endDate })
      return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
      .then((item:any) => {
        return item.data;
      })
    }

    static async getPredictionData(formData: any, code: string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/prediction/predictmulti/v2', [code]);
      return this.getAxios.post(ressource.fullUrl, formData, this.requestOptionsPost(formData))
      .then((item:any) => {
        return item.data;
      })
    }

    static async getPredictionResult(formData: any, code: string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/prediction/predictsingle/v2', [code]);
      return this.getAxios.post(ressource.fullUrl, formData, this.requestOptionsPost(formData))
      .then((item:any) => {
        return item.data;
      })
    }

    // This method is redundant / not needed here, since it is used only wrt Longterm Prediction / Forecast.
    // It is a "leftover" from technically splitting "prediciton-services.tsx" into separate V1 and V2 instances
    // and I did not dare to delete it yet.
    static async getCalculateForecastData(formData: any, code: string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/prediction/predictlongterm/v2', [code]);
      return this.getAxios.post(ressource.fullUrl, formData, this.requestOptionsPost(formData))
      .then((item:any) => {
        return item.data;
      })
    }
}
