import * as React from 'react';
import { Chart } from '../../_models';
import { NoDataAvailable } from '../../_components/Charts/NoDataAvailable';
import Select from 'react-select';
import { zoomOptions } from '../../_constants';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { forEach, filter, maxBy, includes, cloneDeep } from 'lodash';
import { markerSettings, selectRangeCustomStyles, changeLineStyle } from '../../_constants';
import * as moment from 'moment';

import './LinearChart.css';
import {zoomOptionInterface} from '../../_constants/chart.constants';

const LinearChartNotTs = require('./LinearChartReact.js');

/* tslint:disable */
const JsLinearChartNotTs: any = LinearChartNotTs; //todo delete me after refactoring to TS
/* tslint:enable */

interface IModalProps {
	onChangeDate: (plantCode: string, categoryCode: string, subCategoryCode: string, startDate?: number | undefined, endDate?: number | undefined) => void;
	setCommentData: (data: any) => void;
	setOnlyNormal: (data: any) => void;
	updateChartData: (data: any) => void;
	setChartsSelected: (data: any) => void;
}

class LinearChart extends React.Component<IModalProps> {
	chart: any;
	outliersHidden: boolean = false;

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);
		this.state = {
			startDate: undefined,
			endDate: undefined,
			exporting: false,
			charts: props.charts,
			comments: props.comments,
			showMe: 'none'
		};

		// console.log('constructor()', this.props, this.state);

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
		this.resetExport = this.resetExport.bind(this);
	}

	public componentWillMount(): void {
		const {charts, comments, chartsLimit}: any = this.props;
		this.getInitialData();
		this.buildSeries(charts, comments, chartsLimit);
	}

	public componentDidMount(): void {
		this.hideOutliers();
	}

	public componentWillUpdate(nextProps: any): void {
		const {comments, charts, chartsLimit}: any = this.props;
		let {exporting}: any = this.state;

		if (exporting !== nextProps.exporting) {
			this.setState({exporting: nextProps.exporting});
		}

		if (nextProps.comments.length !== comments.length) {
			this.setState({
				comments: nextProps.comments
			})
			this.buildSeries(charts, nextProps.comments, chartsLimit);
		}
	}

	private getInitialData(): void {
		const {chartsLimit}: any = this.props;
		this.setState({
			chartLimits: chartsLimit.map((item: any) => {
				return {name: item.name, color: item.color};
			})
		});
	}

	//TODO
	private handleChange(selectedOption: any) {
		let {graphData}: any = this.state;
		let {data, graph, zoomOptions}: any = graphData;
		let {chartsLimit}: any = this.props;

		// console.log('handleChange()', graphData, selectedOption);

		this.setState({
			index: selectedOption.index - 1,
			graphData: {
				'data': data,
				'graph': graph,
				'zoomOptions': zoomOptions,
				'selectedOption': selectedOption,
				'chartsLimit': chartsLimit
			}
		});
	}

	private bindComments(datas: any, comments: any): any {
		forEach(datas, (data: any) => {
			let comment: any = filter(comments, {plantDataId: data.id});
			let latestComment: any = maxBy(comment, 'createdDate');
			if (latestComment) {
				data.description = latestComment.subject || '';
				data.marker = markerSettings;
				data.keys = [comment.length]
			}
		});

		return datas;
	}

	private buildSeries(charts: any, comments: any, chartsLimit?: any): void {
		const {graphData}: any = this.state;
		const {chartsSelected, currentPlant}: any = this.props;
		const {outliersHidden}: any = this;

		charts = charts.filter((chart: Chart) => {
			return includes(chartsSelected, chart.name);
		})

		if (outliersHidden === true) {
			forEach(charts, (item: any) => {
				item.plantData.filterOutliersData();
			})
		}

		let newGraphData: object[] = [];

		forEach(charts, (chart: any) => {
			newGraphData.push({
				data: this.bindComments(chart.plantData.data, comments),
				name: chart.name,
				color: chart.color,
				unit: chart.unit,
				range: chart.range,
				code: chart.dataCode,
				firstDay: chart.firstDay,
				lastDay: chart.lastDay
			})
		});

		// add only valid zoom options
		const currentLivetime = moment().diff(currentPlant.startUpDate, 'days');
		const combinedZoomOptions : zoomOptionInterface[] = [];
		zoomOptions.forEach((zoomOption: zoomOptionInterface) => {
			if (currentLivetime >= zoomOption.count) combinedZoomOptions.push(zoomOption);
		});

		// calculate initial zoom index
		const initZoomIndex = combinedZoomOptions.length;

		// add more zoomOptions
		const sortedCatalysts = currentPlant.catalysts.sort((v1: any, v2: any) => moment(v2.ignitionDateStart).unix() - moment(v1.ignitionDateStart).unix());
		sortedCatalysts.forEach((catalyst: any, index: number) => {
				const startDate = moment(catalyst.ignitionDateStart);
				const endDate = catalyst.ignitionDateEnd !== null ? moment(catalyst.ignitionDateEnd) : moment();
				const days = endDate.diff(startDate, 'days');

				combinedZoomOptions.push({
					type: 'day',
					value: `${days} D`,
					label: (index === 0 ? 'SOR' : `SOR - ${index}`),
					count: days,
					index: combinedZoomOptions.length + 1,
					className:'select-option',
					startDate: catalyst.ignitionDateStart
				});
			});

		this.setState({
			index: initZoomIndex - 1, //graphData && graphData.index,
			graphData: {
				'data': newGraphData,
				'zoomOptions': combinedZoomOptions,
				'selectedOption': graphData && graphData.selectedOption || zoomOptions[initZoomIndex - 1],
				'chartsLimit': chartsLimit
			},
		})
	}

	private selectLegend(e: any, chart: any) {
		// This is where we have to make the startOnTick
		// buildSeries
		e.preventDefault();

		const {chartsSelected, setChartsSelected}: any = this.props;
		const {chartsLimit, charts}: any = this.props;
		let {comments}: any = this.state;
		chart.isSelected = !chart.isSelected
		let newChart: any = cloneDeep(charts);
		if (includes(chartsSelected, chart.name)) {
			let index: number = chartsSelected.indexOf(chart.name);
			chartsSelected.splice(index, 1);
		} else {
			chartsSelected.push(chart.name);
		}

		setChartsSelected(chartsSelected);
		this.buildSeries(newChart, comments, chartsLimit);
	}

	private hideOutliers(): void {
		const {outliersHidden}: any = this;
		const {comments, chartsLimit, charts}: any = this.props;

		// Because if we change props itself it will be broken with way back
		let newChart: any = cloneDeep(charts);
		this.outliersHidden = !outliersHidden;
		this.buildSeries(newChart, comments, chartsLimit);
	}

	private handleChangeStartDate(date: any): void {
		this.setState({
			startDate: date
		})
	}

	private handleChangeEndDate(date: any): void {
		this.setState({
			endDate: date
		})
	}

	private resetExport(): void {
		let props: any = this.props;
		props.onResetExport();
	}

	public render() {
		const {charts, content}: any = this.props;
		const {graphData, chartLimits}: any = this.state;
		const {outliersHidden}: any = this;
		const {zoom, outliers, show, visibility}: any = content;
		let visiStyle = changeLineStyle;

		let isChartDataAvailable: boolean = charts && charts.length;

    	return (
			<div className="linear-chart">
				<div className="row header-chart">
					<div className="select-container">
						<img src="/assets/images/btn-zoom.png" alt="zoom options" className="small-left btn-zoom"/>
						<span className="zoom small-left"><strong> {zoom} : </strong></span>
						<Select value={graphData.selectedOption}
								onChange={this.handleChange}
								options={graphData.zoomOptions}
								className="chart-select global-grey"
								styles={selectRangeCustomStyles}
								isSearchable={false}/>
					</div>

					<div className="outlier-container">
						<img src="/assets/images/icon.png" alt="weird icon" className="small-left"/>
						<span className="small-left"><strong> {outliers} : </strong></span>
						<div className="ctn" style={outliersHidden ? {color: '#333', opacity: 0.4} : {color: '#000'}} onClick={() => this.hideOutliers()}>
							{show}
							<input type="checkbox"/>
							<span className="checkmark" style={{borderColor: 'black'}} />
							{!outliersHidden &&
								<span className="checkmarkSm" style={{background: 'black'}} />
							}
						</div>
					</div>

					<div className="legend-container" style={visiStyle}>
						<img src="/assets/images/btn-visibility.png" alt="zoom options" className="small-left visi-icon"/>
						<strong className="small-left"> {visibility}:</strong>
						<span className="item">
							{ charts.map((chart: any) => {
									return (
										<div key={chart.name} className="legend-item" onClick={(e: any) => this.selectLegend(e, chart)}>
											<label className="ctn" style={{color: chart.color}}>
												{chart.name}
												<input type="checkbox"/>
												<span className="checkmark" style={{borderColor: chart.color}} />
												{chart.isSelected &&
													<span className="checkmarkSm" style={{background: chart.color}} />
												}
											</label>
										</div>
									)
								})
							}

							{ chartLimits.map((chart: any) => {
									return (
										<div key={chart.name} className="legend-item">
											<div className="ctn" style={{color: chart.color}}>
												--- {chart.name}
											</div>
										</div>
									);
								})
							}

							{!isChartDataAvailable &&
                        	    <NoDataAvailable/>
							}
						</span>
					</div>
				</div>

				<div className="chart-container">
					<JsLinearChartNotTs.default  {...this.state} {...this.props} onResetExport={this.resetExport}> </JsLinearChartNotTs.default>
				</div>
			</div>
		)
	}
}

const LinearChartRouted = withRouter(LinearChart as React.ComponentType<any>);
export { LinearChartRouted as LinearChart };
