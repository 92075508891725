import * as React from 'react';
import './SimulatorForm.css';
import { find, forEach, cloneDeep } from 'lodash';
import { SimulatorParam, User } from '../../_models';
require("react-datepicker/dist/react-datepicker-cssmodules.css");

interface IModalProps {
	setDayOfPredicition: (data: any) => any;
	setFormData: (data: any) => void;
	setResult: () => Promise<any>;
};

export class SimulatorForm extends React.Component<IModalProps> {

	constructor(props: any) {
		super(props);
		// console.log('SimulatorForm.constructor()', props);

		this.handleChange = this.handleChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.calculatePosition = this.calculatePosition.bind(this);
		const user = JSON.parse(localStorage.getItem('user') || '');
		this.state = {
			user: new User(user),
		}
	}

	public componentDidMount(): void {
		const { initialData }: any = this.props;

		let ref =  "rangeInput" +  initialData.params[0].code;
		const offsetWidth = (this.refs[ref] as HTMLElement).offsetWidth;
		this.initData(offsetWidth, initialData);
		this.setState({
			inputSize: offsetWidth
		})
	}

	// @ts-ignore
	private showBarandText(realMaxData: any, max: number, min: number, refInput: string, refText: string, position: number, percentage: number) {
		if (!this.refs[refText]) return;

		(this.refs[refText] as HTMLElement).style.left = `${percentage}%`;
		(this.refs[refText + '-bar'] as HTMLElement).style.width = `${percentage}%`;
	}

	private initData(offsetWidth: number, formData: any): void {
		forEach(formData.params, (item: SimulatorParam) => {
			if (item.hide) return;

			const refInput = "rangeInput" + item.code;
			const refText = item.code;
			// const percentage = item.percentage; - REMOVED, BECAUSE item.percentage ISN'T CORRECT !!!!
			const percentage = ((parseFloat(item.value.toFixed(item.fixed)) - item.minValue) / (item.maxValue - item.minValue)) * 100;
			const position = (percentage / 100) * offsetWidth;
			const min: number = item.minValue;
			const max: number = item.maxValue;

			this.showBarandText(item.realMax, max, min, refInput, refText, position, percentage);
		})
	}

	private submitForm(): void {
		const { setResult }: any = this.props;
		// console.log('submitForm()', setResult);
		setResult();
	}

	private resetData(): void {
		const { dayOfPredicition }: any = this.props;
		this.handleChangeDate(dayOfPredicition);
	}

	private setProperPositionForProgressBar(event: any, currentData: any): void {
		const { inputSize }: any = this.state;

		let min: number = currentData.minValue;
		let max: number = currentData.maxValue;
		let percentage = ((event.value - min) / (max - min)) * 100;
		let refInput = "rangeInput" + event.name;
		let textInput = "textInput" + event.name;
		(this.refs[textInput] as any || {} as any).value = event.value
		let refText = event.name;
		let position: number = percentage * inputSize / 100;

		this.showBarandText(currentData.realMax, max, min, refInput, refText, position, percentage);
	}

	// TODO: Refactor Me this is ugly like hell
	private handleChange(event: any): any {
		const { initialData, setFormData, plant }: any = this.props;
		const { inputSize }: any = this.state;

		// console.log('handleChange()', event.name, event.value, plant.catVol);

		let newFormData: any = cloneDeep(initialData);
		let fieldValue = (find(newFormData.params, { code: event.name }) || {} as any);
		if (event.name === 'LHSV') fieldValue.realMax = 0.5;
		// console.log(`\tfieldValue.realMax: ${fieldValue.realMax}`);

		if (!event.value) {
			return;
		}
		if (parseFloat(event.value)  > fieldValue.realMax || parseFloat(event.value) > fieldValue.maxValue) {
			event.value = fieldValue.realMax || fieldValue.maxValue;
		}
		if (parseFloat(event.value) < fieldValue.minValue) {
			event.value = fieldValue.minValue;
		}

		fieldValue.value = parseFloat(event.value);
		if (fieldValue.code === 'conversion') {
			event.value = parseFloat(event.value).toFixed(1);
			this.setState({
				convertedConversionValue: this.calculatedConversionValue(event.value)
			})
		}

		const currentData: SimulatorParam = (find(newFormData.params, { code: event.name }) || {} as any);
		if (currentData.specialRule) {
			newFormData = this.changeFeed(newFormData);
			newFormData.calculateFeedTotalValue();
			if (newFormData.feedTotalValue > 100) {
				return;
			}
			newFormData.calculateFeedTotalValue();
			newFormData.generatePercentages();
			this.initData(inputSize, newFormData);
		}

		fieldValue.value = parseFloat(event.value);
		if (fieldValue.code === 'conversion') {
			event.value = parseFloat(event.value).toFixed(1);
			this.setState({
				convertedConversionValue: this.calculatedConversionValue(event.value)
			})
		}

		// special treatment for EBFR and LHSV
		if (event.name === 'EBFR') {
			// currentData is EBFR
			const lhsvData: SimulatorParam = (find(newFormData.params, { code: 'LHSV' }) || {} as any);
			const newLhsvValue = Math.round(currentData.value / (869.9 * plant.catVol) * 100) / 100;
			lhsvData.value = Math.max(newLhsvValue, lhsvData.minValue);
			this.setProperPositionForProgressBar({value: lhsvData.value, name: 'LHSV'}, lhsvData);

		} else if (event.name === 'LHSV') {
			// currentData is LHSV
			const ebfrData: SimulatorParam = (find(newFormData.params, { code: 'EBFR' }) || {} as any);
			const newEbfrValue = Math.round(currentData.value * 869.9 * plant.catVol);
			ebfrData.value = newEbfrValue;
			this.setProperPositionForProgressBar({value: ebfrData.value, name: 'EBFR'}, ebfrData);
		}

		this.setProperPositionForProgressBar(event, currentData);
		setFormData(newFormData);

		// special treatment for EBFR and LHSV
		if (event.name === 'EBFR') {
			// currentData is EBFR
			const lhsvData: SimulatorParam = (find(newFormData.params, { code: 'LHSV' }) || {} as any);
			const newLhsvValue = currentData.value / (869.9 * plant.catVol);
			// console.log(`EBFR: ${currentData.value} / LHSV: ${lhsvData.value} / plantCatVol: ${plant.catVol} -> ${newLhsvValue}`);
			lhsvData.value = newLhsvValue;
			this.setProperPositionForProgressBar({value: lhsvData.value, name: 'LHSV'}, lhsvData);

		} else if (event.name === 'LHSV') {
			// currentData is LHSV
			const ebfrData: SimulatorParam = (find(newFormData.params, { code: 'EBFR' }) || {} as any);
			const newEbfrValue = currentData.value * 869.9 * plant.catVol;
			// console.log(`LHSV: ${currentData.value} / EBFR: ${ebfrData.value} / plantCatVol: ${plant.catVol} -> ${newEbfrValue}`);
			ebfrData.value = newEbfrValue;
			this.setProperPositionForProgressBar({value: ebfrData.value, name: 'EBFR'}, ebfrData);
		}
	}

	private calculatedConversionValue(value: any): any {
		let actualValue = value / 100;
		let calculatedValue = (actualValue * (104.15 / (((1-actualValue) * 106.16) + (actualValue * 104.15)))) * 100;
		return calculatedValue.toFixed(1);
	}

	private changeFeed(formData: any): any {
		formData.setRealMax();
		return formData;
	}

	private handleChangeDate(date: any): void {
		const { offsetWidth }: any = this.state;

		const { setDayOfPredicition }: any = this.props;
		const initialData: any = setDayOfPredicition(date);
		this.initData(offsetWidth, initialData);

		forEach(initialData.params, (param: any) => {
			this.setProperPositionForProgressBar({value: param.value, name: param.code}, param);
		})
	}

	private calculatePosition(totalUnit: number, idx: number): number{
		if (!idx) {
			return 0;
		}

		const { inputSize }: any = this.state;
		let percentage: number = 1 / (totalUnit - 1);
		const position = (inputSize - 10) * percentage - (20);

		return position;
	}

	render() {
		const { inputSize, user, convertedConversionValue }: any = this.state;
		const { content, initialData }: any = this.props;
		const visibleParameter = initialData.params.filter((item: any) => !item.hide).length;

		console.log('render()', initialData);

		return (
			<div>
				{
					initialData.params.map((data:any, idx:number) => {
						const isLast = visibleParameter === idx + 1;
						const cssClassName = `row ${isLast ? 'row_last' : ''}`;

						if (data.hide) return '';

						return (
							<div className={cssClassName} key={data.name}>
								{ idx === 5 &&
									<h4 className="simulator-middle-split">
										{ content.maxFeedComposition } { initialData.feedTotalValue.toFixed(2) }%
									</h4>
								}
								<p className="main-text label-simulator-form"><strong> { data.name } { data.unit ? '(' + data.unit + ')' : ''}:</strong>
									{user.calculationMethod  === "DIRECT_METHOD_WT" && data.code === "conversion" &&
										<span className="simulator-conversion-text-disabled"> { content.conversionValueForDirectMethod }
											<span className="simulator-conversion-value-disabled">
												<input type="number"
													   name={data.code}
													   className="data-of-prediction-input-disabled"
													   defaultValue={this.calculatedConversionValue(data.value)}
													   value={convertedConversionValue}
													   disabled = {true} />
											</span>
										</span>
									}
								</p>
								<div className="simulator-range-input-content">
									<span className="simulator-range-input-min-value"> { data.minValue.toFixed(2) } </span>
									<span className="simulator-range-input-max-value"> { data.maxValue.toFixed(2) } </span>
									<input type="range"
										   name={data.code}
										   max={data.maxValue}
										   min={data.minValue}
										   step={data.decimal}
										   ref={"rangeInput" + data.code}
										   className='simulator-range-input'
										   value={data.value.toFixed(data.fixed)}
										   onChange={(e:any) => this.handleChange(e.target)} />
									<div className="fakebg" ref="fakebg">
										<div>
											<span className="simulator-range-input-live-value" ref={data.code}> { data.value.toFixed(data.fixed) } </span>
											<span className="simulator-range-input-value-bar" ref={data.code + '-bar'} />
										</div>
									</div>
								</div>
								<div className="simulator-range-input-current-value">
									<input type="number"
										   name={data.code}
										   className="data-of-prediction-input"
										   defaultValue={data.value.toFixed(data.fixed)}
										   ref={"textInput" + data.code}
										   onBlur={(e:any) => this.handleChange(e.target)} />
								</div>
								<div className="prediction-input-scale">
									{
										inputSize && data.units.map((item:number, idx:number) => {
											return(
												<div className="one-crank" key={idx}>
													<div className='size-six light-grey'> | </div>
													<div className='size-twelve dark-grey'> { item } </div>
												</div>
											)
										})
									}
								</div>
							</div>
						)
					})
				}
				<div className="row submit-simulator">
					<input type="submit" className="simulator-submit-button" onClick={this.submitForm} value={content.calculate} />
					<input type="submit" className="simulator-reset-button" onClick={this.resetData} value={content.reset}/>
				</div>
			</div>
		)
	}
}
