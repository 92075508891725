import * as React from 'react';
import { connect } from 'react-redux';
import { ChartService } from '../../_services';
import { Loader } from '../../_components/Common/Loader/newLoader';
import { Header } from '../../_components/Header/Header';
import { ChartPoint, User } from '../../_models';
import { find, includes, cloneDeep, isUndefined } from 'lodash';
import './DataLog.css';
import { String, DateTool, PlantHelper } from '../../_helpers';
import * as moment from 'moment';
import { Glyphicon, Button } from 'react-bootstrap';
import { DataLogForm } from '../../_components/DataLog/DataLogForm';
import { Pagination } from 'react-bootstrap';
import * as content from '../../content/content.json';
import { permissionsConstants } from '../../_constants';
import * as PropTypes from 'prop-types';

class DataLogPage extends React.Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	}

	constructor(props:any) {
		super(props);
		let currentPlant :any = JSON.parse(localStorage.getItem('plants') || {} as any);
		const user: any = JSON.parse(localStorage.getItem('user') || '')
		this.state = {
			initFinish :false,
			isLoading: true,
			isCreatePopupOpen: false,
			currentPlant: find(currentPlant, { 'code' : props.match.params.id }),
			groupsName: [],
			user: new User(user),
			currentPage: 1,
			nbOfPages: undefined,
			content: content.pages.datalog.en,
		}
		this.toggleModal = this.toggleModal.bind(this);
		this.generateDate = this.generateDate.bind(this);
		this.filterDate = this.filterDate.bind(this);
	}

	public componentDidMount() :void {
		this.generateDate(true);
	}

	private generateDate(loading: boolean, pageNumber?: number) : void {
		const { match } :any = this.props;
		const { currentPage } :any = this.state;
		this.setState({
			isLoading: loading
		})
		ChartService.getAllChartsData(match.params.id, pageNumber || currentPage).then((resp: any) => {
			this.setState({
				initFinish : true
			})
			this.shapeData(resp);
		})
	}

	private toggleModal() :void {
		const { isCreatePopupOpen } :any = this.state;
		this.setState({
			isCreatePopupOpen: !isCreatePopupOpen
		})
	}

	private shapeData(data: any) :void {
		const dataByDay: any = data.data.list
		this.setState({
			allSerialCode: data.data.list.length > 0 && data.data.list[0].dailyGCData,
			// Because React is a idiot as it can be
			dataByDay: dataByDay,
			isLoading: false,
			nbOfPages: data.data.pages,
			code: data.code
		})
	}

	private addDataToCharts(data?:any) : void {
		const { allSerialCode } :any = this.state;
		const allData: any = cloneDeep(data);
		let dataUpload = allData ? allData.dailyGCData : allSerialCode;
		this.setState({
			dataToUpdate: dataUpload && dataUpload.map((chart:ChartPoint) => {
				return {
					serialCode:  chart.serialCode,
					systemSerialCode:  chart.systemSerialCode,
					itemName: chart.itemName,
					unit: chart.unit,
					dataValue: allData ? chart.dataValue : undefined,
					...allData ?{ id: chart.id } : {}
				}
			}),
			editing: !isUndefined(allData),
			dataDateFormat: allData && allData.dataDateTime,
			isCreatePopupOpen: true
		})
	}

	// TODO: Refactor me ugly like hell
	private filterDate(e: any) :void {
		let { currentPlant, dataByDay } :any = this.state;
		let date = moment(currentPlant.startUpDate).add(e.target.value,'days');
		dataByDay && dataByDay.map((data :any) => {
			if (includes(data.date, date.format('YYYY-MM-DD')) && e.target.value) {
				data.display = true;
			} else if (e.target.value) {
				data.display = false
			} else {
				data.display = true;
			}
		})
		this.setState({
			dataByDay: dataByDay,
			days: e.target.value
		})
	}

	private changePage(page: number) :void {
		this.setState({
			currentPage: page
		})
		this.generateDate(true, page);
	}

	private generatePaginationItem(page: number): any {
		const { currentPage }: any = this.state;
		return <Pagination.Item key={page} onClick={() => this.changePage(page)} className={ page === currentPage ? 'current-page-selected' : ''}>{page}</Pagination.Item>
	}

	private generateMultipleItems(number: number): any {
		let table: any[] = [];
		for (let i = number - 2; i < number + 3; i++) {
			table.push(this.generatePaginationItem(i))
		}
		return table;
	}

// TODO: ALERT REFACTOR ME THIS IS TOXIC
	private generatePaginator(): any {
		const {nbOfPages, currentPage}: any = this.state;
		let table: any[] = [];
		let pagination: any[];

		if (includes([1, 2, 3, 4], currentPage)) {
			pagination = this.generateMultipleItems(3);
			table = table.concat(pagination);
			table.push(<Pagination.Ellipsis/>)
			table.push(<Pagination.Item key={nbOfPages} onClick={() => this.changePage(nbOfPages)}>{nbOfPages}</Pagination.Item>);

		} else if (includes([nbOfPages, nbOfPages - 1, nbOfPages - 2, nbOfPages - 3], currentPage)) {
			table.push(<Pagination.Item key={1} onClick={() => this.changePage(1)}>{1}</Pagination.Item>);
			table.push(<Pagination.Ellipsis/>)
			pagination = this.generateMultipleItems(nbOfPages - 2);
			table = table.concat(pagination)

		} else {
			table.push(<Pagination.Item key={1} onClick={() => this.changePage(1)}>{1}</Pagination.Item>);
			table.push(<Pagination.Ellipsis/>)
			pagination = this.generateMultipleItems(currentPage);
			table = table.concat(pagination);
			table.push(<Pagination.Ellipsis/>);
			table.push(<Pagination.Item key={nbOfPages} onClick={() => this.changePage(nbOfPages)}>{nbOfPages}</Pagination.Item>);
		}

		return table;
	}

	private getValue(datas: any, point: any): any {
		let value = ((find(datas.dailyGCData, (data :any) => { return data.serialCode === point.serialCode }) || {} as any).dataValue) 
		
		return (value !== undefined && value !== null) ? value.toFixed(2) : '-'
	}

	render() {
		const { isLoading, allSerialCode, dataByDay, currentPlant, initFinish,
			isCreatePopupOpen, code, user, currentPage, nbOfPages, content } :any = this.state;
		const { location } :any = this.props;

		if (!initFinish || code === 802) {
			return <Loader isLoading={true} code={code}> </Loader>
		}

		const { add, time, day }: any = content;

		return ([
			<Header {...this.props} key="header" showOptions={true}></Header>,
			<div className="data-log" key="data-log">
				{ isCreatePopupOpen &&
					<div className="admin-popup">
						<DataLogForm
							{...this.state}
							{...this.props}
							onGenerateDate={this.generateDate}
							onToogleModal={this.toggleModal}
						/>
					</div>
				}
				{
					isLoading && <Loader isLoading={true} />
				}
				<div className="data-log-header" key="table-header">
					{ user.lookUpPermission(permissionsConstants.ANALYTIC_DATA_UPLOAD_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
					<Button bsStyle="primary" block className="purple-button add-data" onClick={() => this.addDataToCharts()}>
						<span className="plus-icon "><Glyphicon glyph="plus"/></span><span> { add } </span>
					</Button>
					}
					<div className="data-log-day-filter">
						{ /*[<label>Day : </label>, <input type="number" step="0" min="0" value={days} className="data-log-input-day" placeholder="day" onChange={this.filterDate}/>] */ }
					</div>
				</div>

				<div className="data-log-table" key="table-body">
					<table key="table">
						<thead className="data-log-table-header">
							<tr>
								<th className="headcol">
									{ time }
								</th>
								{
									allSerialCode && allSerialCode.map((data: any, idx: number) => {
										return (
											<td key={'title-table-' + idx.toString()}>
												<span className="header-component"> { data.groupName }</span><br/>
												<span>{ String.capitalizeFirstLetter(data.itemName) } ( { data.unit } )</span>
											</td>
										)
									})
								}
							</tr>
						</thead>
						<tbody>
							{
								dataByDay && dataByDay.map((datas :any, index: number) => {
									return (
										<tr className={ index % 2 ? 'grey-cell' : 'white-cell'} key={index}>
											<th className="headcol">
												{
													user.lookUpPermission(permissionsConstants.ANALYTIC_DATA_UPLOAD_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
													<img src='/assets/images/icon-pencil.png' className="nav-icon" onClick={() => this.addDataToCharts(cloneDeep(datas))}/>
												}
												{day} {DateTool.getDayOnStream(currentPlant.catalysts, moment(datas.dataDateTime).format("YYYY-MM-DD"))}
												<span className="data-log-table-single-cell-date">({moment(datas.dataDateTime).format("YYYY-MM-DD")})</span>
												<span className="data-log-table-single-cell-time">{moment(datas.dataDateTime).format("HH")}:00 {moment(datas.dataDateTime).format("A")}</span>
											</th>
											{
												allSerialCode && allSerialCode.map((point: ChartPoint, idx: number) => {
													return (<td key={idx}> { this.getValue(datas, point) } </td>)
												})
											}
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>

				<div className="pagination-content">
					<div className="pagination-block">
						<Pagination>
							{ currentPage !== 1 && <Pagination.Prev onClick={() => this.changePage(currentPage - 1)}/> }
							{this.generatePaginator()}
							{ currentPage !== nbOfPages && <Pagination.Next onClick={() => this.changePage(currentPage + 1)}/> }
						</Pagination>
					</div>
				</div>
			</div>
		]);
	}
}

function mapStateToProps(state:any) {
	const { authentication } = state;
	const { user } = authentication;
	return {
		user
	};
}

const connectedDataLogPage = connect(mapStateToProps)(DataLogPage);
export { connectedDataLogPage as DataLogPage };
