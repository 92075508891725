import * as React from 'react';
import { connect } from 'react-redux';
import { Header } from '../../_components/Header/Header';
import { Simulator, SimulatorParam, SimulatorResultClass } from '../../_models';
import { SimulatorHeader } from '../../_components/Simulator/SimulatorHeader';
import { SimulatorForm } from '../../_components/Simulator/SimulatorForm';
import { SimulatorResult } from '../../_components/Simulator/SimulatorResult';
import { PredictionServiceV2 } from '../../_services';
import * as content from '../../content/content.json';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { find, findIndex, forEach } from 'lodash';
import * as moment from 'moment';
import { Loader } from '../../_components/Common/Loader/newLoader';
import { Toaster } from '../../_components/Common/Toaster/Toaster';
import './SimulatorForm.css';

class SimulatorFormPage extends React.Component {

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);

		this.state = {
			content: content.pages.simulator.en,
			dayOfPredicition: moment(),
			isLoading: true,
			toaster: undefined,
			noData: false,
			isCalculating: false
		}

		this.setDayOfPredicition = this.setDayOfPredicition.bind(this);
		this.setFormData = this.setFormData.bind(this);
		this.setResult = this.setResult.bind(this);
	}

	public componentWillMount(): void {
		const {plants, match}: any = this.props;

		const plant: any = find(plants, (plant: any) => {
			return plant.code === match.params.id;
		})

		this.setState({
			plant: plant
		})

		this.getPredictionData(plant ? plant : match.params.id);
	}

	private getPredictionData(plant: any): void {
		const {history, match}: any = this.props;
		const {dayOfPredicition}: any = this.state;
		const plantCode: any = plant.code ? plant.code : match.params.id;
		const currentIgnition: any = find(plant.catalysts, {status: 'A'});
		const ignitionDateStart = currentIgnition && currentIgnition.ignitionDateStart;
		const catalyst_type: any = plant.catalystName.indexOf('S6-') ? plant.catalystName.substr(plant.catalystName.indexOf('S6-')) : undefined;

		if (plant === undefined) history.push('/logout');

		PredictionServiceV2.getInitial(plantCode, plant.nreactors, plant.catVol, catalyst_type, ignitionDateStart, dayOfPredicition.format('YYYY-MM-DD')).then((resp: any) => {
			// console.log('we are getting something here', resp);
			this.setState({
				initialData: resp.code !== 200 || !resp.data.df_processed || !resp.data.df_processed.length ? {} as any : new Simulator(resp.data, dayOfPredicition.format('YYYY-MM-DD'), plant.catVol),
				isLoading: false,
				code: resp.code,
				toaster: resp.code !== 200 ? {type: 'ERROR', content: resp.message} : !resp.data.df_processed || !resp.data.df_processed.length ? {type: 'ERROR', content: 'No data for this day'} : undefined,
				noData: resp.code !== 200 || !resp.data.df_processed || !resp.data.df_processed.length
			});
		});
	}

	private setFormData(formData: any): void {
		this.setState({
			initialData: formData
		})
	}

	private shapeFormData(initial?: boolean): any {
		const {initialData}: any = this.state;
		let previousData: any = [];
		let data: any = {};

		forEach(initial ? initialData.initialParams : initialData.params, (item: SimulatorParam) => {
			data[item.code] = item.unit === '%' ? item.value / 100 : item.value;
		});

		previousData.push(data);
		const index: number = findIndex(previousData, {date: data.date});

		return {previousData: previousData, index: index};
	}

	private async setResult(initial?: boolean): Promise<any> {
		const { initialData, plant }: any = this.state;
		const {match}: any = this.props;
		const additionalData = {
			catalyst_type: plant.catalystName.indexOf('S6-') ? plant.catalystName.substr(plant.catalystName.indexOf('S6-')) : undefined,
			plantCode: plant.code
		};

		this.setState({ isCalculating: true });

		if (initial) {

			return PredictionServiceV2.getPredictionData({ catalyst_type: additionalData.catalyst_type, plantCode: additionalData.plantCode, df: initialData.previousData }, match.params.id).then((resp: any) => {
				this.setState({
					isCalculating: false,
					charts: new SimulatorResultClass(resp.data.predictions, initialData.convertedPreviousData, resp.data.config),
					toaster: resp.code !== 200 ? {type: 'ERROR', content: resp.message} : undefined
				})
				return new SimulatorResultClass(resp.data.predictions, initialData.convertedPreviousData, resp.data.config);
			})

		} else {
			let data: any = this.shapeFormData(initial);

			return PredictionServiceV2.getPredictionResult({ catalyst_type: additionalData.catalyst_type, plantCode: additionalData.plantCode, df: data.previousData }, match.params.id).then((resp: any) => {
				this.setState({
					result: resp.data.predictions[data.index],
					config: resp.data.config,
					predictions: resp.data.predictions,
					isCalculating: false,
					toaster: resp.code !== 200 ? {type: 'ERROR', content: resp.message} : undefined
				})
				return new SimulatorResultClass(resp.data.predictions, initialData.convertedPreviousData, resp.data.config);
			})
		}
	}

	private setDayOfPredicition(date: any): any {
		const { initialData, plant }: any = this.state;
		// console.log('setDayOfPredicition()', date, plant);

		this.setState({
			initialData: new Simulator(initialData, date.format('YYYY-MM-DD'), plant.catVol),
			dayOfPredicition: date
		})

		return new Simulator(initialData, date.format('YYYY-MM-DD'), plant.catVol);
	}

	render() {
		const {isLoading, code, toaster, noData, content, isCalculating}: any = this.state;

		if (isLoading || code === 802) {
			return <Loader isLoading={isLoading}
			               code={code}> </Loader>
		}

		return (
			<div className="predicition-page">
				<Header {...this.props} key="header"
				        showOptions={true}
				        hideConsult={true}
				        additionalText={content.breadcrumbAdditionalText}
				        isPrediction={true}></Header>
				<div key="content">
					{toaster && <Toaster {...toaster}/>}
					<div className="simulator-page-form-content content-padding"
					     ref="simulatorContent">
						{!noData ?
							[
								<SimulatorHeader {...this.props} {...this.state} setResult={this.setResult} />,
								<SimulatorForm {...this.props} {...this.state} setFormData={this.setFormData}
								               setResult={this.setResult}
								               setDayOfPredicition={this.setDayOfPredicition} />
							]
							:
							<p className="simulator-page-no-data"> {content.noData} </p>
						}
					</div>
					<div className="simulator-page-result-content" ref="simulatorResult">
						<SimulatorResult {...this.state} />
					</div>
					{isCalculating ? <Loader isLoading={isCalculating}/> : null}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	const {authentication} = state;
	const {user} = authentication;
	return {user};
}

const connectedSimulatorFormPage = withRouter(connect(mapStateToProps)(SimulatorFormPage) as React.ComponentType<any>);
export { connectedSimulatorFormPage as SimulatorFormPage };
