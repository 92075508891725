import * as React from 'react';
import { SimulatorAdvanceValue } from './SimulatorAdvanceValue';
import './SimulatorResult.css';
import { User } from '../../_models';
import { find } from 'lodash';
import { PlantHelper } from '../../_helpers';
import { permissionsConstants } from '../../_constants';

export class SimulatorResult extends React.Component {
  constructor(props:any){
    super(props);
    const user = JSON.parse(localStorage.getItem('user') || '');
    this.state = {
      isModalOpen: false,
      user: new User(user)
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  private toggleModal(): void {
    const { isModalOpen }: any = this.state;
    this.setState({
      isModalOpen: !isModalOpen
    })
  }

  render() {
    const { content, result, dayOfPredicition, config }: any = this.props;
    const { isModalOpen, user }: any = this.state;

    let temperature: any = find(config, { code: 'temperature'});
    let selectivity: any = find(config, { code: 'selectivity'});

    return (
      <div className="row">
        { isModalOpen &&
          <div className="simulator-advance-value-popup">
            <SimulatorAdvanceValue
              handleToggleModal={this.toggleModal}
              {...this.props}/>
          </div>
        }
        <div className="content-padding containerr">
          <div className="row simulator-result-header">
            <p> { content.result } { dayOfPredicition.format('YYYY-MM-DD') } </p>
          </div>
          <div className="row simulator-result-content">
            <div className="simulator-result-content-section">
              <p className="small-transparency average-font"> { content.predictedTemp } </p>
              <p className="size-big"> { result && result.temperature.toFixed(0) || '--.-' } { temperature && temperature.unit }</p>
              <p className="size-twelve"> ({ result && result.Tmin.toFixed(0) || '--.-' } { temperature && temperature.unit } - { result && result.Tmax.toFixed(0) || '--.-' } { temperature && temperature.unit })</p>
            </div>
            <div className="simulator-result-content-section">
              <p className="small-transparency average-font"> { content.predictedSelect } </p>
              <p className="size-big"> { result && (result.selectivity).toFixed(1) || '--.-' } { selectivity && selectivity.unit }</p>
              <p className="size-twelve"> ({ result && (result.Smin).toFixed(1) || '--.-' } { selectivity && selectivity.unit } - { result && (result.Smax).toFixed(1) || '--.-' } { selectivity && selectivity.unit })</p>
            </div>
            { user.lookUpPermission(permissionsConstants.SIMULATOR_ADVANCED_VALUES, permissionsConstants.USER, PlantHelper.getPlantCode(location)) && 
              <div className="simulator-result-content-section">
                <p className="simulator-result-advance-params" onClick={this.toggleModal}> { content.advanceParams } </p>
              </div> 
            }
            <div className="simulator-result-warning-section">
              <div className="col-2">
                <img src="/assets/images/icon-warning-black-grey.png" />
              </div>
              <div className="col-10">
                <p className="simulator-result-warning-section-content" dangerouslySetInnerHTML={{__html: content.warningMessage }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  }
