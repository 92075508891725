import {BaseClass} from './BaseClass';
import {Comment} from './';
import {minBy, maxBy, clone, transform, isEqual, isObject, isUndefined} from 'lodash';
import * as moment from 'moment';

export class Chart extends BaseClass {
	categoryCode!: string;
	dataCode!: string;
	dataType!: string;
	plantCode!: string;
	color!: string;
	unit!: string;
	name!: string;
	subCategoryCode!: string;
	plantData!: DataGraph;
	min?: number;
	max?: number;
	minValue?: number;
	maxValue?: number;
	firstDay!: any;
	lastDay?: any;
	isSelected: boolean = true;
	range!: string;
	operationLimit?: any;

	constructor(name: string, dataCode: string, color: string,
				range: string, unit: string, plantData: any, minValue?: number,
				maxValue?: number, operationLimit?: any) {
		super();
		this.name = name;
		this.dataCode = dataCode;
		this.color = color;
		this.unit = unit;
		this.range = range;
		this.min = minValue;
		this.max = maxValue;
		this.plantData = new DataGraph(plantData, minValue, maxValue);
		this.minValue = minValue;
		this.maxValue = maxValue;
		this.firstDay = this.plantData.firstDay;
		this.getLastDay();
		this.operationLimit = operationLimit;
	}

	public filterOutliersData(): void {
		this.plantData.filterOutliersData();
	}

	private getLastDay(): void {
		this.lastDay = clone(this.firstDay);
		let lastDate: any = this.lastDay.setDate(this.firstDay.getDate() + this.plantData.data && this.plantData.data.length);
		this.firstDay = moment(this.firstDay);
		this.lastDay = moment(lastDate as number);
	}
}

export class DataGraph extends BaseClass {
	commentInd!: number;
	comments!: Comment[]
	dataDate!: Date;
	dataValue!: number;
	id!: number;
	data!: any;
	firstDay!: Date;
	plantCode!: string;
	serialCode!: string;
	submitDate!: Date;
	minValue!: number;
	maxValue!: number;
	min?: number;
	max?: number;

	constructor(data: any, min?: number, max?: number) {
		super();
		this.min = min;
		this.max = max;
		this.getStartDay(data);
		this.getMinMaxYvalue(data);
		this.data = data && data.map((item: any, index: number) => {
			let correctiveData: number = 0;
			correctiveData = item.dataValue > (this.max || this.maxValue) ? data[index - 1] && data[index - 1].dataValue || data[index + 1].dataValue : item.dataValue;
			const exceeding: any = item.dataValue > (this.max || this.maxValue) || item.dataValue < (isUndefined((this as any).min) ? this.minValue : (this as any).min);
			return {x: this.getUTC(item.dataDate), y: item.dataValue, id: item.id, exceeding: exceeding, correctiveData: correctiveData}
		});
	}

	public filterOutliersData(): void {
		const data: any = this.data.filter((item: any) => {
			return !item.exceeding;
		});
		// const data:any = this.data.map((item:any) => {
		//  item.y = item.correctiveData
		//  return item
		// })
		this.data = data;
	}

	public difference(object: any, base: any) {
		function changes(object: any, base: any) {
			return transform(object, function (result: any, value: any, key: any) {
				if (!isEqual(value, base[key])) {
					result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
				}
			});
		}

		return changes(object, base);
	}

	public getUTC(date: string): number {
		let arr = date.split("-");
		return Date.UTC(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]));
	}

	public static getMin(data: any, prop: string): any {
		return minBy(data, prop) || {};
	}

	public static getMax(data: any, prop: string): any {
		return maxBy(data, prop) || {};
	}

	private getStartDay(data: any): void {
		this.firstDay = new Date(DataGraph.getMin(data, 'dataDate').dataDate)
	}

	public dayBetweenDate(date: string): number {
		let ONE_DAY: number = 1000 * 60 * 60 * 24;
		// Convert both dates to milliseconds
		let date1_ms: number = new Date(date).getTime();
		// Calculate the difference in milliseconds
		let difference_ms: number = Math.abs(date1_ms - this.firstDay.getTime());
		// Convert back to days and return
		return Math.round(difference_ms / ONE_DAY);
	}

	private getMinMaxYvalue(data: any): void {
		this.minValue = DataGraph.getMin(data, 'dataValue').dataValue
		this.maxValue = DataGraph.getMax(data, 'dataValue').dataValue
	}
}

export class ChartPoint extends BaseClass {
	dataDatetime!: Date;
	dataValue!: number;
	groupName!: string;
	itemName!: string;
	serialCode!: string;
	systemSerialCode!: string;
	submitDate!: Date;
	unit!: string;
	id!: number;
}
