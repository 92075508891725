import { UserActions } from '../_actions';
import { Ressource } from '../_helpers';
import { PlantService } from '../_services';

export class GetUser{

  static checkAuthentication() :void{
    const token: string = this.getQueryParameterByName('token');
    if (token) {
      this.authenticateToken(token);
    }  else {
      this.getAuthRedirect();
    }
  }

  static authenticateToken(token: string) :void{
    UserActions.authenticateToken(token).then((resp:any) => {
      if (resp.response == 'success') {
        PlantService.getAllEnrich(resp.token).then((response:any) => {
          if (response.code == 200 && response.message == 'SUCCESS') {
            localStorage.setItem('plants', JSON.stringify(response.data));
            if (typeof window !== undefined) window.location.href = '/plants/' + (((response.data || [])[0]) || {} as any).code + '/dashboard';
          }
        })
      }
    })
  }

  static getAuthRedirect(): void {
    const ressource = new Ressource();
    ressource.shapeUrl('https://dev.my.catalysts.basf.com/flogin', []);
		if (location.hostname == 'localhost') {
            // console.log('Authentication starts');
			location.href = "https://dev.my.catalysts.basf.com/flogin";
		} else {
			// console.log('error for non local host');
		}
	}

  static getQueryParameterByName(name: any): string {
		const url = window.location.href;
		name = name.replace(/[[]]/g, '$&');
		const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(url);
		if (!results) {
			return '';
		}
		if (!results[2]) {
			return '';
		}
		return decodeURIComponent(results[2].replace(/\+/g, ''));
	}
}
