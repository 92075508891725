import { forEach, range, includes, find, last, isEmpty, some, concat, cloneDeep } from 'lodash';
import * as moment from 'moment';
import { min, max, minBy, maxBy } from 'lodash';

export class Forecast{
  code!: string;
  params!: ForecastParam[];
  initialParams!: ForecastParam[];
  feedTotalValue!: number;
  previousData!: any;
  convertedPreviousData!: any;
  dateOfPrediction!: string;
  constructor(data: any, dateOfPrediction: string){
    this.code = 'Forecast';
    this.dateOfPrediction = dateOfPrediction;
    let params: ForecastParam[] = [];
    this.loadDefaultValues(data);
    forEach(data.config || data.params, (item: any) => {
      params.push(new ForecastParam(item));
    })
    this.params = params;
    this.initialParams = cloneDeep(params);
    this.previousData = data.df_processed || data.previousData;
    this.convertedPreviousData = data.df_processed_converted || data.convertedPreviousData;
    this.setRealMax();
}
  public calculateFeedTotalValue(): void {
    let totalValue: number = 0;
    forEach(this.params, (item: any) => {
      totalValue += item.specialRule ? parseFloat(item.value) : 0;
    })
    this.feedTotalValue = totalValue;
  }
  public generateRanges(): void {
    forEach(this.params, (item: ForecastParam) => {
      item.generateRange();
    })
  }
  public generatePercentages(): void {
    forEach(this.params, (item: ForecastParam) => {
      item.getPercentage();
    })
  }
  public setRealMax(): void {
    this.calculateFeedTotalValue();
    forEach(this.params, (item: any) => {
      if (item.specialRule) {
        item.realMax = parseFloat(item.value) + (100 - this.feedTotalValue);
      }
    })
  }
  private loadDefaultValues(data: any): void {
    let date = moment(this.dateOfPrediction);
    while(!some(data.df_processed || data.previousData, ['date', date.format('YYYY-MM-DD')])){
      date.subtract(1, 'd');
    }
    const defaultData = find(data.df_processed || data.previousData, { date: date.format('YYYY-MM-DD') }) || last(data.df_processed || data.previousData);
    forEach(defaultData, (item: any, key: any) => {
      let mappingDefault: any = (find(data.config || data.params, { code: key }) || {} as any);
      if (!isEmpty(mappingDefault)) {
        mappingDefault.value = mappingDefault.unit === '%' ? item*100 : item ;
      } else {
        (data.config || data.params).push({code: key, value: item, hide: true});
      }
    })
  }
}

export class ForecastParam {
  unit!: string;
  code!: string;
  name!: string;
  minValue!: number;
  maxValue!: number;
  value!: number;
  range!: number;
  percentage!: number;
  units!: number[];
  decimal!: number;
  fixed!: number;
  specialRule!: boolean;
  hide!: boolean;
  realMax!: number;
  constructor(data:any){
    this.code = data.code;
    this.name = data.name;
    this.minValue = data.minValue;
    this.maxValue = data.maxValue;
    this.unit = data.unit;
    this.specialRule = includes(data.code, 'feed');
    this.range = data.maxValue - data.minValue;
    this.value = data.value || data.maxValue - (this.range) /2;
    this.hide = data.hide;
    this.getPercentage();
    this.generateRange();
  }

  public generateRange(): void {
    const newRange = range(this.minValue, this.maxValue, this.getTheGoodStep(this.range));
    forEach(newRange, (item: number, idx: number) => {
      if (Number(item) === item && item % 1 === 0) {
        item = item;
      } else {
        newRange[idx] = parseFloat(item.toFixed(2));
      }
    })
    this.units = concat(newRange, [this.maxValue]);
  }
  public getPercentage(): void{
    this.percentage = ((this.value - this.minValue)/this.range) * 100;
  }
  // TODO: refactor me this is ugly like hell
  private getTheGoodStep(value: number): number {
    if (value < 1000 && value > 100 ){
      this.decimal = 0;
      this.fixed = 0;
      return 50;
    } else if( value < 100 && value > 79.8) {
      this.decimal = 0;
      this.fixed = 0;
      return 5;
    } else if( value < 79.8 && value > 50) {
      this.decimal = 0;
      this.fixed = 0;
      return 4;
    } else if( value < 50 && value > 20) {
      this.decimal = 0.01;
      this.fixed = 2;
      return 2;
    } else if( value < 20 && value > 9.9) {
      this.decimal = 0.01;
      this.fixed = 2;
      return 1;
    } else if (value < 9.9 && value > 4 && (value%2.9 === 0)) {
      this.decimal = 0.01;
      this.fixed = 2;
      return 2.9;
    }  else if (value < 9.9 && value > 4 && (value%2.9 !== 0)) {
      this.decimal = 0.01;
      this.fixed = 2;
      return 0.5;
    } else if ( value < 4 && value > 1.4) {
      this.decimal = 0.01;
      this.fixed = 2;
      return 0.5;
    } else if ( value < 1.4 && value > 0.5) {
      this.decimal = 0.01;
      this.fixed = 2;
      return 0.1;
    } else {
      this.decimal = 0.01;
      this.fixed = 2;
      return 0.05;
    }
  }
}

export class ForecastResultClass {
  selectivity!: any;
  temperature!: any;
  predictedSelectivity!: any;
  predictedTemperature!: any;
  scaleLimits!: any;
  constructor(predicted: any, real: any) {
    this.scaleLimits = this.getScaleLimits(real, predicted);
    this.selectivity = this.getChartType(real, 'selectivity');
    this.temperature = this.getChartType(real, 'temperature');
    this.predictedSelectivity = this.getPredictedChartType(predicted, real, 'predictedSelectivity');
    this.predictedTemperature = this.getPredictedChartType(predicted, real, 'predictedTemperature');
  }

  private getChartType(real: any, type: string): any{
    let realData: any[] = [];
    forEach(real, (item: any, idx: number) => {
      let date: number = moment(real[idx].date).valueOf();
      if(type === 'selectivity'){
        realData.push(concat([date], item.selectivity));
      } else if (type === 'temperature'){
        realData.push(concat([date], item.avgInTemp));
      }
    })
    const color: string = type === 'selectivity' ?  '#d1b254' : '#21a0d2';
    return {
      realData: { data: realData, name: type , color: color  },
      scale: { yAxes: this.scaleLimits, name: 'yAxesIntervals'}
    }
  }

  private getPredictedChartType(predicted: any, real: any, type: string): any{
    let rangeLow: any[] = [];
    let rangeMiddle: any[] = [];
    let rangeHigh: any[] = [];
    let predictedData: any[] = [];
    let lowInnerData: any[] = [];
    let highInnerData: any[] = [];
    let lowOuterData: any[] = [];
    let highOuterData: any[] = [];
    let lastEntry: any = (maxBy(real, 'date'));
    let lastDay: any =  moment(lastEntry['date']).valueOf();
    forEach(predicted, (item: any, idx: number) => {
      if (type === 'predictedSelectivity'){
        let date: number =  moment(lastDay).add(predicted[idx].days_ahead, 'd').valueOf();
        rangeLow.push(concat([date], [item.selectivity_low_outer_band, item.selectivity_low_inner_band]));
        rangeMiddle.push(concat([date], [item.selectivity_low_inner_band, item.selectivity_high_inner_band]));
        rangeHigh.push(concat([date], [item.selectivity_high_inner_band, item.selectivity_high_outer_band]));
        predictedData.push(concat([date], [item.selectivity]));
        lowInnerData.push(concat([date], [item.selectivity_low_inner_band]));
        highInnerData.push(concat([date], [item.selectivity_high_inner_band]));
        lowOuterData.push(concat([date], [item.selectivity_low_outer_band]));
        highOuterData.push(concat([date], [item.selectivity_high_outer_band]));
      } else if (type === 'predictedTemperature'){
        let date: number =  moment(lastDay).add(predicted[idx].days_ahead, 'd').valueOf();
        rangeLow.push(concat([date], [item.temperature_low_outer_band, item.temperature_low_inner_band]));
        rangeMiddle.push(concat([date], [item.temperature_low_inner_band, item.temperature_high_inner_band]));
        rangeHigh.push(concat([date], [item.temperature_high_inner_band, item.temperature_high_outer_band]));
        predictedData.push(concat([date], [item.temperature]));
        lowInnerData.push(concat([date], [item.temperature_high_inner_band]));
        highInnerData.push(concat([date], [item.temperature_low_inner_band]));
        lowOuterData.push(concat([date], [item.temperature_high_outer_band]));
        highOuterData.push(concat([date], [item.temperature_low_outer_band]));
      }
    })
    const color: string = type === 'predictedSelectivity' ?  '#d1b254' : '#21a0d2';
    return {
      rangeLow: { data: rangeLow, name: type , color: color},
      rangeMiddle: { data: rangeMiddle, name: type , color: color},
      rangeHigh: { data: rangeHigh, name: type , color: color},
      realData: { data: predictedData, name: type , color: '#B80019'},
      lowInnerData: { data: lowInnerData, name: 'lowInnerData' , color: color },
      highInnerData: { data: highInnerData, name: 'highInnerData' , color: color },
      lowOuterData: { data: lowOuterData, name: 'lowOuterData' , color: color },
      highOuterData: { data: highOuterData, name: 'highOuterData' , color: color },
      scale: { yAxes: this.scaleLimits, name: 'yAxesIntervals'}
    }
  }

  // Fetching min and max limits which will be used for ploting charts identical for forecast input and output
  private getScaleLimits(real: any, predicted: any): any{
    let minOrMaxValueObject: any;

    let scaleLimits = new Map();
    
    let selectivityArray: any[] = [];
    let temperatureArray: any[] = [];

    minOrMaxValueObject = minBy(real, 'selectivity');
    selectivityArray.push((minOrMaxValueObject)['selectivity']);
    minOrMaxValueObject = maxBy(real, 'selectivity');
    selectivityArray.push(minOrMaxValueObject['selectivity']);

    minOrMaxValueObject = minBy(predicted, 'selectivity');
    selectivityArray.push(minOrMaxValueObject['selectivity']);
    minOrMaxValueObject = maxBy(predicted, 'selectivity');
    selectivityArray.push(minOrMaxValueObject['selectivity']);

    minOrMaxValueObject = minBy(predicted, 'selectivity_low_outer_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_low_outer_band']);
    minOrMaxValueObject = maxBy(predicted, 'selectivity_low_outer_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_low_outer_band']);

    minOrMaxValueObject = minBy(predicted, 'selectivity_low_inner_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_low_inner_band']);
    minOrMaxValueObject = maxBy(predicted, 'selectivity_low_inner_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_low_inner_band']);

    minOrMaxValueObject = minBy(predicted, 'selectivity_high_inner_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_high_inner_band']);
    minOrMaxValueObject = maxBy(predicted, 'selectivity_high_inner_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_high_inner_band']);

    minOrMaxValueObject = minBy(predicted, 'selectivity_high_outer_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_high_outer_band']);
    minOrMaxValueObject = maxBy(predicted, 'selectivity_high_outer_band');
    selectivityArray.push(minOrMaxValueObject['selectivity_high_outer_band']);

    minOrMaxValueObject = minBy(real, 'avgInTemp');
    temperatureArray.push(minOrMaxValueObject['avgInTemp']);
    minOrMaxValueObject = maxBy(real, 'avgInTemp');
    temperatureArray.push(minOrMaxValueObject['avgInTemp']);

    minOrMaxValueObject = minBy(predicted, 'temperature');
    temperatureArray.push(minOrMaxValueObject['temperature']);
    minOrMaxValueObject = maxBy(predicted, 'temperature');
    temperatureArray.push(minOrMaxValueObject['temperature']);

    minOrMaxValueObject = minBy(predicted, 'temperature_low_outer_band');
    temperatureArray.push(minOrMaxValueObject['temperature_low_outer_band']);
    minOrMaxValueObject = maxBy(predicted, 'temperature_low_outer_band');
    temperatureArray.push(minOrMaxValueObject['temperature_low_outer_band']);

    minOrMaxValueObject = minBy(predicted, 'temperature_low_inner_band');
    temperatureArray.push(minOrMaxValueObject['temperature_low_inner_band']);
    minOrMaxValueObject = maxBy(predicted, 'temperature_low_inner_band');
    temperatureArray.push(minOrMaxValueObject['temperature_low_inner_band']);

    minOrMaxValueObject = minBy(predicted, 'temperature_high_inner_band');
    temperatureArray.push(minOrMaxValueObject['temperature_high_inner_band']);
    minOrMaxValueObject = maxBy(predicted, 'temperature_high_inner_band');
    temperatureArray.push(minOrMaxValueObject['temperature_high_inner_band']);

    minOrMaxValueObject = minBy(predicted, 'temperature_high_outer_band');
    temperatureArray.push(minOrMaxValueObject['temperature_high_outer_band']);
    minOrMaxValueObject = maxBy(predicted, 'temperature_high_outer_band');
    temperatureArray.push(minOrMaxValueObject['temperature_high_outer_band']);

    scaleLimits.set('selectivityMin', min(selectivityArray));
    scaleLimits.set('selectivityMax', max(selectivityArray));
    scaleLimits.set('temperatureMin', min(temperatureArray));
    scaleLimits.set('temperatureMax', max(temperatureArray));
    return {
      scaleLimits
    }
  }

}
