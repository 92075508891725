import { clone, isNull } from 'lodash';
import axios from 'axios';
import { ErrorHandler } from '../_helpers';

axios.interceptors.response.use(function (response) {
	return response;
  }, function (error) {
	ErrorHandler.redirectToErrorPage(error.response);
  });

export class BaseService {
	static basicOptionsMutliForm :any;
	static getAxios = axios;
	
	static basicOptionsNoAuth :any = {
		cache: "no-cache",
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin':'*',
			'Access-Control-Allow-Headers': 'Authorization'
		},
		redirect: "follow",
		crossdomain: true
	}

	static basicOptions :any = {
		cache: "no-cache",
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': localStorage.getItem('token'),
			'Access-Control-Allow-Origin':'*'
		},
		redirect: "follow",
		crossdomain: true
	}

	static requestOptionsPost(body: any, token?: string) :object {
		this.basicOptionsMutliForm = clone(this.basicOptions);
		this.basicOptionsMutliForm.headers['Content-Type'] = 'application/json';
		const header :any = {
					...this.basicOptions,
					...{
		        body: JSON.stringify(body)
					}
		}
		if(token) {
			header.headers['Authorization'] = token;
		}
		return header;
	}

	static requestOptionsGet(token?: string) :object {
			const header :any = {
						...this.basicOptions,
						...{
							method: 'GET',
							responseType: 'json',
						}
			};
			if (header.headers['Authorization'] === 'null' || isNull(header.headers['Authorization'])){
				header.headers['Authorization'] = token || localStorage.getItem('token');
			}
			if(token) {
				header.headers['Authorization'] = token;
			}
			return header;
		}

	static requestOptionsPut(url:string, content?: any) :object {
				const header :any = {
							...this.basicOptions,
							...{
								method: 'PUT',
								responseType: 'json',
								body: JSON.stringify(content)
							},
							url: url,
				}
				return header;
			}
	
	static requestOptionsPutWithParams(url:string, body: any, token?: string) :object {
		const header :any = {
					...this.basicOptions,
					...{
						method: 'PUT',
						responseType: 'json',
						params: {plantCode: body}
					},
					url: url,
		};
		if (header.headers['Authorization'] === 'null' || isNull(header.headers['Authorization'])){
			header.headers['Authorization'] = token || localStorage.getItem('token');
		}
		if(token) {
			header.headers['Authorization'] = token;
		}
		return header;
	}


	static requestOptionsNotAuth(body: any): object {
		const header: any = {
			...this.basicOptionsNoAuth,
			...{
				body: JSON.stringify(body)
			}
		}

		return header;
	}

	static requestOptionsNoAuth(body: string): object {
		const header: any = {
			...this.basicOptionsNoAuth,
			...{
				method: 'GET',
				responseType: 'json',
				params: {token: body}
			}
		}

		return header;
	}

	static requestOptionsNoAuthPost(body: string): object {
		const header: any = {
			...this.basicOptionsNoAuth,
			...{
				method: 'POST',
				responseType: 'json',
				body
			}
		}

		return header;
	}

	static requestOptionsPostFile(body: any): object {
		this.basicOptionsMutliForm = clone(this.basicOptions);
		this.basicOptionsMutliForm.headers['Content-Type'] = 'multipart/form-data';
		const header: any = {
			...this.basicOptionsMutliForm,
			...{
				body: JSON.stringify(body)
			}
		}
		return header;
	}
}
