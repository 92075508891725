import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import * as content from '../../content/content.json';
import { AccountManager } from './../../_components/AccountManager/Accounts/AccountManager';
import { UserService } from '../../_services';
import { first } from 'lodash';

class AccountManagerPage extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }
    constructor(props:any) {
        super(props);
        const user = JSON.parse(localStorage.getItem('user') || '');
        this.state = {
          user: user,
          name: user.name,
          role: first(user.roles),
          content: content.pages.dashboard.en
        }
        this.getUsers = this.getUsers.bind(this);
        this.getUsers('B');
    }

    private async getUsers(type: string) :Promise<any> {
      const {user} : any  = this.state
        if(user && user.roles && user.roles.length > 0 && user.roles[0].code=="BASF_ADMIN"){
          UserService.getUsers(type).then((users: any) => {
            this.setState({
              users: users.data.list,
              type: type
            });
          })
        }
    }

    render() {
      const { getUsers } :any = this;
      return (
        [
          <AccountManager handleChangeUser={getUsers}
            {...this.state}/>
        ]
      );
    }
}

function mapStateToProps(state:any) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedAccountManager = withRouter(connect(mapStateToProps)(AccountManagerPage) as React.ComponentType<any>);
export { connectedAccountManager as AccountManagerPage };
