import * as React from 'react';
import { connect } from 'react-redux';
import './PublicLandingPage.css';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import * as content from '../../content/content.json';
import {Feature} from '../../_models/Feature';
import {FeatureService} from '../../_services/feature.services';


class PublicLandingPage extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        fsspinner: PropTypes.string
    }

    constructor(props: any) {
        super(props);

        let cookieSeenString = localStorage.getItem('cookie');
        cookieSeenString = cookieSeenString != null ? cookieSeenString : 'false';
        const cookieSeen = JSON.parse(cookieSeenString);

        // load feature toggle for login buttons
        FeatureService.getFeature('public_landingpage.simple_login').then((feature: Feature) => {
            this.setState({
                simpleLogin: feature.active
            }, () => {
                // console.log('feature state loaded');
            });
        });

        this.state = {
            content: content.pages.landing.en,
            showCookieHint: !cookieSeen,
            simpleLogin: undefined
        };

        this.handleCookieOkay = this.handleCookieOkay.bind(this);
    }

    private handleCookieOkay(e: any) {
        e.preventDefault();
        this.setState({ 'showCookieHint': false });
        localStorage.setItem('cookie', 'true');
    }

    render() {
        const { showCookieHint, simpleLogin, content }: any = this.state;
        const { fsspinner }: any = this.props;

        return (
            <div className="publicLandingPage">
                { showCookieHint &&
                    <div className="cookiepolicy" >
                        <div className="cookiepolicy__content">
                            <div><p>{ content.cookie.hint }&nbsp;&nbsp;<a href="https://www.basf.com/global/en/legal/data-protection.html" target="basf">{ content.cookie.link }</a></p></div>
                            <button onClick={ this.handleCookieOkay }>{ content.cookie.button }</button>
                        </div>
                    </div>
                }

                { fsspinner === "true" &&
                    <div className="spinner">
                        <div className="double-bounce1"/>
                        <div className="double-bounce2"/>
                    </div> 
                }

                <div className="wrapper">
                    <div className="stage stage--homepage">
                        <header className="page--mainhomepage page--path-mainhomepage">
                            <div className="header-container">
                                <div className="header-container__content header-content">
                                    <div className="header-content__header-menu header-menu">

                                    </div>

                                    <div className="header-content__header-logo">
                                        <div className="header-container__right-items">
                                            <div className="logo">
                                                <a className="logo__link" href="/" target="_self">
                                                    <div className="logo__image"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <div className="stage__content">
                            <div className="stage__content__text">
                                <div className="text">
                                    <h3>{ content.title }</h3>
                                    <h4>{ content.subtitle }</h4>

                                    {simpleLogin !== undefined && !simpleLogin &&
                                        <h3>
                                            <a href="/flogin"
                                               className="stage__button">
                                                <span className="button-brand">{ content.login.basf }</span>
                                            </a>

                                            <a href="/flogin_external"
                                               className="stage__button">
                                                <span className="button-brand">{ content.login.customer }</span>
                                            </a>
                                        </h3>
                                    }
                                    {simpleLogin !== undefined && simpleLogin &&
                                        <h3>
                                            <a href="/login"
                                               className="stage__button">
                                                <span className="button-brand">{ content.login.login }</span>
                                            </a>
                                        </h3>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <article>
                    <div className="article__wrapper">
                        <div className="article__container">
                            <h2 dangerouslySetInnerHTML={{ __html: content.teaser.title }} />
                            <p dangerouslySetInnerHTML={{ __html: content.teaser.content }} />
                        </div>
                    </div>
                </article>

                <footer>
                    <div className="footer__wrapper">
                        <div className="legalbar">
                            <div className="legalmenu">
                                <div className="legalmenu__header">Copyright © BASF SE 2020</div>
                                <div className="legalmenu__itemlist">
                                    <ul>
                                        <li className="legalmenu__item">
                                            <a href="https://www.basf.com/global/en/legal/disclaimer.html" target="basf">Disclaimer</a>
                                        </li>
                                        <li className="legalmenu__item">
                                            <a href="https://www.basf.com/global/en/legal/credits.html" target="basf">Credits</a>
                                        </li>
                                        <li className="legalmenu__item">
                                            <a href="https://www.basf.com/global/en/legal/data-protection.html" target="basf">Global data protection</a>
                                        </li>
                                        <li className="legalmenu__item">
                                            <a href="https://www.basf.com/global/en/legal/responsible-disclosure-statement.html" target="basf">Responsible Disclosure Statement</a>
                                        </li>
                                        <li className="legalmenu__item">
                                            <a href="https://www.basf.com/global/en/legal/contact.html" target="basf">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>
            </div>
        );
    }

}

function mapStateToProps(state:any) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const PublicLandingPageRouted = withRouter(connect(mapStateToProps)(PublicLandingPage) as React.ComponentType<any>);
export { PublicLandingPageRouted as PublicLandingPage };
