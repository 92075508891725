import { api } from '../_constants';
import { forEach } from 'lodash';

export class Ressource{
  fullUrl!: string;
  internalUrl?: string;
  constructor(url?: string, newFullUrl?: boolean) {
    if (url && !newFullUrl ) {
      this.fullUrl = api.url + url
    } else if (newFullUrl && url) {
      this.fullUrl = url
    } else {
      this.fullUrl = api.url
    }
  }
  public shapeUrl(url: string, urlParams: any[], getParams?: object) :void {
    // First part is to build url with url required parameter
    // e-g: mysuperurl.com/users/:id/posts/:postId => mysuperurl.com/users/1/posts/2
    this.fullUrl += url;
    urlParams.forEach((param:any) => {
      this.fullUrl = this.fullUrl.replace(/%s/, param);
    })
    // Second if we have optional parameters we build it as well
    // if not just stop it now
    if (!getParams) return;
    let optionalParams :string[] = [];
    forEach(getParams, (value:string, key:any) => {
      if(value) {
        optionalParams.push(key + '=' + value)
      }
    })
    this.fullUrl += optionalParams.length ? '?' + optionalParams.join('&') : ''
  }
  public shapeInternalUrl(url: string, urlParams: string[]) :void {
    this.internalUrl = url;
    urlParams.forEach((param:any) => {
      this.internalUrl = (this.internalUrl || '').replace(/%s/, param);
    })
  }
}
