import * as React from 'react';
import { connect } from 'react-redux';
import { Header } from '../../_components/Header/Header';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { RecommendationService } from '../../_services';
import { Recommendation, User } from '../../_models';
import './Recommendation.css';
import { isEmpty, includes } from 'lodash';
import * as content from '../../content/content.json';
import { PlantHelper } from '../../_helpers';
import { permissionsConstants } from '../../_constants';
import * as moment from 'moment';

class RecommendationPage extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }

    constructor(props:any) {
        super(props);
        const user = JSON.parse(localStorage.getItem('user') || '');
        this.state = {
          body: undefined,
          errors: [],
          isLoading: true,
          user: new User(user),
          content: content.pages.recommendation.en,
        }
        this.getRecommendation();
        this.submitForm = this.submitForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setComments = this.setComments.bind(this);
    }

    private setComments(comments:any[]): void {
      let commentsData = comments.filter((item) => {
        return item.plantDataId;
      })
      this.setState({
        comments: commentsData
      })
    }

    private getRecommendation() :void {
      const { match }: any = this.props;
      RecommendationService.getRecommendations(match.params.id).then((resp:any) => {
        this.setState({
          recommendations: resp.data,
          isLoading: false
        })
      })
    }

    private handleChange(event:any) :void {
      const target :any = event.target;
      this.setState({
        [target.name]: event.target.value
      });
    }

    private submitForm(e: any) :any {
      e.preventDefault();
      const { match }: any = this.props;
      let { body }: any = this.state;
      let errors :string[] = [];
      if(!body) {
        errors.push('body');
      }
      this.setState({
        errors: errors
      })
      if(!isEmpty(errors)){
        return;
      }
      body = body.replace(/\n/g, '<br/>');
      RecommendationService.createRecommendation(match.params.id, {body}).then(() => {
        this.setState({
          body: ''
        })
        this.getRecommendation();
      })
    }

    render() {
        const { body, errors, recommendations, user, content }: any = this.state;
        const { requirecontent }: any = content;
        const { location } :any = this.props;

        return (
          [
            <Header {...this.props}
              key="header"
              showOptions={false}
              setComments={this.setComments}
              ></Header>,
            <div className="content-padding" key="content">
            { user.lookUpPermission(permissionsConstants.RECOMMENDATION_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
              <form onSubmit={this.submitForm} className="recommendation-form">
                <div className="">
                  <textarea name="body" value={body} placeholder="Please leave your recommendations here…"
                    className={'recommendation-textarea' + (includes(errors, 'body') ? ' has-error' : '')} onChange={this.handleChange} />
                    {
                      includes(errors, 'body') &&
                      <p className="alert alert-danger"> {requirecontent} </p>
                    }
                  <input type="submit" value="Done" className="recommendation-submit-button" />
                </div>
              </form>
            }
              <div className="recommendation-list">
                { recommendations && recommendations.map((recommendation: Recommendation) => {
                  return (
                    <div key={recommendation.code} className="single-recommendation">
                      <p className="global-grey" key={recommendation.code}> { moment.utc(recommendation.postDate).local().format('YYYY-MM-DD HH:mm:ss') } </p>
                      <p className="global-grey" key={recommendation.code}> From <span className="recommendation-user">{recommendation.user.type === 'C' ? 'Customer' : 'BASF'}</span>: {recommendation.user.name} </p>
                      <p dangerouslySetInnerHTML={{__html: recommendation.body }}></p>
                    </div>
                  )
                })}
              </div>
           </div>
          ]
        );
    }
}

function mapStateToProps(state:any) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedRecommendationPage = withRouter(connect(mapStateToProps)(RecommendationPage) as React.ComponentType<any>);
export { connectedRecommendationPage as RecommendationPage };
