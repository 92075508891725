import { BaseService } from './base.services'
import { Ressource } from '../_helpers';

export class UnitConversionService extends BaseService {
    static async updateUnitConversion(plantCode: any) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/user/set-profile-userdefault', []);
      return this.getAxios(this.requestOptionsPutWithParams(ressource.fullUrl, plantCode))
        .then((resp: any) => {
          return resp;
        })
    }
}
