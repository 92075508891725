import * as React from 'react';
import {withRouter} from 'react-router';
import './Error.css';
import {ErrorInfo} from '../../../_models';

class Error extends React.Component {
	constructor(props: any) {
		super(props);
		let code: string = '';
		let details: any;
		const errorInfo = localStorage.getItem('errorInfo');
		if (errorInfo) {
			const info = JSON.parse(errorInfo);
			code = info.code;
			details = info.details;
		}

		this.state = {
			errorInfo: new ErrorInfo(code),
			details: details
		}

		this.goToDashboardOrLoginPage = this.goToDashboardOrLoginPage.bind(this);
	}

	goToDashboardOrLoginPage(): void {
		const {plants}: any = this.props;
		localStorage.removeItem('errorInfo');
		if (typeof window !== undefined) {
			if (plants !== undefined) {
				window.location.href = '/plants/' + plants[0].code + '/dashboard';
			} else {
				window.location.href = '/';
			}
		}
	}

	render() {
		const {errorInfo, details}: any = this.state;
		const errorCode = errorInfo.code ? '(' + errorInfo.code + ')' : '';
		const buttonText = localStorage.getItem('user') ? 'Back to Dashboard' : 'Back to start page';

		return (
			<div className="error">
				<h1>{errorInfo.headline} {errorCode} </h1>
				<h2>{errorInfo.message}</h2>

				<p>If you need help, please contact <u><a href="mailto:my-catalysts@basf.com">my-catalysts@basf.com</a></u></p>
				<button className="redirect-btn"
						onClick={this.goToDashboardOrLoginPage}>{buttonText}</button>

				{details &&
					<React.Fragment>
						<h3>Error details:</h3>
						{
							Object.keys(details).map((item: any, index: number) =>
							  <h3 key={'h3_' + index}> {item}: {details[item]}</h3>
							)
						}
                    </React.Fragment>
				}
			</div>
		)
	};
}

const ErrorRouted = withRouter(Error as React.ComponentType<any>);
export {ErrorRouted as Error};
