import { BaseService } from './base.services'
import { Chart } from '../_models';
import { Ressource } from '../_helpers';

export class ChartService extends BaseService {
    static async get(plantCode: string, categoryCode: string, subCategoryCode: string, onlyNormal: boolean, startDate?: number, endDate?: number) :Promise<Chart>{
        let ressource = new Ressource();
        ressource.shapeUrl('/plant/%s/chart', [plantCode], {categoryCode, subCategoryCode, onlyNormal: onlyNormal.toString(), startDate, endDate})
        return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
        .then((resp: any) => {
            return resp.data;
        });
    }

    static async getAllChartsData(plantCode: string, page: number) :Promise<any> {
      let ressource = new Ressource();
        ressource.shapeUrl('/plant/%s/gc-analysis-data/daily_list', [plantCode], {page: page, size: 11})
        return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())

            .then((resp: any) => {
          return resp.data;
      });
    }

    static async createChartsData(plantCode:string, formData:any) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/gc-analysis-data', [plantCode]);
      return this.getAxios.post(ressource.fullUrl, formData , this.requestOptionsPost({ formData }))
      .then((resp: any) => {
          return resp;
      });
    }

    static async updateChartsData(plantCode:string, formData:any) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/gc-analysis-data', [plantCode]);
      return this.getAxios.put(ressource.fullUrl, formData, this.basicOptions)
      .then((resp: any) => {
          return resp;
      })
    }

    static async getAllSerial(plantCode:string, categoryCode:string, subCategoryCode:string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/chart/dataSerial/list', [plantCode], {categoryCode, subCategoryCode});
      return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
      .then((resp: any) => {
          return resp.data.data;
      })
    }

    static async getSerialCodeLimits(token?: string) :Promise<any> {
        let ressource = new Ressource();
        ressource.shapeUrl('/data/serial/listLimits', []);
        return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet(token))
        .then((item:any) => {
            return item.data;
        })
      }
}
