import { cloneDeep, concat, find, forEach, includes, isEmpty, last, range, some } from 'lodash';
import * as moment from 'moment';

export class Simulator {
	code!: string;
	params!: SimulatorParam[];
	initialParams!: SimulatorParam[];
	feedTotalValue!: number;
	previousData!: any;
	convertedPreviousData!: any;
	dateOfPrediction!: string;

	constructor(data: any, dateOfPrediction: string, plantCatVol: number) {
		this.code = 'simulator';
		this.dateOfPrediction = dateOfPrediction;

		let params: SimulatorParam[] = [];
		this.loadDefaultValues(data);

		// console.log('Simulator.constructor()', data, plantCatVol);
		const LHSV = this.findSimulatorParamByCode(data.config || data.params, 'LHSV');
		forEach(data.config || data.params, (item: any) => {
			if (item.code === 'LHSV') {
				item.realMax = 0.5;
			}

			if (item.code === 'LHSV') {
				params.push(new SimulatorParam({
					code: 'EBFR',
					name: 'EB Feed Rate',
					minValue: Math.round(LHSV.minValue * 869.9 * plantCatVol),
					maxValue: Math.round(0.5 * 869.9 * plantCatVol),
					unit: 'kg/h',
					value: LHSV ? Math.round(LHSV.value * 869.9 * plantCatVol) : 0,
					hide: false
				}));
			}

			// console.log(`\t${item.code}`, item);
			params.push(new SimulatorParam(item));
		})

		this.params = params;
		this.initialParams = cloneDeep(params);
		this.previousData = data.df_processed || data.previousData;
		this.convertedPreviousData = data.df_processed_converted || data.convertedPreviousData;

		this.setRealMax();
	}

	private findSimulatorParamByCode(simParams: any[], code: string): any {
		return simParams.find(simParam => simParam.code === code);
	}

	public calculateFeedTotalValue(): void {
		let totalValue: number = 0;

		forEach(this.params, (item: any) => {
			if (item.specialRule) {
				totalValue += item.value;
			}
		})

		this.feedTotalValue = totalValue;
	}

	public generateRanges(): void {
		forEach(this.params, (item: SimulatorParam) => {
			item.generateRange();
		})
	}

	public generatePercentages(): void {
		forEach(this.params, (item: SimulatorParam) => {
			item.getPercentage();
		})
	}

	public setRealMax(): void {
		this.calculateFeedTotalValue();

		forEach(this.params, (item: any) => {
			if (item.specialRule) {
				item.realMax = parseFloat(item.value) + (100 - this.feedTotalValue);
			}
		})
	}

	private loadDefaultValues(data: any): void {
		let date = moment(this.dateOfPrediction);
		while (!some(data.df_processed_converted || data.convertedPreviousData, ['date', date.format('YYYY-MM-DD')])) {
			date.subtract(1, 'd');
		}

		const defaultData = find(data.df_processed_converted || data.convertedPreviousData, {date: date.format('YYYY-MM-DD')}) || last(data.df_processed_converted || data.convertedPreviousData);

		forEach(defaultData, (item: any, key: any) => {
			let mappingDefault: any = (find(data.config || data.params, {code: key}) || {} as any);

			if (!isEmpty(mappingDefault)) {
				mappingDefault.value = mappingDefault.unit === '%' ? item * 100 : item;
			} else {
				(data.config || data.params).push({code: key, value: item, hide: true});
			}
		})
	}
}

export class SimulatorParam {
	unit!: string;
	code!: string;
	name!: string;
	minValue!: number;
	maxValue!: number;
	value!: number;
	range!: number;
	percentage!: number;
	units!: number[];
	decimal!: number;
	fixed!: number;
	specialRule!: boolean;
	hide!: boolean;
	realMax!: number;

	constructor(data: any) {
		this.code = data.code;
		this.name = data.name;
		this.minValue = data.minValue;
		this.maxValue = data.maxValue;
		this.unit = data.unit;
		this.specialRule = includes(data.code, 'feed');
		this.range = data.maxValue - data.minValue;
		this.value = data.value !== undefined && data.value !== null ? data.value : data.maxValue - (this.range) / 2;
		this.hide = data.hide;

		this.getPercentage();
		this.generateRange();
	}

	public generateRange(): void {
		const newRange = range(this.minValue, this.maxValue, this.getTheGoodStep(this.range));
		forEach(newRange, (item: number, idx: number) => {
			if (Number(item) === item && item % 1 === 0) {
				item = item;
			} else {
				newRange[idx] = parseFloat(item.toFixed(2));
			}
		})
		this.units = concat(newRange, [this.maxValue]);
	}

	public getPercentage(): void {
		this.percentage = ((this.value - this.minValue) / this.range) * 100;
	}

	public isConversion(): boolean {
		return this.code === 'conversion';
	}

	// TODO: refactor me this is ugly like hell
	private getTheGoodStep(value: number): number {
		let returnValue = 0.05;
		this.decimal = 0;
		this.fixed = 0;

		if (value >= 10000) {
			returnValue = 10000;

		} else if (value >= 1000) {
			returnValue = 1000;

		} else if (value < 1000 && value > 100) {
			returnValue = 50;

		} else if (value < 100 && value > 79.8) {
			returnValue = 5;

		} else if (value < 79.8 && value > 50) {
			returnValue = 4;

		} else if (value < 50 && value > 20) {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 2;

		} else if (value < 20 && value > 9.9) {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 1;

		} else if (value < 9.9 && value > 4 && (value % 2.9 === 0)) {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 2.9;

		} else if (value < 9.9 && value > 4 && (value % 2.9 !== 0)) {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 0.5;

		} else if (value < 4 && value > 1.4) {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 0.5;

		} else if (value < 1.4 && value > 0.5) {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 0.1;

		} else {
			this.decimal = 0.01;
			this.fixed = this.isConversion() ? 1 : 2;
			returnValue = 0.05;
		}

		return returnValue;
	}
}

export class SimulatorResultClass {
	selectivity!: any;
	temperature!: any;
	config!: any;

	constructor(predicted: any, real: any, config: any) {
		this.selectivity = this.getChartType(predicted, real, 'selectivity');
		this.temperature = this.getChartType(predicted, real, 'temperature');
		this.config = config;
	}

	private getChartType(predicted: any, real: any, type: string): any {
		let range: any[] = [];
		let simulatedData: any[] = [];
		let realData: any[] = [];

		forEach(predicted, (item: any, idx: number) => {
			let date: number = moment(real[idx].date).valueOf();
			range.push(concat([date], type === 'selectivity' ? [item.Smin, item.Smax] : [item.Tmin, item.Tmax]));
			simulatedData.push(concat([date], type === 'selectivity' ? [item.selectivity] : [item.temperature]));
			realData.push(concat([date], type === 'selectivity' ? [real[idx].selectivity] : [real[idx].avgInTemp]));
		})

		const color: string = type === 'selectivity' ? '#d1b254' : '#21a0d2';

		return {
			range: {data: range, name: type, color: color},
			simulatedData: {data: simulatedData, name: 'Predicted', color: color},
			realData: {data: realData, name: 'Measured', color: color}
		}
	}
}
