import * as React from 'react';
import {CustomerAndPlant} from '../../../_models';
import './PlantSelection.css';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import {User} from '../../../_models';
import {UnitConversionService} from '../../../_services';

class PlantSelection extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);
		const user = JSON.parse(localStorage.getItem('user') || '');

		this.state = {
			productLine: undefined,
			currentPlant: props.location.pathname.split('/')[2],
			user: new User(user)
		}

		this.handleChange = this.handleChange.bind(this);
	}

	public handleChange(event: any): any {
		const {history}: any = this.props;
		const {user}: any = this.state;

		this.setState({productLine: event.target.value});
		const code = event.target.value.toLocaleLowerCase().split(' ').join('-');

		if (user.userProfileId !== user.defaultUserProfileId) {
			UnitConversionService.updateUnitConversion(code).then((resp: any) => {
				if (resp.data.code === 200) {
					if (resp.data.data === "PLANT_PROFILE") {
						user.setDefaultUserProfileId(true);
						localStorage.setItem('user', JSON.stringify(user));
					} else if (resp.data.data === "USER_PROFILE") {
						user.setDefaultUserProfileId(false);
						localStorage.setItem('user', JSON.stringify(user));
					}
				}
			})

			if (typeof window !== undefined) {
				window.location.href = '/plants/' + code + '/dashboard';
			}

		} else {
			const routeTo: string = '/plants/' + code + '/dashboard';
			history.push(routeTo);
		}
	}

	render() {
		const {currentPlant}: any = this.state;
		const {customersAndPlants}: any = this.props;

		return (
			<select className="plant-selection"
					onChange={this.handleChange}>
				{customersAndPlants.map((customerAndPlant: CustomerAndPlant) => {
					return (
						<option value={customerAndPlant.plantCode}
								key={customerAndPlant.plantName}
								selected={customerAndPlant.plantCode === currentPlant}>{customerAndPlant.customerName} : {customerAndPlant.plantName}</option>
					)
				})}
			</select>
		);
	}
}

const PlantSelectionRouted = withRouter(PlantSelection as React.ComponentType<any>);
export {PlantSelectionRouted as PlantSelection};
