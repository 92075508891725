import * as React from 'react';
import { isEmpty } from 'lodash';

export class ErrorHandler extends React.Component{

  static handleErrors(): void {
		const url = window.location.href;
		const regex = new RegExp('[/error?&]');
    const error: any = regex.exec(url);
    const errorInfo: any= {};
		if (error) {
      const regExForErrorCode = new RegExp('[?&]' + 'code' + '(=([^&#]*)|&|#|$)');
      const code = regExForErrorCode.exec(url);
      if (code && code[2]) {
        errorInfo['code'] = code[2];
      }
      // const regExForErrorMessage = new RegExp('[?&]' + 'message' + '(=([^&#]*)|&|#|$)');
      // const errorMessage = regExForErrorMessage.exec(url);
      // if (errorMessage && errorMessage[2]) {
      //   errorInfo['errorMessage'] = errorMessage[2];
      // }
    }
    if(!isEmpty(errorInfo)){
      localStorage.setItem('errorInfo', JSON.stringify(errorInfo));
      if (typeof window !== undefined) window.location.href = '/error';
    }
  }
  
  static redirectToErrorPage(response: any): void {
    const errorInfo: any= {};
    const code = response.data && response.data.code;
    const details = response.data && response.data.details;

    if(code == 611){
      // If authorization token expired then clear localStorage and redirect to login page
      this.clearLocalStorage();
      if (typeof window !== undefined) window.location.href = '/login';
    } else if (code == 610 || code == 612) {
      // If session timeout or authorization token invalid then clear localStorage and redirect to error page
      this.clearLocalStorage();
      errorInfo['code'] = code.toString();
      errorInfo['details'] = details;
    } else {
      errorInfo['code'] = code.toString();
      errorInfo['details'] = details;
    }
    if(!isEmpty(errorInfo)){
      localStorage.setItem('errorInfo', JSON.stringify(errorInfo));
      if (typeof window !== undefined) window.location.href = '/error';
    }
  } 

  static clearLocalStorage(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('plants');
  }
  
}