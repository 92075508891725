import * as React from 'react';
import {BreadCrumb} from '../../_components/Common/BreadCrumb/BreadCrumb';
import {Consult} from '../Consult/Consult';
import {Comments} from '../Comment/Comment';
import {More} from '../More/More';
import './Header.css';
import {ChatService} from '../../_services';
import {User} from '../../_models';
import {Link} from 'react-router-dom';
import * as content from '../../content/content.json';
import {permissionsConstants} from '../../_constants';
import {PlantHelper} from '../../_helpers';


interface IModalProps {
	onClickExporting?: (e: any) => void;
	showOptions?: boolean;
	setComments?: (comments: any[]) => void;
	setCommentData?: (data: any) => void;
	updateData?: () => void;
	hideConsult?: boolean;
	hasCloseIcon?: boolean;
	closeLink?: string;
	updateCommentMenu?: (subCategory: string) => void;
	updateUnReadComments?: (data: any) => void;
	additionalText?: any;
	isPrediction?: boolean;
}

export class Header extends React.Component<IModalProps> {

	constructor(props: any) {
		super(props);

		this.state = {
			match: props.match,
			user: new User(props.user),
			content: content.pages.calculationMethod.en
		}

		this.handleExporting = this.handleExporting.bind(this);
		this.getChatId();
	}

	private getChatId(): void {
		const {match}: any = this.props;
		ChatService.getChatId(match.params.id).then((chatId: any) => {
			this.setState({
				chatId: chatId
			})
		})
	}

	componentWillUpdate(nextProps: any): void {
		const {match}: any = this.state
		if (match != nextProps.match) {
			this.setState({
				match: nextProps.match
			})
		}
	}

	private handleExporting(event: string): void {
		let props: any = this.props;
		props.onClickExporting(event);
	}

	private getCalculationMethod(): string {
		const {user, content}: any = this.state;
		const {isPrediction}: any = this.props;

		if (isPrediction) {
			return content.MOL_METHOD;
		}

		if (user.calculationMethod === "DIRECT_METHOD_WT") {
			return content.DIRECT_METHOD;
		}

		return content.MOL_METHOD;
	}

	render() {
		const {props}: any = this;
		const {chatId, user, content}: any = this.state;
		const {hasCloseIcon, closeLink, location}: any = this.props;

		return (
			<div className="siteHeader">
				<BreadCrumb {...props} additionalText={props.additionalText} />

				<div className='centerHeader'
					 dangerouslySetInnerHTML={{__html: content.message + ' ' + this.getCalculationMethod()}} />

				<div className='rightHeader'>
					{!props.showOptions && user.lookUpPermission(permissionsConstants.COMMENT_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) ?
						<div>
							{(props.unReadComments && !!props.unReadComments.length) &&
                            	<span className="unread-comments-header">•</span>
							}
							<Comments {...props} {...{user: user}}
									  setComments={props.setComments}
									  setCommentData={props.setCommentData}
									  updateData={props.updateData}
									  updateUnReadComments={props.updateUnReadComments} />
						</div>
						:
						<div className="header-icons"/>
					}

					{!props.hideConsult && chatId && user.lookUpPermission(permissionsConstants.CONSULT_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
                    	<Consult {...props} chatId={chatId}/>
					}

					{!props.showOptions && props.match.params.type && user.lookUpPermission(permissionsConstants.CHART_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
						<More {...props}
							  onClickExporting={this.handleExporting} />
					}

					{hasCloseIcon &&
	                    <span className="header-close-icon">
    						<Link to={closeLink}>
								<img src="/assets/images/header-close.png" alt="" />
							</Link>
	                    </span>
					}
				</div>
			</div>
		);
	}
}
