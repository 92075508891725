import * as React from 'react';
import * as PropTypes from 'prop-types';

export class RedirectToDashboard extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor(props:any) {
    super(props);
    this.redirectToDashboard();
  }

  redirectToDashboard() :void {
    const { plants } :any = this.props;
    if(plants !== undefined){
      if (typeof window !== undefined) window.location.href = '/plants/' + plants[0].code + '/dashboard';
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('plants');
      window.location.href = '/login';
    }
  }
}
