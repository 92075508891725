import { ToastContainer, toast, cssTransition } from 'react-toastify';
import * as React from 'react';
import './Toaster.css';

export class Toaster extends React.Component{
    constructor(props:any)  {
        super(props);
        let params: object = {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          type: (toast.TYPE as any)[props.type],
          closeButton: false,
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          transition: cssTransition({
            enter: 'fadeIn',
            exit: 'fadeOut'
          })
        }
        toast(props.content, params);
    }

    render() {
        return <ToastContainer />
    };
};
