import * as React from 'react';
import * as moment from 'moment';
import {DateTool, String} from '../../_helpers';
import './DataLogForm.css';
require("react-datepicker/dist/react-datepicker-cssmodules.css");
import { isEmpty, includes, map, find, cloneDeep } from 'lodash';
import { ChartService } from '../../_services';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

interface IModalProps {
	onToogleModal: () => void;
	onGenerateDate: (loading:boolean) => void;
}

export class DataLogForm extends React.Component<IModalProps>{
	constructor(props:any)  {
		super(props);

		const data = cloneDeep(props.dataToUpdate);
		this.state = {
			dataDate: props.dataDateFormat || undefined,
			days: props.dataDateFormat && DateTool.getDayOnStream(props.currentPlant.catalysts, props.dataDateFormat),
			dataDateFormat: props.dataDateFormat && moment(props.dataDateFormat) || undefined,
			dataToUpdate: this.populateData(data),
			selectedOption: {}
		}

		this.handleChangeDays = this.handleChangeDays.bind(this)
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeTime = this.handleChangeTime.bind(this);
	}

	public componentDidMount(): void {
		const {dataDateFormat}: any = this.props;
		this.generateAlldayHours();
		this.generateCurrentDate(dataDateFormat);
		this.getAllSerialCodesLimits();
	}

	private generateCurrentDate(date: string): void {
		let time = moment(date);
		this.setState({
			selectedOption: date ? {value: time.hour(), label: time.format("HH:mm") + (time.hour() < 12 ? ' AM' : ' PM')} : {value: 0, label: '00:00 AM'}
		})
	}

	private getAllSerialCodesLimits(): void {
        ChartService.getSerialCodeLimits().then((resp: any) => {
            this.setState({
                serialCodeLimits: resp.data
            })
        })
    }

    private getSerialCodeLimits(serialCodeName: string, minOrMaxValue: string): any {
        const {serialCodeLimits}: any = this.state;
        let dataSerial:any =  find(serialCodeLimits, (dataSerial: any) => {
            return dataSerial.serialCode === serialCodeName ;
        });

        if(dataSerial){
            return minOrMaxValue==='minValue' ? dataSerial.minValue : dataSerial.maxValue
        }
    }

	private populateData(data: any): any {
		const {editing, allSerialCode}: any = this.props;
		const mySerialCode: any = cloneDeep(allSerialCode);
		if (!editing) {
			return data
		} else {
			const newData = map(mySerialCode, (item: any) => {
				let matchedItem = find(data, {serialCode: item.serialCode}) || {} as any;
				item.dataValue = matchedItem.dataValue;
				item.id = matchedItem.id

				return {
					dataValue: item.dataValue, itemName: item.itemName,
					unit: item.unit, serialCode: item.serialCode, systemSerialCode: item.systemSerialCode, id: item.id
				};
			})

			return newData;
		}
	}

	private generateAlldayHours(): void {
		let allSelectOptions: object[] = [];
		for (var i = 0; i < 24; i++) {
			allSelectOptions.push({value: i, label: (i >= 10 ? i.toString() : '0' + i.toString()) + ':00 ' + (i < 12 ? 'AM' : 'PM')})
		}
		this.setState({
			allSelectOptions: allSelectOptions
		})
	}

	private checkForm(): string[] {
		const {serialCodeLimits}: any = this.state;
		let errors: string[] = [];
		const {dataToUpdate, days}: any = this.state;

		// Validate input value
		dataToUpdate.map((data: any) => {
			const dataSerial:any = find(serialCodeLimits, (dataSerial: any) => {
				if(data.systemSerialCode === dataSerial.serialCode){
					return dataSerial;
				}
			});

			if(dataSerial && dataSerial.isOptional){
				if(data.dataValue !== undefined && data.dataValue !== null && data.dataValue !== "" &&
					(parseFloat(data.dataValue) < parseFloat(dataSerial.minValue) ||
							parseFloat(data.dataValue) > parseFloat(dataSerial.maxValue))){
								errors.push(data.serialCode);
				}
			} else if(data.dataValue === undefined || data.dataValue === null || data.dataValue === "" ||
				(dataSerial && (parseFloat(data.dataValue) < parseFloat(dataSerial.minValue) ||
					parseFloat(data.dataValue) > parseFloat(dataSerial.maxValue)))) {
						errors.push(data.serialCode);
			}
		})

		if (!days) {
			errors.push('errorDataDate')
		}

		this.setState({
			errors: errors
		})

		return errors;
	}

	protected handleSubmit(event: any): any {
		const {match, onToogleModal, editing, onGenerateDate}: any = this.props;

		event.preventDefault();
		const errors = this.checkForm();

		if (!isEmpty(errors)) {
			return true;
		}

		let data = this.shapeData();
		ChartService[editing ? 'updateChartsData' : 'createChartsData'](match.params.id, data).then((resp: any) => {
			if (resp.data.code !== 200) {
				this.setState({
					apiError: true,
					apiErrorMessage: resp.data.message
				})
			} else {
				onGenerateDate(false);
				onToogleModal();
			}
		})
	}

	private handleChangeDays(event: any): void {
		const {currentPlant}: any = this.props;
		const targetMoment = moment(currentPlant.ignitionDateStart).add(event.target.value, 'days');
		const state = {
			dataDate: targetMoment.format('YYYY-MM-DD'),
			dataDateFormat: targetMoment,
			days: targetMoment && DateTool.getDayOnStream(currentPlant.catalysts, targetMoment.format('YYYY-MM-DD'))
		};
		// console.log('handleChangeDays()', state, state.dataDateFormat.toString());
		this.setState(state);
	}

	protected handleChange(event: any, index: number): void {
		const {dataToUpdate}: any = this.state;
		const target: any = event.target;
		dataToUpdate[index][target.name] = event.target.value
		this.setState({
			dataToUpdate: dataToUpdate
		});
	}

	private handleChangeTime(event: any): void {
		this.setState({
			selectedOption: event
		});
	}

	private handleChangeDate(targetMoment: any): void {
		const {currentPlant}: any = this.props;
		const state = {
			dataDate: targetMoment.format('YYYY-MM-DD'),
			dataDateFormat: targetMoment,
			days: targetMoment && DateTool.getDayOnStream(currentPlant.catalysts, targetMoment.format('YYYY-MM-DD'))
		};
		// console.log('handleChangeDate()', state, state.dataDateFormat.toString());
		this.setState(state)
	}

	private getGroup(name: string): string {
		const {allSerialCode}: any = this.props;
		return (find(allSerialCode, {serialCode: name}) || {} as any).groupName;
	}

	private shapeData(): void {
		const {dataToUpdate, dataDateFormat, selectedOption}: any = this.state;
		const {editing}: any = this.props;

		const date = dataDateFormat.format('YYYY-MM-DD') + ' ' + selectedOption.label.replace(/AM|PM/, '').trim() + ':00';
		// let data = dataToUpdate.filter((item: any) => {
		// 	return item.dataValue
		// });

		return dataToUpdate.map((item: any) => {
			return {
				...editing ? {id: item.id} : {},
				...{dataDatetime: date},
				...!editing || (editing && !item.id) ? {serialCode: item.serialCode} : {},
				dataValue: (item.dataValue !== "") ? item.dataValue : null
			}
		})
	}

	private isInputValueOptional(serialCode: string): boolean {
		const {serialCodeLimits}: any = this.state;

		const dataSerial:any = find(serialCodeLimits, (dataSerial: any) => {
			if(serialCode === dataSerial.serialCode){
				return dataSerial;
			}
		});

		return dataSerial ? dataSerial.isOptional : false;
	}

	render() {
		const {days, errors, allSelectOptions, selectedOption, dataDateFormat, dataToUpdate, apiErrorMessage, apiError}: any = this.state;
		const {onToogleModal, editing}: any = this.props;

		return (
			<div className="add-data-form-container">
				<form onSubmit={this.handleSubmit}>
					<div className="row popup-header admin-popup-title">
						<span className="new-data-log"> {editing ? 'Edit' : 'Add New'} Data log</span>
						<img className="close-popup"
							 src="/assets/images/btn-close-white.png"
							 alt={ "Close Modal" }
							 onClick={onToogleModal} />
					</div>
					<div className="add-data-form-container-date-selection">
						<div className="add-data-form-container-date-selection-content">
							<img src="/assets/images/btn-date.png"
								 alt="date options"
								 className="image-date-selection"/>
							<strong className="small-left large-padding-time-date-selection"> Time : </strong>
							<div className="days-selection">
								<span className="days-selection-title">Day -</span> <input type="number"
																						   value={ days }
																						   onChange={ this.handleChangeDays }
																						   className="data-form-days"
																						   placeholder="Days"/>
							</div>

							<DatePicker
								selected={ dataDateFormat }
								selectsStart
								onChange={ this.handleChangeDate }
								className="date-picker global-grey comment-picker"
								utcOffset={ 0 }
								placeholderText={ "Choose date" }
							/>

							<Select
								value={selectedOption}
								onChange={this.handleChangeTime}
								options={allSelectOptions}
								className='select-category-drop-down-hours'
								isSearchable={false}
							/>
						</div>
						{includes(errors, 'errorDataDate') &&
                        <p className="alert alert-danger"> Please select a correct date </p>
						}
					</div>
					<div className="add-data-form-container-form-body">
						{
							dataToUpdate.map((data: any, idx: number) => {
								return (
									<div className="add-data-form-container-form-body-field-content"
										 key={idx}>
										<label> <span className="not-bold-label"> {this.getGroup(data.serialCode)} - </span> {String.capitalizeFirstLetter(data.itemName)} ({data.unit}) </label>
										<input type="number"
											name="dataValue"
											value={dataToUpdate[idx].dataValue}
											onChange={(e: any) => this.handleChange(e, idx)}/>
										{ this.isInputValueOptional(data.systemSerialCode) && <span className="optional"> (Optional) </span>}
										{ includes(errors, data.serialCode) &&
											<span className="alert alert-danger error-message">Input value must be between {this.getSerialCodeLimits(data.serialCode, 'minValue')} to {this.getSerialCodeLimits(data.serialCode, 'maxValue')} </span>
										}
									</div>
								)
							})
						}
					</div>
					<div className="add-data-form-container-form-footer">
						{(apiError || includes(errors, 'errorData')) &&
                        <p className="alert alert-danger"> {apiErrorMessage || 'Please input all datas'} </p>
						}
						<input type="submit"
							   value="Submit"
							   className="single-operation-limit-input-button"
							   onClick={(e: any) => this.handleSubmit(e)}/>
					</div>
				</form>
			</div>
		)
	};
}
