export class DelayTools {
  public static requestAnimationTimeOut(callback:any,delay:number) :any{
   var dateNow=Date.now,
       requestAnimation=window.requestAnimationFrame,
       start=dateNow(),
       stop:any,
       timeoutFunc=function(){
        dateNow()-start<delay?stop||requestAnimation(timeoutFunc):callback()
       };
   requestAnimation(timeoutFunc);
   return{
    clear:function(){stop=1}
   }
  }
}
