import * as React from 'react';
import {SideNav, Header} from './';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import {SideBarLoggedOut} from './SideBarLoggedOut';
import {FindPlant} from '../../_helpers';
import {ProfileManager} from '../AccountManager/ProfileManager';
import './Menu.css';

class Menu extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);
		this.state = {
			isRealPlant: new FindPlant(props.plants, props.location).isRealPlant,
			isBubbleOpen: false
		}
		this.toggleBubble = this.toggleBubble.bind(this);
	}

	protected toggleBubble(e: any, close?: boolean): void {
		const {state}: any = this;
		this.setState({
			isBubbleOpen: close ? !close : !state.isBubbleOpen,
			gearRef: e.target
		});
	}

	private closeBubble() {
		this.setState({
			isBubbleOpen: false
		});
	}

	render() {
		const {isRealPlant, gearRef}: any = this.state;
		const {getPlants, updateSpecificItemPlants}: any = this.props

		if (!isRealPlant || !localStorage.getItem('user')) {
			return (
				<SideBarLoggedOut />
			)
		}

		return (
			<React.Fragment>
				<div id="sidebar-menu" className="sideBarMenuContainer">
					<Header key="header" {...this.props}
							toggleBubble={this.toggleBubble} />
					<SideNav key="sideNav" {...this.props}
							 getPlants={getPlants}
							 updateSpecificItemPlants={updateSpecificItemPlants} />
				</div>

				<ProfileManager {...this.state}
								toggleBubble={this.toggleBubble}
								onCloseBubble={() => this.closeBubble()}
								gearRef={gearRef} />
			</React.Fragment>
		);
	}
}

const MenuRouted = withRouter(Menu as React.ComponentType<any>);
export {MenuRouted as Menu};
