import React from 'react';
import Highcharts from 'highcharts/highstock';
require('highcharts/highcharts-more')(Highcharts);
import {
  HighchartsStockChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  AreaSplineRangeSeries,
  SplineSeries,
  Navigator,
  Tooltip,
  PlotLine
} from 'react-jsx-highstock';
import './SimulatorChart.css';
import { map, maxBy } from 'lodash';
import { crosshair } from '../../_constants';
import { toolTipStyle } from '../../_constants';
import * as moment from 'moment';


class SimulatorChart extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      crosshair: crosshair,
      toolTipStyle: toolTipStyle,
      titles: map(props.chart, (item) => {
        return item.name
      }),
      lastDay: (maxBy(props.chart.range.data, (item) => {
        return item[0]
      }) || [])[0]
    }
  }

  handleClick() {
    // console.log('loutre');
  }

  generateNavigator(item) {
    return (
      <Navigator.Series seriesId={item} key={item} />
    );
  }

  // TODO: move me somewhere else
  loadStatic() {
    let chart = this.props.chart;
    let unit  = this.props.unit;
    let chartNameData =  chart.range.name;
    let chartName = chartNameData.charAt(0).toUpperCase() + chartNameData.substr(1);

    const formatter = function() {
      let numberOfComment = this.point.keys && this.point.keys[0] > 1 ? '<div style="font-size:12px; line-height:25px">' + this.point.keys[0] + ' Comments on this day</div>' : '';
      let comment = this.point.description ? '<div style="font-size:12px;line-height:15px; margin:3px 0px; padding:6px;background-color:rgba(255,255,255,0.1)!important;color:white">' + this.point.description +'</div>' : '';

      let month = new Date(this.x).getMonth() + 1;
      month = month < 9 ? '0'+ month : month;
      let day =  new Date(this.x).getDate()
      day = day < 9 ? '0'+ day : day;
      let date = month + '-' + day;

      let measured = chart.realData.data.filter(item => item[0] == this.x)[0][1];
      let predicted = chart.simulatedData.data.filter(item => item[0] == this.x)[0][1];

      return '<div style="font-size:14px; line-height:25px">' + chartName + '</div><div style="font-size:12px; line-height:22px;"><span style="color:grey">' +  chartName + ', measured' + '['+ unit +']' + ' </span> ' + measured.toFixed(3) + '</div><div style="font-size:12px; line-height:22px;"><span style="color:grey">' +  chartName + ', predicted'+ '['+ unit +']'+ ' </span> ' + predicted.toFixed(3) + '</div><div style="font-size:12px; line-height:22px"><span style="color:grey">Day : </span> ' + date + ' </div> ' + comment + numberOfComment;
    }

    this.setState({
      formatter, chartName
    })
  }

  componentDidMount() {
    this.loadStatic();
  }

  //make chart available to call as object for export feature
  getChart = chart => {
    this.chart = chart;
  }

  render() {
    const { formatter, crosshair, titles, lastDay, chartName } = this.state;
    const { chart, chartWidth, unit } = this.props;
    let dateTimeLabelFormats = {
        day: '%Y<br/>%m-%d',
        week: '%Y<br/>%m-%d',
        month: '%Y-%m',
        year: '%Y'
    }

    return (
      <div className="simulator-chart-graphs">
        <HighchartsStockChart callback={this.getChart} plotOptions={{column: { stacking: 'normal' }}}>
          <Chart zoomType="x" width={chartWidth - 20}/>

          <Tooltip backgroundColor="#4e4e4e" borderColor="none"
            borderRadius="5" split={false} useHTML={true} valueDecimals="3" style={toolTipStyle}
            formatter={formatter}/>

          <XAxis crosshair={crosshair}
          tickPixelInterval={200}
          startOnTick={false}/>

          <YAxis crosshair={crosshair} labels={{x: 0, align: 'left', y: 17}} id="unit">
            <YAxis.Title useHTML align='high' textAlign='left'
              offset='0' rotation='0' x={0} y={0} reserveSpace={false}
              >{unit}</YAxis.Title>
              <AreaSplineRangeSeries id={chart.range.name} name={chart.range.name} key={chart.range.name} data={chart.range.data} onClick={this.handleClick} color={chart.range.color} zones={[{className: 'simulator-chart-range'}]}/>
              <SplineSeries id={chart.realData.name} name={chart.realData.name} key={chart.realData.name} data={chart.realData.data} onClick={this.handleClick} color={chart.realData.color}/>
              <SplineSeries id={chart.simulatedData.name} name={chart.simulatedData.name} key={chart.simulatedData.name} data={chart.simulatedData.data} onClick={this.handleClick} color={chart.simulatedData.color} dashStyle="shortdash"/>
          </YAxis>

          <Navigator height={100}
            xAxis={dateTimeLabelFormats={dateTimeLabelFormats}}>
            {titles.map(this.generateNavigator)}
          </Navigator>
        </HighchartsStockChart>
      </div>
    );
  }
}

export default withHighcharts(SimulatorChart, Highcharts);
