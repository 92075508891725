import * as React from 'react';
import './ModalFormDelete.css';

interface IModalProps {
  handleDeleteForm: () => void;
  handleHideForm: () => void;
}

export class ModalFormDelete extends React.Component<IModalProps>{
    constructor(props:any)  {
        super(props);
        this.deleteForm = this.deleteForm.bind(this);
        this.hideForm = this.hideForm.bind(this);
    }

    protected deleteForm() :void {
      const { handleDeleteForm } = this.props;
      handleDeleteForm();
    }

    protected hideForm() :void {
      const { handleHideForm } = this.props;
      handleHideForm();
    }

    render() {

        return (
            <div className="validation-modal">
              <div className="validation-modal-content">
                <div className="row">
                  You will lose all the content you have entered, <br/>
                  are you still sure to exit ?
                </div>
                <div className="row top20">
                  <div className="col-md-2">
                  </div>
                  <div className="col-md-4">
                    <button className="discard-deletion" onClick={() => this.deleteForm()}>Discard</button>
                  </div>
                  <div className="col-md-4">
                    <button className="cancel-deletion" onClick={() => this.hideForm()}>Cancel</button>
                  </div>
                  <div className="col-md-2">
                  </div>
                </div>
              </div>
            </div>
        )
    };
};
