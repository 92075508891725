export class FindBrowser{
  static async getBrowserInfo(){
      var browser, userAgent = navigator.userAgent;

      if (userAgent.indexOf("Firefox") > -1) {
          browser = "Firefox";
        } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
          browser = "Opera";
        } else if (userAgent.indexOf("Trident") > -1) {
          browser = "Microsoft Internet Explorer";
        } else if (userAgent.indexOf("Edge") > -1) {
          browser = "Edge";
        } else if (userAgent.indexOf("Chrome") > -1) {
          browser = "Chrome";
        } else if (userAgent.indexOf("Safari") > -1) {
          browser = "Safari";
        } else {
          browser = "unknown";
        }

      return {browser: browser};
  }
}