import * as React from 'react';
import './ListComment.css';
import { Comment } from '../../_models/';
import { withRouter } from 'react-router';
import { Glyphicon,  Button } from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import Select from 'react-select';
import { CommentService } from '../../_services';
import { hexToRgb, PlantHelper } from '../../_helpers';
import { find } from 'lodash';
import { permissionsConstants } from '../../_constants';


class ListComment extends React.Component{

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props: any) {
        super(props);
        this.state = {
            nbOfComments: 20
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.addComment = this.addComment.bind(this);
    }

    private handleChange(selectedOption: any) {
        const {onHandleChange}: any = this.props;
        this.setState({
            nbOfComments: 20
        })
        onHandleChange(selectedOption);
    }

    private handleClick(comment: Comment) {
        const {onHandleClick, match}: any = this.props;
        onHandleClick(comment);
        comment.readInd = true;
        CommentService.readComment(match.params.id, comment.id).then(() => {

        })
    }

    private addComment() {
        const {addComment, onAddHandleChange}: any = this.props;
        onAddHandleChange(addComment);
    }

    private addfavoriateComment(commentId: number, status: any): any {
        const {match, selectedComments}: any = this.props;
        let selectedComment = find(selectedComments, (comment: any) => comment.id == commentId);
        selectedComment.setFavoriteStatus(status);
        this.handleClick(selectedComment);
        CommentService.addFavoriteComment(match.params.id, commentId).then(() => {

        })
    }

    private loadMore(): void {
        const {pagination, updateComment}: any = this.props;
        updateComment(pagination + 20);
    }

    render() {
        const { options, selectedOption, selectedComments, preloadedData,
          selectedComment, openManually, user, pagination, nbOfComments, location } : any= this.props;

        const { handleClick } : any = this;
        const getRBG = preloadedData && hexToRgb(preloadedData.selectedChart.color);
        let styleConst :any = preloadedData && {
            color: preloadedData && preloadedData.selectedChart.color,
            backgroundColor: preloadedData && 'rgb( ' + getRBG.r + ',' + getRBG.g + ',' + getRBG.b + ',0.3)',
            fontWeight: 'normal !important',
            maxWidth: '140px'
        }

        return (
            <div className="comment-list-wrap">
                <div className={'contenu-listing ' + (user.lookUpPermission(permissionsConstants.COMMENT_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location)) ? 'selection-comment' : '')}>
                    <h2 className="comment-main-title">
                        <b>COMMENTS</b>
                        {(preloadedData && !openManually) &&
                            <p style={styleConst} className="current-chart">{preloadedData.selectedChart.label} - Day {selectedComment.dayOfComment}</p>
                        }
                    </h2>

                    <Select value={!preloadedData || openManually ? selectedOption : {}}
                            onChange={this.handleChange}
                            options={options}
                            isSearchable={false} />

                    { user.lookUpPermission(permissionsConstants.COMMENT_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
                        <Button bsStyle="primary" block className="purple-button add-comment" onClick={this.addComment}>
                            <span className="plus-icon "><Glyphicon glyph="plus"/></span><span>Add Comment</span>
                        </Button>
                    }
                </div>

                <div className="all-contenu-listing">
                    {
                      selectedComments.map((comment:Comment) => {
                        return (
                          <div className={'row individual-comment contenu-listing ' + (selectedComment.id === comment.id ? 'select-comment ' : ' ' )  + (comment.readInd ? 'read-comment' : 'not-read-comment' )} key={ comment.id } onClick={()=>handleClick(comment)}>
                            <div className="comment-header">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span className="comment-user">{ comment.getUserAcronmy() }</span>
                                      <span className="comment-title">{ comment.getPlantType() }</span>
                                      { comment.serialName
                                        &&<span className="comment-title"> { comment.serialName }</span>
                                      }
                                    </td>
                                    <td className="right-align"><img src={selectedComment.id === comment.id ? selectedComment.favoriteIcone : comment.favoriteIcone} alt="" onClick={()=>selectedComment.id === comment.id  ? this.addfavoriateComment(selectedComment.id, selectedComment.starStatus):this.addfavoriateComment(comment.id, comment.starStatus)}/></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="comment-date top-10">
                              <p>
                                { comment.dataDate && <span> D-{ comment.dayOfComment } / </span> }
                                { comment.dataDate }
                              </p>
                            </div>

                            <div className="comment-date">
                              <p className="comment-list-title">{ comment.subject }</p>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      (nbOfComments) > pagination &&
                      <div className="load-more-comment-wrapper">
                        <button className="load-more-comment" onClick={() => { this.loadMore() }}> Load More </button>
                      </div>
                    }
                </div>
          </div>
        )
    };
};

const ListCommentRouted = withRouter(ListComment as React.ComponentType<any>);
export { ListCommentRouted as ListComment };
