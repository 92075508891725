import { alertConstants } from '../_constants';
 
export class AlertActions {
	static success(message :string) :object{
	    return { type: alertConstants.SUCCESS, message };
	}
	static error(message :string) :object{
	    return { type: alertConstants.ERROR, message };
	}
	static clear() :object{
	    return { type: alertConstants.CLEAR };
	}
}
