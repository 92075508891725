// import { Comment } from './';
// import { isEmpty } from 'lodash';
import { SubCategory } from '../_helpers';

export class SelectOptions {
  elements: object[] = [];
  favoriteComments?: any = [];
  genericComments?: any = [];
  constructor(data: any) {
    // We deal with the case where we are on dashboard page
    if (data.params.type || data.params.recommandation) {
      // currentCategoryComments = this.getCurrentCategoryComments(comments, param);
      // We push the current page category comment
      this.elements.push({value: 'subCategoryCode', label: SubCategory.getProperSubcategoryName(data.params.type || data.params.recommandation) , number: 1, code: data.params.type || data.params.recommandation});
    }
    // We push all elements
    this.elements.push({value: 'ALL', label: 'all', number: 1, code: 'ALL'});
    // We push the element only for the product ListCommentRouted
    this.elements.push({value: 'GENERAL_COMMENTS', label: 'General Comments', number: 1, code: 'GENERAL_COMMENTS' });
    // Favorite comments
    this.elements.push({value: 'BOOKMARKED', label: 'Bookmarked', number: 1, code: 'BOOKMARKED' });
  }
}
