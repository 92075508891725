import * as React from 'react';
import './Consult.css';
import Modal from '../Common/Modal/Modal';
import { consultBackdropStyle, consultModalStyle, permissionsConstants } from '../../_constants';
import { ChatService } from '../../_services';
import { User } from '../../_models';
import { ConsultForm } from './Form';
import { Listing } from './Listing';
import { orderBy, find } from 'lodash';
import { PlantHelper } from '../../_helpers';
import * as PropTypes from 'prop-types';

export class Consult extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props:any) {
    super(props);
    const { match } :any = props;
    const plants = JSON.parse(localStorage.getItem('plants') || '')
    const user = JSON.parse(localStorage.getItem('user') || '')
    this.state = {
      isOpen: false,
      user: new User(user),
      numberOfPost: 10,
      type: window.innerWidth < 1300 ? 'pad' : 'computer',
      plant: find(plants, (plant:any) => { return plant.code === props.match.params.id }),
      plantId : match.params.id
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.getMessages();
  }

  componentDidMount() :void {
    this.scrollToBottom('messagesEnd');
  }

  protected toggleModal () :void {
    const { isOpen } :any = this.state;
    this.setState({
      isOpen: !isOpen
    });
    // If we open the window it will refresh the data
    if (!isOpen) {
      this.getMessages();
    }
  }

  private getMessages(more?:boolean) :void {
    const {  match } :any = this.props;
    let { numberOfPost } :any = this.state;
    if (more) {
      numberOfPost += 10;
    }
    ChatService.getChatId(match.params.id).then((chatId:any) => {
        ChatService.getMessages(match.params.id, chatId, 1, numberOfPost).then((resp:any) => {
            this.setState({
                messages: orderBy(resp.data.list, ['id'], ['asc']),
                numberOfPost: numberOfPost,
                maxNbOfComment: resp.data.total
            });
            // We don't want to scroll down when we load more
            if (!more) {
                this.scrollToBottom('messagesEnd');
            }
        })
    })

  }

  private scrollToBottom(ref:string) :void {
    const { type } :any = this.state;
    let size :number = type === 'pad' ? 250 : 50;
    (this.refs[ref] as HTMLElement).scrollTop = (this.refs[ref] as HTMLElement).scrollHeight + size;
  }


  public UNSAFE_componentWillUpdate(_:  any): void {
      const {plantId} :any = this.state
      const {match} :any  = this.props
      let currentPlantId = match.params.id
      if(plantId && plantId === currentPlantId){
          return
      }
      const plants = JSON.parse(localStorage.getItem('plants') || '')
      this.setState({
          plant: find(plants, (plant:any) => { return plant.code === currentPlantId }),
          plantId : currentPlantId
      })
      this.getMessages();
  }

  render() {
    const { location } : any= this.props;
    const { isOpen, plant, user } :any = this.state;
    return (
      <div>
        <div onClick={this.toggleModal} className="header-icons">
          <img src="/assets/images/btn-consultant.png" />
        </div>

        <Modal state={isOpen}
          onClose={this.toggleModal}
          backgroundStyle={consultBackdropStyle}
          modalStyle={consultModalStyle}>
            <div className="header-consult border-bottom-light-grey">
              <p>
                <span className="consult-header-title">CONSULT</span> - { plant.name }<br/>
                <span className="global-grey normal-text">Feel free to consult BASF, data analysts will answer your question.</span>
              </p>
            </div>
            <div className="chat-content">
              <div className="consult-content-message" ref='messagesEnd'>
                <Listing {...this.state} handleNewMessage={this.getMessages}></Listing>
              </div>
            </div>
            <div className="chat-input">
              { user.lookUpPermission(permissionsConstants.CONSULT_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
                 <ConsultForm
                  {...this.state} {...this.props}
                  handleNewMessage={this.getMessages}/>
              }
            </div>
        </Modal>
      </div>
    );
  }
}
