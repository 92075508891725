import * as React from 'react';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import './NotFound.css';

class NotFound extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);
		this.goToHomePage = this.goToHomePage.bind(this);
	}

	goToHomePage(): void {
		const {plants, history}: any = this.props;
		if (plants !== undefined) {
			if (typeof window !== undefined) window.location.href = '/plants/' + plants[0].code + '/dashboard';
		} else {
			history.push('/logout');
		}
	}

	render() {
		return (
			<div className="not-found">
				<h2>Sorry, the page you were looking for doesn’t exist.</h2>
				<p>If you need help, please contact dashboard administrator of BASF: <u><a href="mailto:my-catalysts@basf.com">my-catalysts@basf.com</a></u></p>
				<button className="redirect-btn"
						onClick={this.goToHomePage}>Back to Dashboard
				</button>
			</div>

		)
	};
}

const NotFoundRouted = withRouter(NotFound as React.ComponentType<any>);
export {NotFoundRouted as NotFound};
