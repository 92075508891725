export const breadCrumbConstant :any = {
  menuItem: {
    dashboard: ['Dashboard'],
    'data-log': ['Data log', 'Data Upload'],
    'sgraph': ['Simulator', 'Historical Accuracy'],
    'fgraph': ['Forecast', 'Long Term Prediction Model'],
    simulator: ['Simulator'],
    forecast: ['Forecast']
  }
}
