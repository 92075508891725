import { BaseService } from './base.services'
import { Ressource } from '../_helpers';
import { OperationLimit } from '../_models'

export class OperationLimitService extends BaseService {
    static async updateOperationLimit(operationLimits: OperationLimit[], plantCode: string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/operationLimit/list', [plantCode]);
      return this.getAxios.put(ressource.fullUrl, operationLimits, this.basicOptions)
        .then((resp: any) => {
          return resp;
        })
    }

    static async getOperationLimit(plantCode: string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/operationLimit/list', [plantCode]);
      return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
      .then((comments: any) => {
          return comments.data;
      });
    }
}
