import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Login.css';
import { PlantService } from '../../_services';
import { FindBrowser } from '../../_helpers';
import { UserActions } from '../../_actions';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { first } from 'lodash';
import * as content from '../../content/content.json';
import {FeatureService} from '../../_services/feature.services';
import {Feature} from '../../_models/Feature';

class LoginPage extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props:any) {
        super(props);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            className: false,
            browserRecommendation: false,
            plant: props.plants && props.plants[0].code.toLocaleLowerCase().split(' ').join('-'),
            content: content.pages.login.en,
            featureLoaded: false
        };

        // load feature toggle for login buttons
        FeatureService.getFeature('public_landingpage.simple_login').then((feature: Feature) => {
            // console.log('LoginPage.constructor() - feature state loaded', feature.active);
            if (!feature.active) {
                window.location.href = '/';
            } else {
                this.setState({
                    featureLoaded: true
                })
            }
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getBrowserInfo();
    }

    private getBrowserInfo(): void {
        FindBrowser.getBrowserInfo().then((resp:any) => {
            if (!(resp.browser.match("Chrome") || resp.browser.match("Firefox"))) {
                this.setState({
                    browserRecommendation: true
                });
            }
        });
    }

    public handleChange(e:any) :void{
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    public handleSubmit(e: any): void {
        e.preventDefault();

        this.setState({submitted: true});
        const {username, password}: any = this.state;

        if (username && password) {
            this.setState({loading: true});
            UserActions.login(username, password).then((resp: any) => {
                if (resp.response !== 'success') {
                    this.setState({
                        error: resp,
                        loading: false
                    })
                } else {
                    PlantService.getAllEnrich(resp.token).then((resp: any) => {
                        this.setState({
                            plant: first(resp.data),
                            isLoading: false,
                            code: resp.code
                        });

                        // We store globaly
                        localStorage.setItem('plants', JSON.stringify(resp.data));
                        if (typeof window !== undefined) window.location.href = '/plants/' + (((resp.data || [])[0]) || {} as any).code + '/dashboard';
                    })
                }
            })
        }
    }

    handleSubmitFS = (e:any) => {
        e.preventDefault();
//        this.setState({submitted: true});
        this.setState({loading: true});

        if (typeof window !== undefined) window.location.href = '/flogin';
    }

    public componentDidMount() :void {
        const {location}: any = this.props;

        if (location.state && location.state.message === 'expired') {
            this.setState({
                error: 'User is not active or has expired',
                submitted: true
            });
        }
    }

    render() {
        const {loggingIn}: any = this.props;
        const {username, password, submitted, error, loading, content, browserRecommendation, featureLoaded}: any = this.state;
        const {welcome, pDashboard, email, usernameRequired, pwdRequired, pwdWrong, login, forgetpwd, federationServiceText, federationService, browserWarning, browserWarningMessage}: any = content;

        if (!featureLoaded) {
            return(<React.Fragment />);
        }

        return (
            <div className="col-md-6 col-md-offset-6 left-align welcome-container">
                { loading &&
                    <div className="spinner">
                        <div className="double-bounce1"/>
                        <div className="double-bounce2"/>
                    </div>
                }

                <div className="welcome-caption">
                    <h3>{ welcome }</h3><br/>
                    <h2>{ pDashboard }</h2>
                </div>

                <form name="form" className="login-form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + ((submitted && !username) || (submitted && error) ? ' has-error' : '')}>
                        <label htmlFor="username" className="label-basf-version login-email">{ email }</label>
                        <input type="text" className="form-control input-basf-version " name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">{ usernameRequired }</div>
                        }
                    </div>

                    <div className={'form-group' + ((submitted && !password) || (submitted && error) ? ' has-error' : '')}>
                        <label htmlFor="password" className="label-basf-version login-pwd">Password</label>
                        <input type="password" className="form-control input-basf-version " name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">{ pwdRequired }</div>
                        }
                    </div>

                    {submitted && error &&
                        <div className="help-block alert alert-danger">{ error || pwdWrong }</div>
                    }

                    <div className="form-group log-in-row">
                        <button className="btn btn-primary submit-basf-version">{ login }</button>
                        {loggingIn &&
                           <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }

                        <Link to="/forgot-password" className="btn btn-link forget-pwd">{ forgetpwd }</Link>
                        {browserRecommendation &&
                            <div className="help-block">
                                <p>{browserWarning}</p>
                                <p>{browserWarningMessage}</p>
                            </div>
                        }
                    </div>
                </form>

                <form name="form2" className="login-form" onSubmit={ this.handleSubmitFS }>
                    <div className="form-group">
                        <div className="netiq-login">{ federationServiceText }<u><button className="btn btn-primary submit-fs-version">{ federationService }</button></u></div>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state:any) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const LoginPageRouted = withRouter(connect(mapStateToProps)(LoginPage) as React.ComponentType<any>);
export { LoginPageRouted as LoginPage };
