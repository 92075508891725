import { BaseService } from './base.services';
import { Comment } from '../_models';
import { Ressource } from '../_helpers';
import { extend } from 'lodash';

export class CommentService extends BaseService {
    static async getByCategory(plantCode: string, params: any, size?: number) :Promise<Comment[]>{
        let ressource = new Ressource();
        ressource.shapeUrl('/plant/%s/comment/list', [plantCode], extend(params, size ? {size: size} : {}));
        return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
        .then((comments: any) => {
            return comments.data;
        });
    }

    static async getByType(plantCode: string, category: any, size?: number) :Promise<Comment[]>{
        let ressource = new Ressource();
        ressource.shapeUrl('/plant/%s/comment/listByCategory', [plantCode], {category, size});
        return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
        .then((comments: any) => {
            return comments.data;
        });
    }

    static async postComment(comment:any, plantCode: string) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/comment', [plantCode]);
//      comment.body = comment.body.replace(/</g,"&lt;").replace(/>/g,"&gt;");
      return this.getAxios.post(ressource.fullUrl, comment , this.requestOptionsPost({ comment }))
        .then(resp => {
          return resp.data;
        })
    }

    static async uploadImageComment(file: any) :Promise<any> {
      let ressource = new Ressource('/common/file/upload');
      let bodyFormData = new FormData();
      bodyFormData.set('name', file);
      bodyFormData.set('filename', file.name);
      return this.getAxios.post(ressource.fullUrl, bodyFormData , this.requestOptionsPostFile({ bodyFormData }))
        .then(resp => {
          return resp;
        })
    }

    static async addFavoriteComment(plantCode: string, commentId: any) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/comment/%s/userFavorite', [plantCode, commentId]);
      return this.getAxios(this.requestOptionsPut(ressource.fullUrl))
        .then(resp => {
          return resp;
        })
    }

    static async readComment(plantCode: string, commentId: any): Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/comment/%s/readStatus', [plantCode, commentId]);
      return this.getAxios(this.requestOptionsPut(ressource.fullUrl))
        .then(resp => {
          return resp;
        })
    }

    static async getCommentByChartPoint(plantCode: string, plantDataId: any, params: any): Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/chart/data/%s/comment/list', [plantCode, plantDataId], { categoryCode: params.category, subCategoryCode: params.type });
      return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
        .then(resp => {
          return resp.data;
        })
    }
}
