import * as React from 'react';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import './NotFound.css';

class GenericErrorPage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
  constructor(props:any) {
    super(props);
    this.goToHomePage = this.goToHomePage.bind(this);
  }

  goToHomePage() :void {
    if (typeof window !== undefined) window.location.href = '/login';
  }

  render (){
    return(
      <div className="not-found">
        <h2>OOPS... Something went wrong.</h2>
        <p>Please try to access after some time Or contact the administrator: <u><a href="mailto:my-catalysts@basf.com">my-catalysts@basf.com</a></u></p>
        <button className="redirect-btn" onClick={this.goToHomePage}>Back to login page</button>
      </div>
    )
  };
}

const GenericErrorPageRouted = withRouter(GenericErrorPage as React.ComponentType<any>);
export { GenericErrorPageRouted as GenericErrorPage };
