import { BaseClass } from './BaseClass';
import { find, forEach, some, isEmpty, sumBy } from 'lodash';

export class BaseItem extends BaseClass {
	seqNum!: number;
	status!: string;
	type!: string;
	version!: number;
	hasUnReadComment!: boolean;
	constructor(data:any) {
		super();
		this.code = data.code;
		this.name = data.name;
		this.hasUnReadComment = data.existUnreadComments;
	}
}

export class MenuItems extends BaseItem{
	subCategoryList!: BaseItem[];
	unReadItemNumber!: number;
	constructor(data:any) {
		super(data);
		const subCategoryList: BaseItem[] = [];
		forEach(data.subCategoryList, (data:any) => {
			subCategoryList.push(new BaseItem(data));
		});
		this.subCategoryList = subCategoryList;
		this.checkHasUnReadComment();
		this.getNumberOfUnreadItem();
	}
	public updateUnreadComment(subCategory: string): void {
		(find(this.subCategoryList, {code: subCategory }) || {} as any).hasUnReadComment = false;
		this.checkHasUnReadComment();
	}
	private checkHasUnReadComment(): void {
		this.hasUnReadComment = some(this.subCategoryList, { hasUnReadComment: true });
	}
	private getNumberOfUnreadItem(): void {
		this.unReadItemNumber = sumBy(this.subCategoryList, (item: any) => {
			return item.hasUnReadComment;
		});
	}
}

export class MenuClass {
  categoryList!: MenuItems[];
	code!: string;
	unReadItemNumber!: number;
	constructor(menuData:any, plantId:string) {
		const categoryList :MenuItems[] = (find(menuData, {code: plantId}) || {} as any).categoryList;
		const categoryLists: MenuItems[] = [];
		forEach(categoryList, (categoryList:MenuItems) => {
			categoryLists.push(new MenuItems(categoryList));
		});
		this.categoryList = categoryLists;
		this.getNumberOfUnreadItem();
	}
	public updateUnreadComment(category: string, subCategory: string): void {
		const properCategory = (find(this.categoryList, { code: category }) || {} as any);
		if (!isEmpty(properCategory)) {
			properCategory.updateUnreadComment(subCategory)
		}
	}
	private getNumberOfUnreadItem(): void {
		this.unReadItemNumber = sumBy(this.categoryList, 'unReadItemNumber');
	}
}
