import * as React from 'react';
import './SimulatorHeader.css';
import * as moment from 'moment';
import { find, includes } from 'lodash';

interface IModalProps {
  setResult?: (initial?:boolean) => Promise<any>;
}

export class SimulatorHeader extends React.Component<IModalProps> {
  constructor(props: any) {
    super(props);
    this.goesToResult = this.goesToResult.bind(this);
  }

  // Tricky since we need to call the api before going to this page thank to the nice API
  private goesToResult(): void {
    const { setResult, history, lastDay, plant }: any = this.props;

    if (plant !== undefined) {
      setResult(true).then((resp: any) => {
        history.push({
          pathname: '/plants/' + plant.code + '/simulator/sgraph',
          state: { charts: resp, lastDate: lastDay }
        });
      });
    }  else {
      history.push('/logout');
    }
  }

  render() {
    const { content, plant, match }: any = this.props;
    const currentIgnition: any =  find(plant.catalysts, { status: 'A'});
    const ignitionDateStart =  currentIgnition && currentIgnition.ignitionDateStart;

    return (
      <div className="row simulator-header">
        <div className="third-col">
          <p className="main-text">
            { content.productionStart }
          </p>
          <p className='simulator-header-data'> { moment(ignitionDateStart).format('YYYY-MM-DD') } </p>
        </div>
        <div className="third-col">
          <p className="main-text">
            { content.nbOfReactor }
          </p>
          <p className='simulator-header-data'> { plant.nreactors } </p>
        </div>
        <div className="third-col">
          <p className="main-text">
            { content.catalystVolume }
          </p>
          <p className='simulator-header-data'> { plant.catVol } m³</p>
        </div>
        { !includes(match.url, 'sgraph') &&
          <div className="third-col">
            <div
              className="button-like-simulator"
              onClick={this.goesToResult}>
              { content.history }
            </div>
          </div>
        }
      </div>
    );
  }
}
