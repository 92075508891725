import * as React from 'react';
import './Modal.css';

interface IModalProps {
  state: boolean;
  onClose(): void;
  backgroundStyle: object;
  modalStyle: object;
}

class Modal extends React.Component<IModalProps>{
  render() {
    const { state, onClose, backgroundStyle, modalStyle } :any = this.props

    return (
      <div className={"backdrop slide-in "+ (state ? "show" : "")} style={backgroundStyle}>
        <div className="closeModal" onClick={onClose}>
          <img src="/assets/images/btn-close.png" />
        </div>
        <div className="modalcontent" style={modalStyle}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
