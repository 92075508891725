import * as React from 'react';
import './Comment.css';
import Modal from '../Common/Modal/Modal';
import {ListComment} from './'
import {CommentService} from '../../_services/';
import {Comment, SelectOptions, CommentContainer, Plant} from '../../_models/';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import {SpecificComment} from './SpecificComment';
import {Form} from './Form';
import {map, extend, isEmpty, first, find} from 'lodash';
import {Loader} from '../../_components/Common/Loader/newLoader';
import {backdropStyle, modalStyle} from '../../_constants';


interface IModalProps {
	setComments?: (comments: any[]) => void;
	setCommentData?: (data: any) => void;
	updateData?: () => void;
	updateUnReadComments?: (data: any) => void;
}


class Comments extends React.Component<IModalProps> {
    
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);
		const {user, match}: any = props;
		let plant = find(props.plants, (plant: Plant) => {
			return plant.code === props.match.params.id;
		})

		this.state = {
			isOpen: false,
			comments: [],
			selectedComments: [],
			isLoading: true,
			selectedOption: {},
			options: [],
			addComment: false,
			user: user,
			constPlantId: undefined,
			charts: props.charts,
			allCharts: map(props.charts, (chart: any) => {
				return {label: chart.name, value: chart.dataCode, color: chart.color}
			}),
			plantFirstDay: plant ? plant.catalysts : undefined,
			match: match,
			updatedComment: false,
			openManually: false,
			pagination: 20,
			plantId: match.params.id,
			plantCode: match.params.id,
			showAlert: false
		};

		this.toggleModal = this.toggleModal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleAddChange = this.handleAddChange.bind(this);
		this.updateComment = this.updateComment.bind(this);
		this.updateChartData = this.updateChartData.bind(this);
		this.showModal = this.showModal.bind(this)
		this.handleInterprateComment = this.handleInterprateComment.bind(this)
	}

	// Todo: Refactorme this state if not recommanded anymore but just keep
	// in mind componentwillupdate will check change and state and props
	// that can lead to an infinite loop, A big improvment for the entire
	// system will be to centralize the calls to the page level or event at the
	// app level but that would be long refactoring
	public componentWillReceiveProps(nextProps: any): void {
		const {match, commentForm}: any = this.props;
		const {constPlantId, selectedOption}: any = this.state;

		// In case we change chart page we need to redo the comment processing again
		if (nextProps.match.params.id !== match.params.id) {
			let type = selectedOption ? selectedOption.value : 'ALL';
			this.setState({plantCode: nextProps.match.params.id});
			this.getComments(type, 20, nextProps.match.params.id);
		} else if (nextProps.match.url !== match.url) {
			this.getComments();
		}

		// In case some clicks somewhere on the chat we need to make sure this
		// the form will open with some proper data
		if (nextProps.commentForm && nextProps.commentForm.isLoaded) {
			if (commentForm.isLoaded) {
				this.getCommentByChartPoint(nextProps.commentForm, nextProps.commentForm.data.plantDataId);
				this.setState({
					isOpen: true,
				})
			}
			if (constPlantId !== nextProps.commentForm.data.plantDataId) {
				this.setState({
					isOpen: true,
					openManually: !nextProps.commentForm.listing,
					addComment: !nextProps.commentForm.listing,
					preloadedData: nextProps.commentForm.data
				})

			}
		}
	}

	public componentWillMount(): void {
		let pageType: any = this.findCurrentEntity();
		this.getComments(isEmpty(pageType) ? 'ALL' : 'subCategoryCode');

		// IF we come from a chart page we needs to have the data from this
		// chart comments
		if (!isEmpty(pageType)) {
			this.updateChartData();
		}
		this.getCurrentOption();
	}

	protected toggleModal(): void {
		const {isOpen}: any = this.state;
		const {setCommentData}: any = this.props;
		this.setState({
			isOpen: !isOpen,
			openManually: true,
			constPlantId: 0
		});
		setCommentData && setCommentData({create: false, hasBeenCreated: true});
		let pageType: any = this.findCurrentEntity();
		this.getComments(isEmpty(pageType) ? 'ALL' : 'subCategoryCode');
		this.getCurrentOption();
	}

	private getCurrentOption(): void {
		const {match}: any = this.props;
		let options: any = new SelectOptions(match).elements;
		let option: any = find(options, (item: any) => item.code === (match.params.type || match.params.recommandation || 'ALL'));
		this.setState({
			options: options,
			selectedOption: option
		})
	}

	async getCommentByChartPoint(commentForm: any, plantDataId: number): Promise<void> {
		const {constPlantId}: any = this.state;
		if (constPlantId !== plantDataId) {
			const {match}: any = this.props;
			const {selectedComments, selectedComment, plantFirstDay}: any = this.state;
			return CommentService.getCommentByChartPoint(match.params.id, plantDataId, match.params).then((resp: any) => {
				this.setState({
					selectedComments: commentForm.listing ? new CommentContainer(plantFirstDay, resp.data).comments : selectedComments,
					selectedComment: commentForm.listing ? first(new CommentContainer(plantFirstDay, resp.data).comments) : selectedComment,
					constPlantId: plantDataId,
					nbOfComments: resp.data.length
				})
			})

		}
	}

	private findCurrentEntity(): string {
		const {match}: any = this.props;
		let params: any = {};
		let type = match.params && (match.params.type || match.params.recommandation);
		extend(params, type ? {subCategoryCode: type} : {});
		return params;
	}

	private async getComments(type?: string, nbrOfPage?: number, plantId?: string): Promise<void> {
		const {plantFirstDay, pagination}: any = this.state;
		const {match, updateUnReadComments}: any = this.props;
		let requestData: any = type === 'subCategoryCode' ? this.findCurrentEntity() : (isEmpty(type) ? 'ALL' : type);
		let plantCode: string = isEmpty(plantId) ? match.params.id : plantId;

		return CommentService[type === 'subCategoryCode' ? 'getByCategory' : 'getByType'](plantCode, requestData, nbrOfPage || pagination).then((resp: any) => {
			let selectedComment = first(new CommentContainer(plantFirstDay, resp.data.list).comments)
			this.setState({
				selectedComments: new CommentContainer(plantFirstDay, resp.data.list).comments,
				selectedComment: selectedComment,
				isLoading: false,
				code: resp.code,
				nbOfComments: resp.data.total
			})
			updateUnReadComments && updateUnReadComments(new CommentContainer(plantFirstDay, resp.data.list).unReadComments);
		})
	}

	protected handleClick(comment: Comment): void {
		const {updateUnReadComments}: any = this.props;
		const {selectedComments, plantFirstDay, addComment}: any = this.state;
		this.setState({
			selectedComment: comment,
			showAlert: !!addComment
		});
		updateUnReadComments && updateUnReadComments(new CommentContainer(plantFirstDay, selectedComments).unReadComments);
	}

	protected handleAddChange(addComment: boolean): void {
		this.setState({
			addComment: !addComment,
			showAlert: false
		});
	}

	protected handleChange(selectedOption: any): void {
		const {setCommentData}: any = this.props;
		this.setState({
			selectedOption,
			openManually: true,
			pagination: 20
		});
		setCommentData && setCommentData({create: false, hasBeenCreated: true});
		this.getComments(selectedOption.value, 20);
	}

	private updateComment(nbrOfPage?: number): void {
		const {selectedOption, pagination}: any = this.state;
		this.getComments(selectedOption.value, nbrOfPage).then(() => {
			this.setState({
				pagination: nbrOfPage || pagination
			})
		})
	}

	private updateChartData(): void {
		const {match, setComments}: any = this.props;
		CommentService.getByCategory(match.params.id, this.findCurrentEntity()).then((resp: any) => {
			setComments(resp.data.list);
		})
	}

	private showModal(): void {
		const {showAlert}: any = this.state
		this.setState({
			showAlert: !showAlert
		})
	}

	private handleInterprateComment(status: any): void {
		const {selectedComment}: any = this.state
		selectedComment.setFavoriteStatus(status)
		this.setState({
			selectedComment: selectedComment,
		});
	}

	// public componentWillUpdate(_:  any): void {
	//     const {plantId} :any = this.state
	//     const {match} :any  = this.props
	//     let currentPlantId = match.params.id
	//
	//     if(plantId && plantId === currentPlantId && !match.url.endsWith("dashboard")){
	//         return
	//     }
	//     const { selectedOption, pagination }: any = this.state;
	//     this.setState({
	//         plantId : currentPlantId
	//     })
	//     this.getComments(selectedOption.value, 20).then(() => {
	//         this.setState({
	//             pagination: 20 || pagination
	//         })
	//     })
	// }

	render() {
		const {toggleModal, state}: any = this;
		const {firstDay}: any = this.props;

		if (state.isLoading || state.code === 802) {
			return <Loader isLoading={state.isLoading} code={state.code} />
		}

		return (
			<div>
				<div onClick={toggleModal}
					 className="header-icons">
					<img src="/assets/images/btn-comment.png"/>
				</div>

				<Modal state={state.isOpen}
					   onClose={toggleModal}
					   backgroundStyle={backdropStyle}
					   modalStyle={modalStyle}>
					<div className="row">
						<div className="col-sm-4 list-comment-container">
							{(state.selectedComment || state.nbOfComments === 0) &&
                                <ListComment {...this.state}
                                             onHandleChange={this.handleChange}
                                             onHandleClick={this.handleClick}
                                             onAddHandleChange={this.handleAddChange}
                                             updateComment={this.updateComment} />
							}
						</div>
						<div className="col-sm-8 specific-comment-container">
							{ state.addComment ?
                                <Form {...this.state} {...{firstDay: firstDay}}
                                      handleAddChange={this.handleAddChange}
                                      preloadedData={state.preloadedData}
                                      updateData={this.updateComment}
                                      updateChartData={this.updateChartData}
                                      showModal={this.showModal} />
                                :
                                <SpecificComment {...this.state}
                                                 handleInterprateComment={this.handleInterprateComment} />
							}
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

const CommentsRouted = withRouter(Comments as React.ComponentType<any>);
export {CommentsRouted as Comments};
