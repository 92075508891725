export class CustomerAndPlant {
	code!: number;
	customerName!: string;
	locationCountry!: string;
	locationId!: number;
	locationName!: string;
	locationRegion!: string;
	plantCode!: string;
	plantName!: string;
	status!: string;
	tenantId!: number;
	tenantName!: string;
	userId!: number;
}
