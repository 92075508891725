import * as React from 'react';
import './Logo.css';

export class Logo extends React.Component {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<div className="basfLogo" />
		);
	}
}


// <mark className="rectangle"></mark>
