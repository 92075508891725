import * as React from 'react';
import './AccountManager.css';
import { Header } from './Header';
import { UsersList } from './UsersList';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { UserService } from '../../../_services';
import { User } from '../../../_models';

interface IModalProps {
  toggleBubble: (close?: boolean) => void;
};

class AccountManager extends React.Component<IModalProps> {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props:any) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user') || '');
    this.state = {
      user: new User(user),
      roles: user.roles,
      isModalOpen: false,
      isBubbleOpen: false,
      isPasswordModalOpen: false,
      isProfileModalOpen: false
    };
    // this.toggleModal = this.toggleModal.bind(this);
    this.logOut = this.logOut.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.toggleProfileModal = this.toggleProfileModal.bind(this);
    this.getUsers('B');
  }

  protected logOut(): void {
    const { history }: any = this.props;
    history.push('/logout');
  }

  protected toggleModal () :void {
    const { state } :any = this;
    let props: any= this.props;
    props.onCloseBubble();

    this.setState({
      isModalOpen: !state.isModalOpen
    });
  }

  protected toggleProfileModal() :void {
    const { state } :any = this;
    let props: any= this.props;
    props.onCloseBubble();
    this.setState({
      isProfileModalOpen: !state.isProfileModalOpen
    });
  }

  private getUsers(type: string) :any {
    const {user} : any  = this.state
      if(user && user.roles && user.roles.length > 0 && user.roles[0].code=="BASF_ADMIN"){
        UserService.getUsers(type).then((users: any) => {
          this.setState({
            users: users.data.list,
            type: type
          });
        })
      }
  }

  //click outside of the bubble to close it
  componentWillMount(){
    document.addEventListener('mousedown', this.handleClick, false )
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClick, false )
  }

  private handleClick  = (e: any) => {
    //if bubble is closed, skip
    let { isBubbleOpen }: any = this.props;
    if(!isBubbleOpen) return

    // if click on gear icon or bubble list, skip
    let { gearRef }: any = this.props;
    let bubble = this.refs.bubble as HTMLDivElement;
    if(gearRef && gearRef.contains(e.target) ||bubble && bubble.contains(e.target) ) return;

    let props: any= this.props;
    props.onCloseBubble();
  }


  render() {
    const { state, getUsers } :any = this;

    return (
          <div>
            <Header/>
            <UsersList {...{users: state.users}}
              handleChangeUser={getUsers}
              {...this.state}/>
          </div>
    );
  }
}

const AccountManagerRouted = withRouter(AccountManager as React.ComponentType<any>);
export { AccountManagerRouted as AccountManager };
