import {BaseService} from './base.services'
import {Ressource} from '../_helpers';
import {Feature} from '../_models/Feature';

export class FeatureService extends BaseService {

	static async getFeatures(): Promise<Feature[]> {
		const ressource = new Ressource();
		ressource.shapeUrl('/feature', [], {});

		return this.getAxios
            .get(ressource.fullUrl, this.requestOptionsGet())
			.then((response: any) => response.data);
	}

    static async getFeature(featureName: string): Promise<Feature> {
        const ressource = new Ressource();
        ressource.shapeUrl('/feature/%s', [featureName], {});

        return this.getAxios
            .get(ressource.fullUrl, this.requestOptionsGet())
            .then((response: any) => response.data);
    }

}
