import { BaseService } from './base.services'
import { Ressource } from '../_helpers';

export class CompanyService extends BaseService {
    static async getCompanies() :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/tenant/list', [], {});
      return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
      .then((companies:any) => {
        return companies.data;
      })
    }
}
