// The modal "window"
export const modalStyle :any = {
  backgroundColor: '#fff',
  borderRadius: 0,
  width: '100%',
  height: '100%',
  margin: '0 auto',
  padding: 0,
};

// The gray background
export const backdropStyle :any = {
  position: 'fixed',
  top: 0,
  right: 0,
  width: '60vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.7)',
  boxShadow: '0 0 2px 2px rgb(218,218,218,0.8)'
};
