import { BaseService } from './base.services'
import { Recommendation } from '../_models';
import { Ressource } from '../_helpers';

export class RecommendationService extends BaseService {
    static async getRecommendations(plantCode: string) :Promise<Recommendation[]>{
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/recommendation/list', [plantCode]);
      return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
      .then((recommendations: any) => {
          return recommendations.data;
      });
    }

    static async createRecommendation(plantCode: string, body: any) :Promise<Recommendation[]>{
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/recommendation', [plantCode]);
      //body = body.replace(/</g,"&lt;").replace(/>/g,"&gt;");
      return this.getAxios.post(ressource.fullUrl, body , this.requestOptionsPost({ body }))
      .then((recommendations: any) => {
          return recommendations.data;
      });
    }
}
