import * as React from 'react';
import './PlantDescription.css';
import { DateTool } from '../../_helpers';
import { find } from 'lodash';

export class PlantDescription extends React.Component {
  constructor(props:any) {
    super(props);
  }

  render() {
    const { dashboard, content } :any = this.props;
    const { startup }: any = content;
    const currentIgnition: any =  find(dashboard.catalysts, { status: 'A'});
    const ignitionDateStart =  currentIgnition && currentIgnition.ignitionDateStart;
    return (
      <div>
        <h2> { dashboard && dashboard.catalystName }</h2>
        <p className='global-grey'> { DateTool.timeBetween(ignitionDateStart) } Days
          <span className="startup">( { startup }: { ignitionDateStart } )</span>
        </p>
      </div>
    );
  }
}
