import * as React from 'react';
import './CreateAccount.css';
import DatePicker from 'react-datepicker';
import { UserService, PlantService } from '../../../_services';
import Select from 'react-select';
import { map, first, isEmpty, includes, omit, extend, intersection, endsWith, find } from 'lodash';
import * as moment from 'moment';
import { Toaster } from '../../Common/Toaster/Toaster';
import { Button } from 'react-bootstrap';
// @ts-ignore
import { Multiselect } from 'multiselect-react-dropdown';

interface IModalProps {
  handleOpenCreatePopupState: () => void;
  handleChangeUser: (typeCust: string) => Promise<any>;
  isUpdate: boolean;
  handleUserCreation?: (user: any) => void;
  userToModify?: any;
};

const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export class CreateAccount extends React.Component<IModalProps> {
  private multiselectRef: any;
  constructor(props:any) {
    super(props);
    this.state = {
      isModalOpen: false,
      expireDate: undefined,
      type: props.type,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      errors: [],
      title: 'Create',
      submitted: false
    };
    this.closeAccoutCreationPopup = this.closeAccoutCreationPopup.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeRole = this.handleChangeRole.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectUserType = this.selectUserType.bind(this);
    this.isSelectedUserType = this.isSelectedUserType.bind(this);
    this.updateData = this.updateData.bind(this);
    this.keepEditing = this.keepEditing.bind(this);
    this.validationCloseAccoutCreationPopup = this.validationCloseAccoutCreationPopup.bind(this);
    this.multiselectRef = React.createRef();
  }

  public componentDidMount(): void {
    const { type }: any = this.props;
    this.getRoles(type);
    this.getAllCustomersAndPlants();
  }

  private handleChangeDate(date: any): void {
    this.setState({
      expireDate: date
    })
  }

  private selectUserType(userType: string): void{
    this.setState({
      type: userType,
      currentCompany: ''
    })
    this.getRoles(userType);
  }

  private handleChangeRole(role: any): void {
    this.setState({
      role: role
    })
    // this.resetCustomerAndPlants();
  }

  private handleChangeCompany(company: any): void {
    this.setState({
      currentCompany: company
    })
    this.multiselectRef && this.multiselectRef.current && this.multiselectRef.current.resetSelectedValues();
  }

  private handleChange(event:any) :void {
    const target :any = event.target;
    this.setState({
      [target.name]: event.target.value
    });
  }

  private checkForm(): string[] {
    let errors :string[] = new Array();
    const { isUpdate }: any = this.props;
    const { firstName, lastName, email, type, role, currentCompany }: any = this.state;
    if(!isUpdate) {
      if (isEmpty(firstName)) {
        errors.push('firstNameEmpty');
      }
      if (isEmpty(lastName)) {
        errors.push('lastNameEmpty');
      }
    }
    if (isEmpty(email) || !re.test(email) || (type === 'B' && !endsWith(email, '@basf.com'))) {
      errors.push('email');
    }
    if (type === 'B' && !endsWith(email, '@basf.com')) {
      errors.push('basfemail')
    }
    if (isEmpty(type)) {
      errors.push('type');
    }
    if (isEmpty(role)) {
      errors.push('role');
    }
    if (isEmpty(currentCompany) && type === 'C') {
      errors.push('currentCompany');
    }
    this.setState({
      errors: errors
    })
    return errors;
  }

  private submitForm(): any {
    const { isUpdate }: any = this.props;
    const { email, firstName, lastName, type, role, expireDate, id, currentCompany, initialExpireDate }: any = this.state;
    const assignedPlants = this.getAssignedPlants();
    const defaultPlant = assignedPlants.length ? assignedPlants[0] : '';
    this.setState({
      defaultPlant: defaultPlant
    })
    const errors = this.checkForm();
    if (!isEmpty(errors)) {
      return true;
    } else if(!defaultPlant) {
      errors.push('plant');
      return true;
    }

    this.setState({
      submitted: true
    })

    let userInfo = this.formatData({email, firstName, lastName, type, role, expireDate, currentCompany});
    if(isUpdate) {
      userInfo = extend(userInfo, {id: id});
    }
    let data = {user: userInfo, plants: assignedPlants, defaultPlant: defaultPlant};
    const setExpireDate = isUpdate ? !!expireDate || expireDate !== initialExpireDate : !!expireDate;
    UserService[isUpdate ? 'updateUser' : 'createUser'](setExpireDate, data).then((resp:any) => {
      if(resp.data.code === 800){
        this.setState({
          submitted: false,
          errors: ['emailExists']
        })
      } else if (resp.data.code === 200) {
        this.updateData(userInfo);
        this.setState({
          submitted: false
        })
      } else {
        this.setState({
          submitted: false,
          toaster: { type: 'ERROR' , content: resp.data.message }
        })
      }
    });
  }

  private formatData(data: any): any{
    const { currentCompany } :any = this.state;
    let newData = omit(data, ['role','currentCompany', 'expireDate']);
    newData.roles = [{'id': data.role.value}];
    if (data.expireDate) {
      newData.expireDate = data.expireDate.format('YYYY-MM-DD')
    }
    if (data.type === 'C') {
      newData.tenantId = (data.currentCompany || currentCompany).value
    }
    return newData;
  }

  private getAllCustomersAndPlants(): void {
    const { isUpdate }: any = this.props;
    PlantService.getAllCustomersAndPlants().then((resp: any) => {
      this.setState({
        companies: this.getCompanies(resp.data),
        plants: resp.data
      });
      if(isUpdate){
        this.initializeUpdateUser(resp.data);
      }
    })
  }  

  private initializeUpdateUser(plants: any): void {
    const { isUpdate, userToModify }: any = this.props;
    if (isUpdate) {
      this.setState({
        title: 'Update',
        id: userToModify.id,
        firstName: userToModify.firstName,
        lastName: userToModify.lastName,
        email: userToModify.email,
        expireDate: userToModify.expireDate && moment(userToModify.expireDate),
        initialExpireDate: userToModify.expireDate && moment(userToModify.expireDate),
        type: userToModify.type,
        status: userToModify.status
      })
      this.getSelectedValues(userToModify, plants);
    }
  }

  private getSelectedValues(userToModify: any, plants: any): void{
    if(userToModify.type === 'C'){
      let plant: any = find(plants, { plantCode: userToModify.plants[0] && userToModify.plants[0].plantCode})
      this.setState({
        selectedValues: userToModify.plants,
        currentCompany: plant && { label: plant.customerName, value: plant.customerId }
      })
    } else {
      this.setState({
        selectedValues: userToModify.plants
      })
    }
  }

  private mapSelectValue(objectToMap: any, key: string, value: string): object[]{
    return map(objectToMap, (item: any) => {
      return { label: item[key], value: item[value] };
    })
  }

  private getCompanies(plants: any): object[]{    
    let distinctCustomerInfo = this.getDistinctValues(plants);
    let listOfCustomers = map(distinctCustomerInfo, (item: any) => {
      return { label: item['customerName'], value: item['customerId'] };
    })
    return listOfCustomers;
  }

  private getDistinctValues(plants: any): object[]{
    let map = new Map(); 
    return plants.filter((el: any) => {
      const val = map.get(el.customerName); 
      if(val) {
          if(el.customerId < val) { 
            map.delete(el.customerName); 
            map.set(el.customerName, el.customerId); 
            return true; 
          } else {
            return false; 
          } 
      }
      map.set(el.customerName, el.customerId); 
      return true;
    });
  }

  private getPlantsByCompanyName(company: any): object[] {
    const { plants } :any = this.state;
    let palntsForCompany: object[] = new Array();
    map(plants, (item: any) => {
      if(company && company.label === item['customerName']){
        palntsForCompany.push(item);
      }
    })
    return palntsForCompany;
  }

  private getAssignedPlants(): any {
    const { role, plants } :any = this.state;
    let assignedPlants: any;
    if(this.isSelectedUserType('B') && role && role.label=== 'Admin'){
      assignedPlants = map(plants, (item: any) => {
        return item['plantCode'];
      })
    } else {
      let selectedItems = this.multiselectRef && this.multiselectRef.current && this.multiselectRef.current.getSelectedItems();
      assignedPlants = map(selectedItems, (item: any) => {
        return item['plantCode'];
      })
    }
    return assignedPlants;
  }

  // private resetCustomerAndPlants(): void {
  //   this.multiselectRef && this.multiselectRef.current && this.multiselectRef.current.resetSelectedValues();
  //   this.setState({
  //     currentCompany: ''
  //   })
  // }

  private validationCloseAccoutCreationPopup(): void{
    this.setState({
      stopEditing: true,
    })
  }

  private keepEditing(): void{
    this.setState({
      stopEditing: false
    })
  }

  private closeAccoutCreationPopup(): void{
    const { handleOpenCreatePopupState }: any = this.props;
    handleOpenCreatePopupState();
  }

  private updateData(data: any): void {
    const { type } :any = this.props;
    const { handleOpenCreatePopupState, handleChangeUser, handleUserCreation, isUpdate }: any = this.props;
    handleChangeUser(type);
    handleOpenCreatePopupState();
    if (!isUpdate) {
      handleUserCreation(data);
    }
  }

  private disableUser(): void {
    const { email, firstName, lastName, type, role, expireDate, id }: any = this.state;
    const { userToModify }: any = this.props;
    const assignedPlants = this.getAssignedPlants();
    const defaultPlant = assignedPlants.length ? assignedPlants[0] : '';
    this.setState({
      defaultPlant: defaultPlant
    })
    const status = userToModify.status === 'A' ? 'I' : 'A';
    let userInfo = this.formatData({email, firstName, lastName, type, role, expireDate, id, status});
    let data = {user: userInfo, plants: assignedPlants, defaultPlant: defaultPlant};
    UserService.updateUser(false, data).then(() => {
      this.updateData(data);
    });
  }

  private isSelectedUserType(newUserType: string): boolean {
    const { type }: any = this.state;
    return type === newUserType;
  }

  private async getRoles(userType: string): Promise<any> {
    const { userToModify }: any = this.props;
    UserService.getRoles(userType).then((resp: any) => {
      let roles: any = {roles: this.mapSelectValue(resp.data, 'name', 'id')};
      if (userToModify) {
        const currentRole: any = first(userToModify.roles);
        roles['role'] = currentRole && { 'label': currentRole.name, 'value': currentRole.id }
      } else {
        roles['role'] = first(this.mapSelectValue(resp.data, 'name', 'id'));
      }
      this.setState(roles);
    })
  }

  render() {
    const { closeAccoutCreationPopup, state, selectUserType,
      props, validationCloseAccoutCreationPopup, keepEditing }: any = this;
    const { submitted, plants, selectedValues, currentCompany } :any = this.state;
    return (
      <div>
        { state.toaster && <Toaster {...state.toaster}/> }
        <div className="title">
          { state.title } User Account:
        </div>
        <div className='account-creation-popup-form'>
          <div className={'account-creation-popup-form-fields with-border-bottom-grey inline ' + (state.status === 'I' ? 'inactivate' : '')}>
            { !props.isUpdate ?
              [<div className={'select-user-type ' + (this.isSelectedUserType('B') ? 'selected-user-type' : '')} onClick={() => selectUserType('B')}>
                <span className="basf-first">B</span> BASF
                {
                  this.isSelectedUserType('B') &&
                  <img className="checked-user-type" src="/assets/images/checked.png" />
                }
              </div>,
              <div className={'select-user-type '+ (this.isSelectedUserType('C') ? 'selected-user-type' : '')}  onClick={() => selectUserType('C')}>
                <span className="customer-first">C</span> Customer
                {
                  this.isSelectedUserType('C') &&
                  <img className="checked-user-type" src="/assets/images/checked.png" />
                }
              </div>]
              :
              <div className='select-user-type select-user-type-no-border'>
                { state.type === 'B' ?
                  <span><span className="basf-first">B</span> BASF</span>
                  :
                  <span><span className="customer-first">C</span> {currentCompany ? currentCompany.label : 'Customer'} </span>
                }
              </div>
            }
          </div>
          <div className={'grey-separation ' + (state.status === 'I' ? 'inactivate' : '')}></div>
          { !props.isUpdate ?
            [ 
            <div className='account-creation-popup-form-fields inline'>
              <div className="inlineBlock verticalTop">
                <p>First Name:</p>
                <input type="firstName" name="firstName" value={state.firstName}
                placeholder="First Name..." className='account-creation-popup-input-field'
                onChange={this.handleChange} readOnly={props.isUpdate}/>
                {
                  includes(state.errors, 'firstNameEmpty') &&
                  <p className="alert alert-danger"> First name should not be empty </p>
                }
              </div>
              <div className="relative normal-input-field inlineBlock verticalTop">
                <p>Last Name: </p>
                <input type="lastName" name="lastName" value={state.lastName}
                placeholder="Last Name..." className={'account-creation-popup-input-field'}
                onChange={this.handleChange} readOnly={props.isUpdate}/>
                {
                  includes(state.errors, 'lastNameEmpty') &&
                  <p className="alert alert-danger"> Last name should not be empty </p>
                }
              </div>
            </div>
            ]
            : []
          }
          <div className={'account-creation-popup-form-fields ' + (state.status === 'I' ? 'inactivate' : '')}>
            <p className="account-creation-popup-form-label">Account email {props.isUpdate? '(Unchangeable)': ''}:</p>
            <input type="email" name="email" value={state.email}
              placeholder="Please enter..." className={'account-creation-popup-input-big ' + (props.isUpdate ? 'readonly' : '') + (intersection(state.errors, ['email', 'emailExists']) ? 'has-error' : '')}
              onChange={this.handleChange} readOnly={props.isUpdate}/>
            {
              includes(state.errors, 'email') &&
              <p className="alert alert-danger"> { includes(state.errors, 'basfemail') ? 'BASF account can only end with @basf.com' : 'Email is required and should have a good format' } </p>
            }
            {
              includes(state.errors, 'emailExists') &&
              <p className="alert alert-danger"> This is email is already used in our system </p>
            }
          </div>
          <div className={'account-creation-popup-form-fields inline ' + (state.status === 'I' ? 'inactivate' : '')}>
            <div className="inlineBlock verticalTop">
              <p>Role:</p>
              <Select
                className="account-creation-selection"
                value={state.role}
                onChange={this.handleChangeRole}
                key={state.roles}
                options={state.roles}
              />
              {
                includes(state.errors, 'role') &&
                <p className="alert alert-danger"> Role is required </p>
              }
            </div>
            <div className="relative normal-input-field inlineBlock verticalTop">
              <p>Expire Date: </p>
              <DatePicker
                  selectsStart
                  selected={state.expireDate}
                  onChange={this.handleChangeDate}
                  key="1"
                  className="date-picker-account-creation"
              />
              <div className={'select__arrow ' + (props.isUpdate ? "bit-up" : "")}></div>
              {
                includes(state.errors, 'expireDate') &&
                <p className="alert alert-danger"> Expiration Date is required </p>
              }
            </div>
          </div>
          <div className={'account-creation-popup-form-fields ' + (state.status === 'I' ? 'inactivate' : '')}>
            { state.role && !(state.role.label=== 'Admin') && this.isSelectedUserType('B') && 
                <div className="inlineBlock verticalTop">
                <p>Plants:</p>
                <div>
                  <Multiselect
                    ref={this.multiselectRef}
                    placeholder="Select Plant"
                    options={plants}
                    displayValue="plantName"
                    groupBy="customerName"
                    showCheckbox={true}
                    avoidHighlightFirstOption={true}
                    closeIcon="close"
                    selectedValues={selectedValues}
                    closeOnSelect={false}
                  />
                </div>
                {
                  includes(state.errors, 'plant') &&
                  <p className="alert alert-danger"> Plant is required </p>
                }
                </div>
              }
              { state.role && state.role.label=== 'Admin' && this.isSelectedUserType('B') &&
                <p className="alert alert-danger"> If selected 'Role' is 'Admin', then all plants which has been assigned to current user will be assigned to new user</p>
              }
              { this.isSelectedUserType('C') &&
              <div>
                <div className='inlineBlock verticalTop'>
                <p>Customer:</p>
                {props.isUpdate && 
                  <input type="email" name="company" value={state.currentCompany && state.currentCompany.label}
                  className={'account-creation-popup-input-field'} readOnly={props.isUpdate}/>
                }
                {!props.isUpdate &&
                <Select
                  className="account-creation-selection"
                  placeholder="Select Customer"
                  value={state.currentCompany}
                  onChange={this.handleChangeCompany}
                  key={state.companies}
                  options={state.companies}
                />
                }
                </div>
                <div className="relative inlineBlock verticalTop">
                <p>Plants:</p>
                <Multiselect
                  ref={this.multiselectRef}
                  placeholder="Select Plant"
                  options={this.getPlantsByCompanyName(state.currentCompany)}
                  displayValue="plantName"
                  showCheckbox={true}
                  avoidHighlightFirstOption={true}
                  closeIcon="close"
                  selectedValues={selectedValues}
                />
                {
                  includes(state.errors, 'plant') &&
                  <p className="alert alert-danger"> Plant is required </p>
                }
              </div>
            </div>
            }
          </div>
          <div className="grey-separation"></div>
          <div className="inline">
            <Button bsStyle="primary" block className={'purple-button ' + (state.status === 'I' ? 'inactivate' : '')} disabled={submitted} onClick={() => this.submitForm()}> Submit </Button>
            { props.isUpdate &&
              <Button bsStyle="primary" block className={'purple-button inlineBlock'} onClick={() => this.disableUser()}> { props.userToModify && props.userToModify.status === 'A' ? 'Disable account' : 'Enable account'} </Button>
            }
            <Button bsStyle="primary" block className={'purple-button inlineBlock'} onClick={() => validationCloseAccoutCreationPopup()}> Cancel </Button>
          </div>
        </div>
        { state.stopEditing &&
          <div className="alert-user-edit-change">
            <p> You will lose all the changes you have made, are you still sure to exit ? </p>
            <input type="submit" value="Yes, Exit" className="discart-generic-button" onClick={() => closeAccoutCreationPopup()}/>
            <input type="submit" value="Cancel" className="ok-generic-button" onClick={() => keepEditing()}/>
          </div>
        }
      </div>
    );
  }
}
