import * as React from 'react';
import './Loader.css';

export class Loader extends React.Component{

  render() {
    return (
      <div className="loader-background">
         <div className="lds-ripple">
           <div></div>
           <div></div>
         </div>
     </div>
    );
  }
}
