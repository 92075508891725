import * as React from 'react';
import { withRouter } from 'react-router';
import './NoDataAvailable.css';

class NoDataAvailable extends React.Component {
  constructor(props:any) {
    super(props);
  }

  render (){
    return(
      <div className="not-found">
        <h2>No data available for selected timeframe / ignition phase.</h2>
      </div>

    )
  };
}

const NoDataAvailableRouted = withRouter(NoDataAvailable as React.ComponentType<any>);
export { NoDataAvailableRouted as NoDataAvailable };
