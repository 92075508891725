export class DrawGauge{
  public static assignLinearGradient(context: any, centerX: number, centerY: number, radius: number) :any{
    return context.createLinearGradient(
      centerX - radius,
      centerY / 2,
      centerX + radius,
      centerY / 2
    );
  }

  public static addStop(grad:any, colorArr: Array<string>, colorPoint: Array<number>){
   for(let i=0; i<colorArr.length; i++){
     grad.addColorStop(colorPoint[i],colorArr[i]);
   }
  }

  public static generateWhiteGap(context:any, centerX:number, centerY:number, radius:number,angle: number, increase: boolean): void{
    context.beginPath();
    if(increase){
      context.arc(centerX, centerY, radius, angle, angle+0.05, false);
    }else{
      context.arc(centerX, centerY, radius, angle-0.05, angle, false);
    }
    context.strokeStyle = "#fff";
    context.lineWidth = 9;
    context.stroke();
    context.closePath();
  }

  // make text position dynamic
  public static getXY(angle:any, percentage:any, baseVal: Array<number>,longText?: boolean) {
    let centerX = baseVal[0];
    let centerY = baseVal[1];
    let radius = baseVal[2];
    let xVal = centerX + radius * Math.cos(angle);
    let xMargin = 15;
    let wordLength = longText ? 40 : 22;
    if(percentage < 0.5){
      xVal = xVal - xMargin - wordLength
    }else if(percentage > 0.5){
      xVal = xVal + xMargin
    }else{
      xVal = xVal - wordLength/2
    }
    let yMargin = percentage == 0.5 ? 12 : 5 ;
    let yVal = centerY + radius * Math.sin(angle) - yMargin;
    return [xVal,yVal];
  }
}
