import * as React from 'react';
import './Form.css';
import { ChatService, CommentService } from '../../_services';
import { first, isEmpty } from 'lodash';
import { Toaster } from '../Common/Toaster/Toaster';

interface Iprops {
  handleNewMessage: () => void
}

export class ConsultForm extends React.Component<Iprops> {
  constructor(props:any) {
    super(props);
    this.state = {
      body: undefined
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private handleChange(event:any) :void {
    const target :any = event.target;
    this.setState({
      [target.name]: event.target.value
    });
  }

  protected uploadFile(file:any) :void {
    const { match, chatId, handleNewMessage } :any = this.props;
    CommentService.uploadImageComment(first(file)).then((resp: any) => {
      ChatService.createMessage(match.params.id, chatId, resp.data.data.location, true).then((resp2: any) => {
        if (resp2.code === 200) {
          handleNewMessage();
        } else {
          this.setState({
            toaster: { type: 'ERROR' , content: resp2.message }
          })
        }
      })
    })
  }

  private handleSubmit(event:any) :any {
    const { match, handleNewMessage } :any = this.props;
    const { body } :any = this.state;

    event.preventDefault();
    if(isEmpty(body)) {
      return;
    }

    const bodyContent = body;
    this.clearMessage();

    ChatService.getChatId(match.params.id).then((chatId:any) => {
      ChatService.createMessage(match.params.id, chatId, bodyContent, false).then((resp: any) => {
        if (resp.code === 200) {
          this.clearMessage();
          handleNewMessage();
        } else {
          this.setState({
            toaster: { type: 'ERROR' , content: resp.message }
          })
        }
      });
    })
  }

  private clearMessage(): void {
    this.setState({
      body: ''
    })
  }

  render() {
    const { body, toaster } :any = this.state;
    return (
      <form onSubmit={this.handleSubmit} className="chat-comment">
        { toaster && <Toaster {...toaster}/> }
        <input name="body" value={body} onChange={(e) => this.handleChange(e)} className="chat-comment-form" placeholder="Enter Your Message" autoComplete="off" />
        <div className="upload-btn-wrapper">
          <input type="file" id="file" name="file" accept="image/png, image/jpeg" onChange={ (e) => this.uploadFile(e.target.files) }/>
          <img src="/assets/images/btn-add-pic.png" alt="add picture" className="upload-image"/>
        </div>
      </form>
    );
  }
}
