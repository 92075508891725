import {api} from '../_constants';
import {BaseService} from './base.services'
import {User, Role} from '../_models';
import {Ressource} from '../_helpers';

export class UserService extends BaseService {
	static async login(username: string, password: string): Promise<any> {
		const ressource = new Ressource();
		ressource.shapeUrl('/authenticate', []);
		return this.getAxios.post(ressource.fullUrl, {username, password}, this.requestOptionsNotAuth({username, password}))
			.then((user: any) => {
				// login successful if there's a jwt token in the response
				if (user.data.code === 200) {
					const fullUser = new User(user.data.data);
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('user', JSON.stringify(fullUser));
					// We set the token for the future request
					localStorage.setItem('token', user.data.data.token)
				}
				return user.data;
			});
	}

	static async authenticateToken(token: string): Promise<any> {
		const ressource = new Ressource();
		ressource.shapeUrl('/authenticate/token', []);
		return this.getAxios.post(ressource.fullUrl, token)
			.then((response: any) => {
				// login successful if there's a jwt token in the response
				if (response.data.code === 200) {
					const fullUser = new User(response.data.data);
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('user', JSON.stringify(fullUser));
					// We set the token for the future request
					localStorage.setItem('token', response.data.data.token)
				}
				return response.data;
			});
	}

	static logout(): void {
		// remove user from local storage to log user out
		localStorage.removeItem('user');
	}

	static async getUser(userId: number): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/%s', [userId]);
		return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
			.then((user: any) => {
				return user.data;
			})
	}

	static async getUsers(type: string): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/list', [], {type});
		return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
			.then((user: any) => {
				return user.data;
			})
	}

	static async register(content: any): Promise<User> {
		return this.getAxios.get(api.url + '/users', this.requestOptionsPost(content))
			.then((user: any) => {
				return user.data;
			})
	}

	static async getRoles(type: string): Promise<Role> {
		let ressource = new Ressource();
		ressource.shapeUrl('/role/list', [], {type});
		return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
			.then((roles: any) => {
				return roles.data;
			})
	}

	static async createUser(setExpireDate: boolean, user: any): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/add_user_with_plants', [], {setExpireDate: setExpireDate.toString()});
		return this.getAxios.post(ressource.fullUrl, user, this.requestOptionsPost({user}))
			.then((resp: any) => {
				return resp;
			})
	}

	static async updateUser(setExpireDate: boolean, user: any): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/update_user_with_plants', [], {setExpireDate: setExpireDate.toString()});
		return this.getAxios.put(ressource.fullUrl, user, this.basicOptions)
			.then((resp: any) => {
				return resp;
			})
	}

	static async updateProfile(setExpireDate: boolean, user: any): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/profile/update', [], {setExpireDate: setExpireDate.toString()});
		return this.getAxios.put(ressource.fullUrl, user, this.basicOptions)
			.then((resp: any) => {
				return resp;
			})
	}

	static async resetPassword(email: string): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/reset-password/email', [], {email})
		return this.getAxios.post(ressource.fullUrl, '', this.requestOptionsPost({}))
			.then((resp: any) => {
				return resp;
			})
	}

	static async submitNewPassword(password: string, uuid: string): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/reset-password', [], {password, uuid});
		return this.getAxios(this.requestOptionsPut(ressource.fullUrl))
			.then(resp => {
				return resp;
			})
	}

	static async forgotPassword(email: string): Promise<any> {
		let ressource = new Ressource();
		ressource.shapeUrl('/user/forgot-password/email', [], {email: encodeURIComponent(email)})
		return this.getAxios.post(ressource.fullUrl, '', this.requestOptionsPost({}))
			.then((resp: any) => {
				return resp;
			})
	}

}
