import * as moment from 'moment';

export class DateTool {

	private static ZERO_TIMEZONE ='T00:00:00.000Z';

	public static timeBetween(date1: any, date2?: Date, unit?: any): string {
		// The number of milliseconds in one day
		let ONE_DAY: number = 1000 * 60 * 60 * 24;
		let ONE_HOUR: number = 1000 * 60 * 60;
		// Convert both dates to milliseconds
		let date1_ms: number = new Date(date1).getTime();
		// let date1_ms :number = new Date(date1).getTime();
		let date2_ms: number = date2 ? new Date(date2).getTime() : new Date().getTime();
		// Calculate the difference in milliseconds
		let difference_ms: number = Math.abs(date1_ms - date2_ms);
		// Convert back to days and return
		let perUnit = unit == "hour" ? ONE_HOUR : ONE_DAY;
		return Math.round(difference_ms / perUnit).toString();
	}

	/**
	 * Calculate the counted day of a ignition-face for a given date over all existing ignition-faces.
	 *
	 * @param catalysts - list of all ignition-faces of a plant
	 * @param selectedDate
	 */
	public static getDayOnStream(catalysts: any[], selectedDate: string): number {
		// convert wrong date format from highchart to ISO format Y/M/D => YYYY-MM-DD
		// It's required, because we have to add a defined timezone to the date to prevent usage of teh local timezone
		if (selectedDate.indexOf('/') !== -1) {
			const selectedDateParts = selectedDate.split('/');
			selectedDate = `${this.pad(selectedDateParts[0], 4)}-${this.pad(selectedDateParts[1], 2)}-${this.pad(selectedDateParts[2], 2)}${this.ZERO_TIMEZONE}`;
		} else  if (selectedDate.indexOf(' ') !== -1) {
			// handle dates with time (in data log)
			selectedDate = `${selectedDate.replace(/\s/g, 'T')}.000Z`;
		} else {
			// handle correct date without time
			selectedDate = `${selectedDate}${this.ZERO_TIMEZONE}`;
		}
		// console.log('getDayOnStream()', selectedDate);

		// Get the timestamp of a date with a defined timezone (Z)
		const selectedTimestamp = new Date(selectedDate).getTime();

		const catalyst = catalysts.sort((c1, c2) => new Date(c1.ignitionDateStart + this.ZERO_TIMEZONE).getTime() >= new Date(c2.ignitionDateStart + this.ZERO_TIMEZONE).getTime() ? -1 : 1)
		                          .filter(catalyst => selectedTimestamp >= new Date(catalyst.ignitionDateStart + this.ZERO_TIMEZONE).getTime())[0];

		return this.getDateInterval(new Date(selectedDate), new Date(catalyst.ignitionDateStart + this.ZERO_TIMEZONE));
	}

	/* ============================================================================= */

	/**
	 * Calculate the count of days between two dates (new Date(...))
	 * @param date1
	 * @param date2
	 * @private
	 */
	private static getDateInterval(date1: Date, date2: Date): number {
		const dateOne: any = moment({ year: date1.getFullYear(), month: date1.getMonth(), day: date1.getDate() }); // date1.toString().substr(0, 10));
		const dateTwo: any = moment({ year: date2.getFullYear(), month: date2.getMonth(), day: date2.getDate() }); // date2.toString().substr(0, 10));

		return dateOne.diff(dateTwo, 'days') + 1;
	}

	/**
	 * Add leading zeros to a value (string || number) for number formatting with a defined length
	 * Example:
	 * 	pad('6', 3) => '006'
	 * 	pad(47, 4)  => '0047'
	 *
	 * @param value
	 * @param zeros - count of total digits
	 * @private
	 */
	private static pad(value: string | number, zeros: number): string {
		const zeroString = '0'.repeat(zeros);
		return (zeroString + value).slice(-1 * zeros);
	}

}
