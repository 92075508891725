import {userConstants} from '../_constants';
import {UserService} from '../_services';
import {User} from '../_models';
import {AlertActions} from './';
import {history} from '../_helpers';

export class UserActions {
	static async login(username: string, password: string): Promise<any> {
		try {
			const user = await UserService.login(username, password);
			if (user.code !== 200) {
				this.failure('LOGIN', user.message.toString());
				AlertActions.error(user.message.toString());
				return user.message.toString();
			}
			this.success('LOGIN', user);
			return {'response': 'success', 'token': user.data.token};
		} catch (error) {
			this.failure('LOGIN', error.toString());
			AlertActions.error(error.toString());
			return error.toString();
		}
	}

	static async authenticateToken(token: string): Promise<any> {
		try {
			const user = await UserService.authenticateToken(token);
			if (user.code == 200) {
				this.success('LOGIN', user);
				return {'response': 'success', 'token': user.data.token};
			} else if (user.code == 400 || user.code == 601) {
				history.push('/userNotExist');
			} else {
				this.failure('LOGIN', user.message.toString());
				AlertActions.error(user.message.toString());
				history.push('/errorPage');
			}
		} catch (error) {
			this.failure('LOGIN', error.toString());
			AlertActions.error(error.toString());
			return error.toString();
		}
	}

	static logout(): object {
		UserService.logout();
		return {type: userConstants.LOGOUT};
	}

	static async register(content: any): Promise<any> {
		try {
			const user = await UserService.register(content);
			this.success('LOGIN', user);
			history.push('/');
		} catch (error) {
			this.failure('LOGIN', error.toString());
			AlertActions.error(error.toString());
		}
	}

	static request(type: string, username?: string): object {
		return {type: userConstants[type + '_REQUEST'], username: username}
	}

	static success(type: string, user?: User): object {
		return {type: userConstants[type + '_SUCCESS'], user}
	}

	static failure(type: string, error: User, id?: string): object {
		return {type: userConstants[type + '_FAILURE'], id, error}
	}

}
