import * as React from 'react';
import './SimulatorAdvanceValue.css';

interface IModalProps {
  handleToggleModal: () => void;
};

export class SimulatorAdvanceValue extends React.Component<IModalProps> {
  constructor(props:any){
    super(props);
  }

  render() {
    const { handleToggleModal, content, initialData }: any = this.props;
    return (
      <div className="row">
        <div className="row admin-popup-title simulator-popup-header">
          <span> { content.values } </span>
          <img className="close-popup" src="/assets/images/btn-close-white.png" onClick={() => handleToggleModal()}/>
        </div>
        <div className="simulator-popup-content">
          <div className="simulator-popup-content-top-array row">
            <div className="col-6 border-bottom-black size-sixteen bottom-twelve"> <strong> { content.parameter } </strong></div>
            <div className="col-4 border-bottom-black size-sixteen bottom-twelve"> <strong> { content.value } </strong></div>
          </div>
          {
            initialData.params.map((data:any, idx:number) => {
              return (
                <div className={"simulator-popup-content-array row " + ( idx % 2 ? 'odd' : 'even')} key={idx}>
                  <div className="simulator-popup-content-array-cell">
                    <div className="col-6">
                      <p> { data.code } </p>
                    </div>
                    <div className="col-4">
                      <p> { data.value } { data.unit } </p>
                    </div>
                  </div>
                </div>
              )
            })
          }
          <div className="simulator-popup-bottom">
            <input type="submit" className="simulator-submit-button" onClick={() => handleToggleModal()} value={content.close} />
          </div>
        </div>
      </div>
    )
  }
  }
