import * as React from 'react';
import { connect } from 'react-redux';
import { Header } from '../../_components/Header/Header';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SimulatorHeader } from '../../_components/Simulator/SimulatorHeader';
import { SimulatorChartTitle } from '../../_components/Simulator/SimulatorChartTitle';
const SimulatorChart = require('../../_components/Simulator/SimulatorChart');
import * as content from '../../content/content.json';
import './SimulatorGraph.css';
import { find } from 'lodash';

class SimulatorGraphPage extends React.Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }

    constructor(props:any) {
      super(props);
      const { location }: any = props;
      const { plants, match }: any = props;
      const plant: any = find(plants, (plant: any) => {
        return plant.code === match.params.id;
      })
      this.state = {
        graphs: location.state && location.state.charts,
        plant: plant,
        content: content.pages.simulator.en
      }
    }

    public componentDidMount(): void {
      const { contentGraph }: any = this.refs;
      this.setState({
        chartWidth: contentGraph.offsetWidth
      })
    }

    render() {
      const { graphs, chartWidth, content }: any = this.state;
      const { match }: any = this.props;
      const productLineId = match.params.id;
      const link = '/plants/' + productLineId + '/simulator';

      let temperature: any = graphs && find(graphs.config, { code: 'temperature'});
      let selectivity: any = graphs && find(graphs.config, { code: 'selectivity'});

        return (
          <div className="predicition-page">
            <Header {...this.props} key="header" showOptions={true} hideConsult={true} hasCloseIcon={true} closeLink={link} isPrediction={true}></Header>
            <div className="content-padding" ref="contentGraph">
              <SimulatorHeader {...this.props} {...this.state} />
            </div>
            { graphs &&
              [
                <SimulatorChartTitle {...{ title: 'Temperature', color: graphs.temperature.range.color, content: content }}></SimulatorChartTitle>,
                <SimulatorChart.default {...{chart: graphs.temperature, unit: temperature.unit, chartWidth: chartWidth }} >
                </SimulatorChart.default>,
                <SimulatorChartTitle {...{ title: 'Selectivity', color: graphs.selectivity.range.color, content: content  }}></SimulatorChartTitle>,
                <SimulatorChart.default {...{chart: graphs.selectivity, unit: selectivity.unit, chartWidth: chartWidth }} >
                </SimulatorChart.default>
              ]
            }
          </div>
        );
    }
}

function mapStateToProps(state:any) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedSimulatorGraphPage = withRouter(connect(mapStateToProps)(SimulatorGraphPage) as React.ComponentType<any>);
export { connectedSimulatorGraphPage as SimulatorGraphPage };
