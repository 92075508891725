export const permissionsConstants :any = {
    DASHBOARD_VIEW: "DASHBOARD_VIEW",
    EDIT_OPERATION_LIMITS: "DASHBOARD_EDIT_OPERATION_LIMITS",
    ACCOUNT_MANAGEMENT_VIEW: "ACCOUNT_MANAGEMENT_VIEW_ANY_USER",
    ACCOUNT_MANAGEMENT_ADD: "ACCOUNT_MANAGEMENT_ADD_ANY_USER",
    ACCOUNT_MANAGEMENT_EDIT: "ACCOUNT_MANAGEMENT_EDIT_ANY_USER",
    CHART_VIEW: "CHART_VIEW",
    CHART_EXPORT: "CHART_EXPORT",
    RECOMMENDATION_VIEW: "RECOMMENDATION_VIEW",
    RECOMMENDATION_ADD: "RECOMMENDATION_ADD",
    COMMENT_VIEW: "COMMENT_VIEW",
    COMMENT_ADD: "COMMENT_ADD",
    CONSULT_VIEW: "CONSULT_VIEW",
    CONSULT_ADD: "CONSULT_ADD",
    ANALYTIC_DATA_UPLOAD_VIEW: "ANALYTIC_DATA_UPLOAD_VIEW",
    ANALYTIC_DATA_UPLOAD_ADD: "ANALYTIC_DATA_UPLOAD_ADD",
    SIMULATOR_VIEW: "SIMULATOR_VIEW",
    SIMULATOR_ADVANCED_VALUES: "SIMULATOR_ADVANCED_VALUES",
    FORECAST_VIEW: "FORECAST_VIEW",
    REACTOR_STARTUP_DATE_VIEW: "REACTOR_STARTUP_DATE_VIEW",
    REACTOR_STARTUP_DATE_EDIT: "REACTOR_STARTUP_DATE_EDIT",
    UNIT_SWITCH: "UNIT_SWITCH",
    USER: "USER",
    USER_PLANT: "USER_PLANT"
};
