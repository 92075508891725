import { BaseClass } from './BaseClass';

export class Operation {
  name!: string;
  value!: string;
  max!: boolean;
}

export class GaugeData extends BaseClass{
  value!: number;
  unit!: string;
  maxValue!: number;
  variation!: string;
  type!: string;
  range!: string;
  dataValue!: number;
  serialCode!: string;
}

class Gauge  extends GaugeData{
  latestPlantData!: GaugeData[];
}

export class DashBoard {
  id!: number;
  name!: string;
  startingDate!: Date;
  operations!: Operation[];
  data!: Gauge[];
}

export class OperationLimit extends BaseClass {
  id!: number;
  valueType!: string;
  value!: string;
  minValue!: string;
  maxValue!: string;
  unit!: string;
  name!: string;
  plantCode!: string;
  seqNum!: number;
}
