import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './user.reducer';
import { alert } from './alert.reducer';

const rootReducer :any = combineReducers({
  authentication,
  registration,
  users,
  alert
});

export default rootReducer;
