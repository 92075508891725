import * as React from 'react';
import './SideNav.css';
import { Navbar, Nav } from 'react-bootstrap';
import { MenuClass, MenuItems, BaseItem, User } from '../../_models/';
import { Ressource, PlantHelper } from '../../_helpers/';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { menu as MenuConstant,  permissionsConstants}  from '../../_constants/';
import { includes, first, last } from 'lodash';

class SideNav extends React.Component{
    static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

    constructor(props:any)  {
        super(props);
        const user = JSON.parse(localStorage.getItem('user') || '');
		this.state = {
			menu: [],
			productLineId: props.location.pathname.split('/')[2],
			currentItem: 'dashboard',
			user: new User(user)
		}
    }

    componentWillMount() {
		this.getMenu();
	}

    public componentDidMount(): void {
		const {location}: any = this.props;
		const urlElement = location.pathname.split('/');
		this.setState({
			currentItem: location.pathname.indexOf("data-log") == -1 ? urlElement.length > 6 && urlElement[6] : urlElement.length > 4 && urlElement[4],
			currentCategory: urlElement.length > 4 && urlElement[4]
		})
	}

    // TODO: Refactor me this is not clean. We handle the change the plant,
	// To put the menu build at the App level will probably worth a try
	public componentDidUpdate(prevProps: any): void {
		const {location, unReadComments, updateSpecificItemPlants}: any = this.props;
		const {productLineId}: any = this.state;

		if (location.pathname.split('/')[2] !== prevProps.location.pathname.split('/')[2]) {
			this.setState({
				productLineId: location.pathname.split('/')[2],
				currentItem: {}
			});
			this.getMenu();
		} else if (location.pathname !== prevProps.location.pathname) {
			this.getMenu();
		}

		// When we are selecting an unread comment
		if (unReadComments && (unReadComments.length !== prevProps.unReadComments.length) && !unReadComments.length) {
			const {menu}: any = this.state;
			const latestComment: any = (first(prevProps.unReadComments) || {} as any);

			if (last(prevProps.location.pathname.split('/')) === latestComment.subCategoryCode) {
				menu.updateUnreadComment(latestComment.categoryCode, latestComment.subCategoryCode);
				updateSpecificItemPlants(productLineId, latestComment.categoryCode, latestComment.subCategoryCode);
				this.setState({
					menu: menu
				});
			}
		}
	}

	private getMenu(): void {
		const {location}: any = this.props;
		const {plants}: any = this.props;
		let productLineId = location.pathname.split('/')[2];

		let menu: MenuClass = new MenuClass(plants, productLineId);
		this.setState({
			menu: menu
		});
	}

    private shapeUrl(params: any[], url: string): string {
		let ressource = new Ressource();

		// We do a trick for recommendation page
		if (includes(params, 'csc_recommendations')) {
			url = url.split('/type')[0] + '/recommendations/csc_recommendations';
			ressource.shapeInternalUrl(url, params.splice(0, 2));
		} else {
			ressource.shapeInternalUrl(url, params);
		}

		return ressource.internalUrl || '';
	}

    private shapeHeaderUrl(productLineId: number, lastURI: string): string {
		return '/plants/' + productLineId + '/' + lastURI;
	}

    private updateCurrentItem(item: any): void {
		const {getPlants}: any = this.props;
		getPlants();

		this.setState({
			currentItem: item.code
		})
	}

    render() {
        const { menu, productLineId, currentItem, user }: any = this.state;
        let { location }: any = this.props;

        // console.log('currentItem', currentItem);

        // TODO: Check me later, can do something cleaner
        // later maybe (its own component?)
		const menuItems = menu.categoryList.map((item: MenuItems) => {
			let path: string = '/assets/images/' + item.name + '.png';
			let {pathname} = location;

			return (
				<li key={item.code}>
					<h4>
						<span className="nav-icon-caption"><img src={path} className="nav-icon" alt="" /></span>
						{item.name}
					</h4>

					<Nav key="leftNav">
						{ item.subCategoryList.map((subItem:BaseItem, idx:number) => {
							return (
								<li key={item.code + subItem.code + idx}
									ref={this.shapeUrl([productLineId, item.code, subItem.code], MenuConstant.url)}
									className={'submenu ' + (currentItem === subItem.code && !includes(['dashboard', 'simulator'], pathname) ? 'active' : '')} >
									<Link to={this.shapeUrl([productLineId, item.code, subItem.code], MenuConstant.url)} onClick={() => this.updateCurrentItem({code: subItem.code})}>
										{subItem.name} {subItem.hasUnReadComment ? <span className="unread-message-menu">•</span> : ''}
									</Link>
								</li>
							);
						}) }
					</Nav>
				</li>
			);
        });


        return (
        	<Navbar fluid className="sidebar">
				<Navbar.Header>
					<Nav className="nav nav-pills border-bottom">
						{ user.lookUpPermission(permissionsConstants.DASHBOARD_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
							<li key="dashboard" className={(currentItem === 'dashboard' || includes(location.pathname, 'dashboard') ? 'active' : '')}>
                                <Link to={this.shapeHeaderUrl(productLineId, 'dashboard')} onClick={() => this.updateCurrentItem({code: 'dashboard'})}>
                                    <span className="nav-icon-caption"><img src="/assets/images/dashboard.png" alt="" /></span>
									Dashboard
                                </Link>
							</li>
						}

						{ user.lookUpPermission(permissionsConstants.SIMULATOR_VIEW, permissionsConstants.USER_PLANT, PlantHelper.getPlantCode(location)) &&
							<li key="simulator" className={(currentItem === 'simulator' || includes(location.pathname, 'simulator') ? 'active' : '')}>
                                <Link to={this.shapeHeaderUrl(productLineId, 'simulator')} onClick={() => this.updateCurrentItem({code: 'simulator'})}>
                                    <span className="nav-icon-caption"><img src="/assets/images/icon-simulator.png" alt="" /></span>
									Simulator
                                </Link>
							</li>
						}

						{ user.lookUpPermission(permissionsConstants.FORECAST_VIEW, permissionsConstants.USER_PLANT, PlantHelper.getPlantCode(location)) &&
							<li key="forecast" className={(currentItem === 'forecast' || includes(location.pathname, 'forecast') ? 'active' : '')}>
                                <Link to={this.shapeHeaderUrl(productLineId, 'forecast')} onClick={() => this.updateCurrentItem({code: 'forecast'})}>
                                    <span className="nav-icon-caption"><img src="/assets/images/icon-simulator.png" alt="" /></span>
									Forecast
                                </Link>
							</li>
						}
					</Nav>
					{ /* <Navbar.Toggle /> */ }
				</Navbar.Header>

				<Navbar fluid className="submenu">
					<Nav>
						{ user.lookUpPermission(permissionsConstants.CHART_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) && menuItems }

						{ user.lookUpPermission(permissionsConstants.ANALYTIC_DATA_UPLOAD_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
							<li key={'data-upload'}>
                                <h4>
                                    <span className="nav-icon-caption"><img src='/assets/images/icon-data-upload.png' className="nav-icon" alt="" /></span>
                                    Data Upload
                                </h4>

								<Nav key="leftNav">
									<li key="data-log"
										className={'submenu ' + (currentItem === 'data-log' ? 'active' : '')}>
										<Link to={'/plants/' + productLineId + '/data-upload/data-log'} onClick={() => this.updateCurrentItem({code: 'data-log'})}>
											Data log
										</Link>
									</li>
								</Nav>
                            </li>
						}

						{ user.lookUpPermission(permissionsConstants.ACCOUNT_MANAGEMENT_VIEW, permissionsConstants.USER, PlantHelper.getPlantCode(location)) &&
							<li key={'administration'}>
                                <h4>
                                    <span className="nav-icon-caption"><img src='/assets/images/icon-user-settings.png' className="nav-icon" alt="" /></span>
                                    Administration
                                </h4>

								<Nav key="leftNav">
									<li key="admin"
										className={'submenu ' + (currentItem === 'accounts' ? 'active' : '')}>
										<Link to={'/plants/' + productLineId + '/accountManager'} onClick={() => this.updateCurrentItem({code: 'accounts'})}>
											User Accounts
										</Link>
									</li>
								</Nav>
                            </li>
						}
					</Nav>
				</Navbar>
          	</Navbar>
        )
    };
}



const SideNavRouted = withRouter(SideNav as React.ComponentType<any>);
export { SideNavRouted as SideNav };
