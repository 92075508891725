import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {provideAxis} from 'react-jsx-highstock';
import './DayPicker.css';
import './DateRangePickers.css';

const ONE_DAY = 86400000;

class DateRangePickers extends Component {

    static propTypes = {
        getHighcharts: PropTypes.func.isRequired,
        axisId: PropTypes.string.isRequired,
        dayFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        locale: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        onChangeFromDate: PropTypes.func.isRequired,
        onChangeToDate: PropTypes.func.isRequired,
        className: PropTypes.string.isRequired,
        fromLabel: PropTypes.string,
        toLabel: PropTypes.string,
        datePickerProps: PropTypes.object
    };

    static defaultProps = {
        dayFormat: 'YYYY-MM-DD',
        locale: 'en',
        onChangeFromDate: (date) => {
            // console.log('DateRangePickers.onChangeFromDate()', date);
        },
        onChangeToDate: (date) => {
            // console.log('DateRangePickers.onChangeToDate()', date);
        },
        className: 'date-range-pickers',
        datePickerProps: {}
    };

    state = {
        min: null,
        max: null
    };

    constructor(props) {
        super(props);

        const langOpts = props.getHighcharts().getOptions().lang;
        const {months: monthsLong, weekdays, shortWeekdays, rangeSelectorFrom, rangeSelectorTo} = langOpts;

        const {
            locale,
            months = monthsLong,
            weekdaysLong = weekdays,
            weekdaysShort = shortWeekdays,
            fromLabel = rangeSelectorFrom,
            toLabel = rangeSelectorTo,
        } = props;

        this.localisation = {
            locale,
            months,
            weekdaysLong,
            weekdaysShort,
            fromLabel,
            toLabel
        };

        //For input field value
        moment.locale(locale, {
            ...langOpts
        });

        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
    }

    componentDidMount() {
        const {getHighcharts, getAxis} = this.props;
        const Highcharts = getHighcharts(); // Get Highcharts injected via withHighcharts
        const axis = getAxis();

        Highcharts.addEvent(axis.object, 'afterSetExtremes', this.handleAfterSetExtremes);

        const {min, max} = axis.getExtremes();
        this.setState({
            min,
            max
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log('UNSAFE_componentWillReceiveProps()', this.props.from, this.props.to);

        const {onChangeFromDate, onChangeToDate} = this.props;
        const axis = this.props.getAxis();

        let startDate = moment(nextProps.from).startOf('day').valueOf();
        let endDate = moment(nextProps.to).startOf('day').valueOf();
        axis.setExtremes(startDate, endDate);

        onChangeFromDate(nextProps.from);
        onChangeToDate(nextProps.to);
    }

    componentWillUnmount() {
        const {getHighcharts, getAxis} = this.props;
        const Highcharts = getHighcharts(); // Get Highcharts injected via withHighcharts
        const axis = getAxis();
        if (axis.object) {
            Highcharts.removeEvent(axis.object, 'afterSetExtremes', this.handleAfterSetExtremes);
        }
    }

    handleFromDateChange = callback => {
        return fromDate => {
            // console.log('handleFromDateChange()', fromDate);

            const axis = this.props.getAxis();
            let {max} = axis.getExtremes();
            let newSelectedTime = moment(fromDate);
            let selectedTime = newSelectedTime.startOf('day').valueOf();

            let newMax = (selectedTime >= max) ? selectedTime + ONE_DAY : max;
            axis.setExtremes(selectedTime, newMax);
            callback(selectedTime);
        };
    }

    handleToDateChange = callback => {
        return toDate => {
            // console.log('handleToDateChange()', toDate);

            const axis = this.props.getAxis();
            let {min} = axis.getExtremes();
            let newSelectedTime = moment(toDate);
            let selectedTime = newSelectedTime.startOf('day').valueOf();

            let newMin = (selectedTime <= min) ? selectedTime - ONE_DAY : min;
            axis.setExtremes(newMin, selectedTime);

            callback(selectedTime);
        };
    }

    handleAfterSetExtremes = e => {
        const {min, max} = e;
        // console.log('handleAfterSetExtremes()', min, max);
        this.setState({
            min,
            max
        });
    }

    render() {
        // console.log('DateRangePickers.render():1', this.props, this.state);
        // console.log('DateRangePickers.render():1', this.props.from, this.props.from);

        const axis = this.props.getAxis();
        if (!axis) return null;

        const {fromLabel, toLabel, ...localisationOpts} = this.localisation;
        const {
            dayFormat,
            onChangeFromDate,
            onChangeToDate,
            className,
            datePickerProps,
            from,
            to,
            firstDay
        } = this.props;

        const {min, max} = this.state;

        let startDate = from;
        if (min !== undefined) {
            startDate = moment(min).format(dayFormat);
        }
        const fromDate = startDate;

        const toDate = moment(max).format(dayFormat);
        // console.log('DateRangePickers.render():2', fromDate, toDate);
        onChangeFromDate(fromDate);
        onChangeToDate(toDate);

        // console.log("porps", this.props)
        const {content} = this.props;
        const {date} = content;

        return (
            <div className={className}>
                <img src="/assets/images/btn-date.png"
                     alt="date options"
                     className="icone-calendar"/>
                <strong className="small-left"> {date} :</strong>
                <span className={className + '__label ' + className + '__from-label small-left'}>{fromLabel} </span>
                <DayPickerInput
                    value={fromDate}
                    onDayChange={this.handleFromDateChange(onChangeFromDate)}
                    format={dayFormat}
                    dayPickerProps={{...datePickerProps, ...localisationOpts, disabledDays: {before: new Date(firstDay), after: new Date()}}}
                />
                <span className={className + '__label ' + className + '__to-label small-left'}>{toLabel} </span>
                <DayPickerInput
                    value={toDate}
                    onDayChange={this.handleToDateChange(onChangeToDate)}
                    format={dayFormat}
                    dayPickerProps={{...datePickerProps, ...localisationOpts, disabledDays: {before: new Date(firstDay), after: new Date()}}}
                />
            </div>
        );
    }
}

export default provideAxis(DateRangePickers);
