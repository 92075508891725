import {BaseService} from './base.services'
import {Ressource} from '../_helpers';

export class PlantDataService extends BaseService {

	static async getValidDates(plantCode: string, serialCode: string): Promise<Date[]> {
		const ressource = new Ressource();
		ressource.shapeUrl('/plantdata/%s/validdates', [plantCode], {serialCode});

		return this.getAxios
            .get(ressource.fullUrl, this.requestOptionsGet())
			.then((item: any) => {
				return item.data;
			})
	}

}
