import * as React from 'react';
import './UsersList.css';
import { first, includes } from 'lodash';
import { Glyphicon, Button } from 'react-bootstrap';
import { CreateAccount } from './CreateAccount';

interface IModalProps {
  handleChangeUser: (typeCust: string) => Promise<any>
}

export class UsersList extends React.Component<IModalProps> {
  constructor(props:any) {
    super(props);
    this.state = {
      openCreatedUser: false,
      successMessage: false,
      userToModify: undefined,
      selectedTab: 'B',
      openModelCreate: false,
      createdUser: undefined
    }
    this.updateUser = this.updateUser.bind(this);
    this.openUpdatePopupState = this.openUpdatePopupState.bind(this);
    this.pickTab = this.pickTab.bind(this);
    this.openCreatePopupState = this.openCreatePopupState.bind(this);
    this.createdUser = this.createdUser.bind(this);
  }

  private openUpdatePopupState(): void {
    const { openCreatedUser }: any = this.state;
    this.setState({
      openCreatedUser: !openCreatedUser,
    });
  }

  private openCreatePopupState(): void {
    const { openModelCreate }: any = this.state;
    this.setState({
      openModelCreate: !openModelCreate,
    });
  }

  private createdUser(user:any): void {
    this.setState({
      successMessage: true,
      createdUser: user
    });
  }

  private closeUserCreationValidation(): void {
    this.setState({
      successMessage: false,
      openCreatedUser: false
    });
}

  private pickTab(tab: string): void {
    const { handleChangeUser } = this.props;
    handleChangeUser(tab);
    this.setState({
      selectedTab: tab,
    });
  }

  private getRole(roles: any): string {
    return (first(roles) || {} as any).name;
  }

  private updateUser(userToModify: any): void{
    this.setState({
      userToModify: userToModify
    })
    this.openUpdatePopupState();
  }

  private isManager(roles: any): string {
    const role = this.getRole(roles);
    return includes(['Manager','Admin','admin'], role) ? 'admin-surrounding' : 'normal-surrounding';
  }

  render() {
    const { pickTab, state, openCreatePopupState, props }: any = this;
    const { users, handleChangeUser }: any = this.props;
    const { openCreatedUser, userToModify, openModelCreate, successMessage }: any = this.state;

    if (openModelCreate){
      return (
        <CreateAccount
            handleOpenCreatePopupState={openCreatePopupState}
            handleChangeUser={props.handleChangeUser}
            handleUserCreation={this.createdUser}
            isUpdate={false}
            {...this.props}
        />
      );
    } else if (openCreatedUser) {
        return (
            <CreateAccount
              handleOpenCreatePopupState={this.openUpdatePopupState}
              handleChangeUser={handleChangeUser}
              isUpdate={true}
              userToModify={userToModify}
              {...this.props}
            />
        );
    } else {
      return (
        <div className="row user-listing">
          <div className="row admin-popup-header-selection">
            <div className={'admin-popup-title-item ' + (state.selectedTab === 'B' ? 'selected-tab' : '')} onClick={() => pickTab('B')}>
              BASF
            </div>
            <div className={'admin-popup-title-item ' + (state.selectedTab === 'C' ? 'selected-tab' : '')} onClick={() => pickTab('C')}>
              Customer
            </div>
            <div className="create-account">
              <Button bsStyle="primary" block className="purple-button" onClick={() => openCreatePopupState()}>
                  <span className="plus-icon "><Glyphicon glyph="plus"/></span><span> Create User </span>
              </Button>
            </div>
          </div>
          <div>
            <p className="row user-listing-count"><b> { users && users.length }</b> Accounts:</p>
            <div className="table-user-listing">
                { users &&
                  users.map((user: any) =>{
                    return (
                      <div key={user.id} className={'user-listing-cell '+ (user.status === 'I' ? 'user-disable' : '')}>
                        <div className="user-listing-name">
                          <img src='/assets/images/icon-pencil.png' className="edit-icon" onClick={() => this.updateUser(user)}/>
                          <p> { user.email } {user.status === 'I' && <span>(Disabled)</span>} </p>
                        </div>
                        <div className='user-listing-role'> <p className={this.isManager(user.roles)}>{ this.getRole(user.roles) }</p> </div>
                      </div>
                    )
                  })
                }
            </div>
          </div>
          { successMessage &&
              <div className="user-created-validation-popup">
                <p>The invitation email has been sent to:<br/>
                  { state.createdUser.email }
                </p>
                <input type="submit" value="Ok" className="ok-generic-button" onClick={() => this.closeUserCreationValidation()}/>
              </div>
            }
        </div>
      );
    }
  }
}
