import { User } from './';

export class Plant {
	code!: string;
	createdBy!: User;
	createdDate!: Date;
	description!: string;
	lastModifiedBy!: User;
	lastModifiedDate!: Date;
	name!: string;
	startUpDate!: Date;
	ignitionDateStart!: Date;
	ignitionDateEnd!: Date;
	status!: string;
	version!: number;
	catalysts!: any;
}
