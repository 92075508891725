import * as React from 'react';
import { GaugeComponent } from '../Common/Gauge/Gauge';
import { DashBoard } from '../../_models/';
import './Gauges.css';

export class Gauges extends React.Component {
  constructor(props:any) {
    super(props);
  }

  public componentDidUpdate(prevProps: any): void{
    let props:any = this.props;
    if (prevProps.dashboard !== props.dashboard) {
      this.setState({
        dashboard: props.dashboard
      })
    }
  }

  public componentWillMount(): void{
    let {dashboard}: any = this.props;
    this.setState({
      dashboard: dashboard
    })
  }

  render() {
    const { content } :any = this.props;
    const { dashboard } :any = this.state;
    const { preview, previewDesc }: any = content;

    return (
      <div className="gauge-container">
        <h2> { preview } </h2>
        <p> { previewDesc } </p>
        <div className="gauges-charts">
          { dashboard.dataPreview.map((data:DashBoard, index: number) => {
              return(
                <GaugeComponent {...data} key={data.name} index={index}  {...{content: content, dashboard: dashboard}} />
              )
          }) }
        </div>
      </div>
    );
  }
}
