import { includes, find } from 'lodash';

export class User {
	id!: number;
	name!: string;
	email!: string;
	expireDate!: string;
	loginName!: string;
	mobile!: string;
	roles!: any;
	status!: string;
	token!: string;
	tenantId!: number;
	type!: string;
	calculationMethod!: string;
	userProfileId!: number;
	defaultUserProfileId!: number;
	userPermissions!: any;
	constructor(data:any) {
		this.id = data.id;
		this.name = data.name;
		this.email = data.email;
		this.expireDate = data.expireDate;
		this.loginName = data.loginName;
		this.mobile = data.mobile;
		this.roles = data.roles;
		this.status = data.status;
		this.token = data.token;
		this.tenantId = data.tenantId;
		this.type = data.type;
		this.type = data.type;
		this.userProfileId = data.userProfileId;
		this.calculationMethod = data.calculationMethod ? data.calculationMethod : (data.userProfile && data.userProfile.calculationMethod);
		this.defaultUserProfileId = data.defaultUserProfileId;
		this.userPermissions = this.setUserPermissions(data.userPermissions ? data.userPermissions : data.permissions);
	}

	private setUserPermissions(permissions: any): any {
		return permissions.map((permission:any) => {
			return {
			  name: permission.name,
			  type: permission.type,
			  plantCodes: permission.plantCodes
			}
		  });
	}

	public lookUpPermission(name: string, type: string,  plantCode: string): boolean {
		let permission:any = find(this.userPermissions, (userPermission: any) => {
			return userPermission.name === name && userPermission.type === type && includes(userPermission.plantCodes, plantCode);
		});

		return permission ? true : false;
	}

	public isBasf(): boolean {
		return this.type === 'B';
	}
	
	public setDefaultUserProfileId(checked: boolean): void {
		if(checked){
			this.defaultUserProfileId = 0;
		} else {
			this.defaultUserProfileId = this.userProfileId;
		}
	}
}
