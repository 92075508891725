export interface zoomOptionInterface {
	type: string;
	value: string;
	label: string;
	count: number;
	index: number;
	className: string;
	startDate?: string;
}

export const zoomOptions : zoomOptionInterface[] =
	[
		{ type: 'day', value: '7 D', label: '7 D', count: 6, index: 1, className:'select-option' },
		{ type: 'day', value: '30 D', label: '1 M', count: 30, index: 2, className:'select-option' },
		{ type: 'day', value: '90 D', label: '3 M', count: 90, index: 3, className:'select-option' },
		{ type: 'day', value: '180 D', label: '6 M', count: 180, index: 4, className:'select-option' },
		{ type: 'day', value: '360 D', label: '1 Y', count: 365, index: 5, className:'select-option' },
		{ type: 'day', value: (365 * 3) + ' D', label: '3 Y', count: 365 * 3, index: 6, className:'select-option' },
		//{ type: 'all', value: 'all', label: 'all', index: 6, className:'select-option' }
	];

export const markerSettings :any = {
	enabled: true,
	fillColor: '#ff4040',
	width: 6,
	height: 6,
	lineWidth:0,
	radius:4,
	symbol: 'circle',
	state: {
		hover: {
			enabled: true,
			fillColor: 'yellow'
		}
	}
};

export const crosshair :any = {
	dashStyle: 'dash',
	width:1,
	color:'#666666'
};

export const toolTipStyle :any = {
	color: 'white',
	padding: '25px',
	lineHeight: '30px',
	fontSize: '14px',
	fontFamily: 'CircularStd',
	minWidth: '80px'
};

export const selectRangeCustomStyles :any = {
	option: (base:any, state:any) => ({
		...base,
		color: state.isSelected ? 'rgba(51,51,51,1)' : 'rgba(51,51,51,0.4)',
		background: '#f9f9f9',
		fontSize:'12px',
		fontFamily: 'CircularStd',
		fontWeight: 'normal',
		cursor: 'pointer',
		textAlign: 'left',
		':hover': {
			fontWeight: 'bold',
		}
	}),

	control: (base:any, state:any) => ({
		...base,
		background: '#f9f9f9',
		// match with the menu
		borderRadius: state.isFocused ? '2px' : '2px',
		// Overwrittes the different states of border
		borderColor: state.isFocused ? '#d8d8d8' : '#d8d8d8',
		// Removes weird border around container
		// boxShadow: state.isFocused ? '#d8d8d8' : '#d8d8d8',
		boxShadow: state.isFocused ? null : null,
		minHeight: state.isFocused ? '24px' : '24px',
		height: state.isFocused ? '24px' : '24px',
		width: '90px',
		cursor: 'pointer',
	}),

	menu: (base :any) => ({
		...base,
		// override border radius to match the box
		borderRadius: "2px",
		// beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
		hyphens: "auto",
		// kill the gap
		// marginTop: 0,
		textAlign: "center",
		// prevent menu to scroll y
		wordWrap: "break-word",
		boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.12)',
		width: '90px',
		marginTop: '0',
	}),

	menuList: (base:any) => ({
		...base,
		// kill the white space on first and last option
		padding: 0,
		background: '#efefef',
		width: '90px',
	}),

	indicatorsContainer: (base:any) => ({
		...base,
		height: "24px"
	}),
	clearIndicator: (base:any) => ({
		...base,
		padding: "0px"
	}),
	dropdownIndicator: (base:any) => ({
		...base,
		padding: "0px"
	})
};
