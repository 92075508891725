import * as React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

// We import actions
import { history } from '../_helpers';
import { AlertActions } from '../_actions';

// We import pages
import { HomePage } from '../Pages/HomePage';
import { PublicLandingPage } from '../Pages/PublicLandingPage/PublicLandingPage';
import { LoginPage } from '../Pages/LoginPage/LoginPage';
import { SafeLoginPage } from '../Pages/LoginPage/SafeLoginPage';
import { ForgotPasswordPage } from '../Pages/LoginPage/ForgotPassword';
import { ResetPasswordPage } from '../Pages/LoginPage';
import { DashBoardPage } from '../Pages/Dashboard';
import { ChartPage } from '../Pages/Charts';
import { LogoutPage } from '../Pages/LogoutPage';
import { SimulatorFormPage, SimulatorGraphPage } from '../Pages/Simulator';
import { ForecastGraphPage } from '../Pages/Forecast';
import { RecommendationPage } from '../Pages/RecommendationPage';
import { DataLogPage } from '../Pages/DataUpload';
import { AccountManagerPage } from '../Pages/Administration';

import { Menu } from '../_components/Menu/Menu';
import { NotFound } from '../_components/Common/404/NotFound';
import { PageNotFound } from '../_components/Common/404/PageNotFound';
import { UserNotExist } from '../_components/Common/404/UserNotExist';
import { GenericErrorPage } from '../_components/Common/404/GenericErrorPage';
import { Error } from '../_components/Common/404/Error';
import { RedirectToDashboard } from '../_components/Common/Redirect';
import { Loader } from '../_components/Common/Loader/Loader';
import { find } from 'lodash';

// TODO: See how to globalize BreadCrumb
// import { BreadCrumb } from '../_components/Common/BreadCrumb/BreadCrumb';

// services
import { PlantService } from '../_services';

import './App.css';

class App extends React.Component {
    fsspinner: string;

    constructor(props:any) {
        super(props);
        this.state = {
            isLoading: !!localStorage.getItem('user'),
            unReadComments: [],
            simpleLogin: false
        }

        const { dispatch }: any = this.props;
        history.listen((location :any, action :any) => {
            console.log(location, action);

            // clear alert on location change
            dispatch(AlertActions.clear());
        });

        this.getPlants = this.getPlants.bind(this);
        this.updateUnReadComments = this.updateUnReadComments.bind(this);
        this.updateSpecificItemPlants = this.updateSpecificItemPlants.bind(this);

        this.fsspinner = /\/\?token=/.exec(window.location.href) ? "true" : "false";
    }

    componentWillMount() {
        if (localStorage.getItem('user')) {
            this.getPlants();
            this.getAllCustomersAndPlants();
        }
    }

    private getPlants(): void {
        PlantService.getAllEnrich().then((resp: any) => {
            this.setState({
                plants: {plants: resp.data},
                isLoading: false,
                code: resp.code
            });

            // We store globaly
            localStorage.setItem('plants', JSON.stringify(resp.data));
        })
    }

    private getAllCustomersAndPlants(): void {
        PlantService.getAllCustomersAndPlants().then((resp: any) => {
            this.setState({
                customersAndPlants: {customersAndPlants: resp.data}
            });
        })
    }

    private updateSpecificItemPlants(productLineId: string, category: string, subCategory: string): void {
        const {plants}: any = this.state;
        const plant = find(plants.plants, (item: any) => item.code === productLineId);
        const plantCategory = find(plant.categoryList, (item: any) => item.code === category);

        find(plantCategory.subCategoryList, (item: any) => item.code === subCategory).existUnreadComments = false;
    }

    private updateUnReadComments(unReadComments: any): void {
        this.setState({
            unReadComments: unReadComments
        })
    }

    render() {
        const {alert}: any = this.props;
        const {isLoading, plants, unReadComments, customersAndPlants}: any = this.state;
        const userLoggedIn: boolean = !!localStorage.getItem('user');

        if (isLoading) {
            return <Loader> </Loader>
        }

        return (
            <Router history={history} key="main">
                {userLoggedIn ?
                    <React.Fragment>
                        <Menu key="menu" {...plants}
                              getPlants={this.getPlants} {...customersAndPlants}
                              getAllCustomersAndPlants={this.getAllCustomersAndPlants} {...{unReadComments: unReadComments}}
                              updateSpecificItemPlants={this.updateSpecificItemPlants} />

                        <div className="main-container"
                             key="container">
                            <div className="page-container">
                                <div className="content-container">
                                    <div className="col-sm-8 col-sm-offset-2">
                                        {alert.message &&
                                            <div className={'alert '+ alert.type}>{alert.message}</div>
                                        }
                                    </div>
                                    <div>
                                        <Switch>
                                            <Route exact path="/plants/:id" render={()=><HomePage {...plants}/>}/>
                                            <Route exact path="/plants/:id/dashboard" render={()=><DashBoardPage {...plants}/>}/>
                                            <Route exact path="/plants/:id/simulator" render={()=><SimulatorFormPage {...plants}/>}/>
                                            <Route exact path="/plants/:id/simulator/sgraph" render={()=><SimulatorGraphPage {...plants}/>}/>
                                            <Route exact path="/plants/:id/forecast" render={()=><ForecastGraphPage {...plants}/>}/>
                                            <Route exact path="/plants/:id/category/:category/type/:type" render={()=><ChartPage  {...plants} updateUnReadComments={this.updateUnReadComments} {...{unReadComments: unReadComments}}/>}/>
                                            <Route exact path="/plants/:id/category/:category/recommendations/:recommandation" render={()=><RecommendationPage {...plants}/>} />
                                            <Route exact path="/plants/:id/data-upload/data-log" component={DataLogPage} />
                                            <Route exact path="/plants/:id/accountManager" render={()=><AccountManagerPage {...plants}></AccountManagerPage>}/>
                                            <Route exact path="/login" render={()=><RedirectToDashboard {...plants}/>}/>
                                            <Route exact path="/errorPage" render={()=><GenericErrorPage/>}/>
                                            <Route exact path="/error" render={()=><Error {...plants}/>}/>
                                            <Route exact path="/" render={()=><RedirectToDashboard {...plants}/>}/>
                                            <Route exact path="/logout" component={LogoutPage}/>
                                            <Route render={()=><NotFound {...plants}/>} />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <Switch>
                        <Route exact path="/login" render={()=><LoginPage/>}/>
                        <Route exact path="/safelogin" render={()=><SafeLoginPage/>}/>
                        <Route exact path="/forgot-password" render={()=><ForgotPasswordPage/>}/>
                        <Route exact path="/reset-password" render={()=><ResetPasswordPage/>}/>
                        <Route exact path="/userNotExist" render={()=><UserNotExist/>}/>
                        <Route exact path="/errorPage" render={()=><GenericErrorPage/>}/>
                        <Route exact path="/error" render={()=><Error/>}/>
                        <Route exact path="/" render={()=><PublicLandingPage fsspinner={this.fsspinner}/>}/>
                        <Route render={() => <PageNotFound/>}/>
                    </Switch>
                }
            </Router>
        );
    }
}

function mapStateToProps(state:any) {
    const { alert } = state;
    return { alert };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
