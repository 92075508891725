import { BaseService } from './base.services';
import { Comment } from '../_models';
import { Ressource } from '../_helpers';

export class ChatService extends BaseService {
    static async getChatId(plantCode: string) :Promise<Comment[]>{
        let ressource = new Ressource();
        ressource.shapeUrl('/plant/%s/chat', [plantCode], {});
        return this.getAxios.get(ressource.fullUrl, this.requestOptionsGet())
        .then((chat: any) => {
            return chat.data.data;
        });
    }

    static async getMessages(plantCode:string, chatId: number, page: number = 1, size: number = 10) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/chatMessage/list', [plantCode], {chatId, page, size});
      return this.getAxios.get(ressource.fullUrl , this.requestOptionsGet())
        .then((messages: any) => {
          return messages.data;
        })
    }

    static async createMessage(plantCode:string, chatId: number, body: string, isImage: boolean) :Promise<any> {
      let ressource = new Ressource();
      ressource.shapeUrl('/plant/%s/chatMessage', [plantCode], {chatId});
//      body = body.replace(/</g,"&lt;").replace(/>/g,"&gt;");
      return this.getAxios.post(ressource.fullUrl, { body, isImage }, this.requestOptionsPost({ body }))
        .then((resp: any) => {
          return resp.data;
        })
    }
}
