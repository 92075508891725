import * as React from 'react';
import './Listing.css';
import * as moment from 'moment';

interface Iprops {
	handleNewMessage: (more: boolean) => void
}

export class Listing extends React.Component<Iprops> {
	constructor(props: any) {
		super(props);
		this.state = {
			user: JSON.parse(localStorage.getItem('user') || '')
		}
	}

	private getUserDetails(message: any, user: any): any {
		if (user.id === message.postUserId) {
			return (
			  <React.Fragment>
				<p className="single-consult-header">
				  <span className="global-grey"> { moment.utc(message.createdDate).local().format('MM-DD HH:mm') } </span>
				  { message.postUserName }
				  { message.postUserType === 'B' ?
					<span className="comment-user">BASF</span> :
					<span className="basf-first">C</span>
				   }
				 </p>
			  </React.Fragment>
			)
		} else {
			return (
			  <React.Fragment>
				<p className="single-consult-header">
				  { message.postUserType === 'B' ?
					<span className="comment-user">BASF</span> :
					<span className="basf-first">C</span>
				   }
				   { message.postUserName }
				   <span className="global-grey"> { moment.utc(message.createdDate).local().format('MM-DD HH:mm') } </span>
				 </p>
			  </React.Fragment>
			)
		}
	}

	private loadMore(): void {
		const {handleNewMessage}: any = this.props;
		handleNewMessage(true);
	}

	render() {
		const {messages, maxNbOfComment}: any = this.props;
		const {user}: any = this.state;

		return (
			<React.Fragment>
				{
				  messages && messages.length !== maxNbOfComment && <button className="load-more-comment" onClick={() => { this.loadMore() }}> Load More </button>
				}
				{
				  messages && messages.map((message: any) => {
					return (
					  <div className="single-consult" key={message.id}>
						<div className={user.id === message.postUserId ? 'single-consult-right' : 'single-consult-left'}>
						  { this.getUserDetails(message, user) }
							{ message.isImage ?
							  <p key={message.id} className="single-consult-content">
								<img alt="" src={message.body} />
							  </p>
							  :
							  <p key={message.id} className="single-consult-content">
								{ message.body }
							  </p>
							}
						</div>
					  </div>
					)
				  })
				}
			</React.Fragment>
    	);
	}
}
