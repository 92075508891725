import * as React from 'react';
import {connect} from 'react-redux';
import {Header} from '../../_components/Header/Header';
import {Gauges} from '../../_components/Gauges/Gauges';
import {Plant} from '../../_models/';
import {PlantDescription} from '../../_components/PlantDescription/PlantDescription';
import {OperationLimits} from '../../_components/OperationLimits/OperationLimits';
import {withRouter} from 'react-router';
import * as PropTypes from 'prop-types';
import * as content from '../../content/content.json';
import {find} from 'lodash'

class DashBoardPage extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	constructor(props: any) {
		super(props);
		this.state = {
			content: content.pages.dashboard.en,
			dashboard: find(props.plants, (plant: Plant) => {
				return plant.code === props.match.params.id;
			})
		}
		this.setComments = this.setComments.bind(this);
	}

    public componentWillMount(): void {
        document.title = content.global.dashboard;
    }

    // We handle the change of plants
	public componentDidUpdate(prevProps: any): void {
		const {match, plants}: any = this.props;
		if (match.params.id !== prevProps.match.params.id) {
			this.setState({
				dashboard: find(plants, (plant: Plant) => {
					return plant.code === match.params.id;
				})
			})
		}
	}

	private setComments(comments: any[]): void {
		this.setState({
			comments: comments.filter((item) => {
				return item.plantDataId;
			})
		})
	}

	render() {
		const {dashboard, content}: any = this.state;

		return (
			<React.Fragment>
				<Header {...this.props}
                        key="header"
                        setComments={this.setComments} />

				<div className="content-padding" key="content">
					<PlantDescription {...{dashboard: dashboard, content: content}} key="plant-descrition"/>
					<OperationLimits {...{dashboard: dashboard}} key="operation-limits"/>
					<Gauges {...{dashboard: dashboard, content: content}} key="gauges"/>
				</div>
            </React.Fragment>
		);
	}
}

function mapStateToProps(state: any) {
	const {authentication} = state;
	const {user} = authentication;
	return {user};
}

const connectedDashBoard = withRouter(connect(mapStateToProps)(DashBoardPage) as React.ComponentType<any>);
export {connectedDashBoard as DashBoardPage};
