var url: String;

switch (process.env.SERVER_ENV) {
  case "DEV":
    url = "https://dev.my.catalysts.basf.com/basf";
    break;
  case "LOCAL":
    url = "http://localhost:8081/basf";
    break;
  default: url = "/basf"
}

export const api: any = {
  url: url
}
