import * as React from 'react';
import './Header.css';

export class Header extends React.Component {
  constructor(props:any) {
    super(props);
  }

  render() {
    return (
      
      <div className="row admin-popup-header">
        <div className="siteHeader">
          <ol className="breadcrumb">
              <li key='accountManager' className="first"><span>Account Management</span></li>
          </ol>
          <span className="breadcrumb-additionalText">Create Or Modify User accounts</span>
        </div>
      </div>
    );
  }
}
