import * as React from 'react';
import { connect } from 'react-redux';
import { Header } from '../../_components/Header/Header';
import { ChartService } from '../../_services/';
import { Chart, User, Plant } from '../../_models/';
import { LinearChart } from '../../_components/Charts/LinearChart';
import { Loader } from '../../_components/Common/Loader/newLoader';
import './chart.css';
import { extend, filter, map, omit, find } from 'lodash';
import * as moment from 'moment';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import * as content from '../../content/content.json';
import { PlantHelper } from '../../_helpers';
import { permissionsConstants } from '../../_constants';


class ChartPage extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props: any) {
        super(props);
        const {match, location, user} = props;
        this.state = {
            match: match,
            location: location,
            isLoading: true,
            user: new User(user),
            showPopupChoise: false,
            onlyNormal: true,
            comments: [],
            commentForm: {
                data: null,
                isLoaded: null
            },
            charts: undefined,
            chartsLimit: undefined,
            chartsSelected: undefined,
            content: content.pages.chart.en,
            currentPlant: find(props.plants, (plant: Plant) => {
                return plant.code === props.match.params.id;
            })
        }

        this.handleExporting = this.handleExporting.bind(this);
        this.setCommentData = this.setCommentData.bind(this);
        this.setComments = this.setComments.bind(this);
        this.showOrCreateComment = this.showOrCreateComment.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.resetExport = this.resetExport.bind(this);
        this.updateData = this.updateData.bind(this);
        this.setOnlyNormal = this.setOnlyNormal.bind(this);
        this.setChartsSelected = this.setChartsSelected.bind(this);
    }

    public componentDidMount(): void {
        this.updateData();
    }

    public componentWillReceiveProps(nextProps: any): any {
        const {match}: any = this.props;
        if (nextProps.match.url === match.url) {
            return;
        }

        this.setState({
            isLoading: true,
            match: nextProps.match,
            location: nextProps.location,
            commentForm: {data: null, isLoaded: false},
            comments: []
        });

        const {onlyNormal}: any = this.state;
        this.getChart(nextProps.match.params.id, nextProps.match.params.category, nextProps.match.params.type, onlyNormal);
    }

    private updateData() {
        const {match}: any = this.props;
        const {onlyNormal}: any = this.state;
        this.getChart(match.params.id, match.params.category, match.params.type, onlyNormal);
    }

    private setOnlyNormal() {
        const {onlyNormal}: any = this.state;
        this.setState({
            onlyNormal: !onlyNormal
        })
    }

    private setCommentData(data: any): any {
        const {user}: any = this.state;
        const {location}: any = this.props;

        // If you are a non authorize user and you want to create data
        // this won't work
        const permissionToSetComment: boolean = user.lookUpPermission(permissionsConstants.COMMENT_ADD, permissionsConstants.USER, PlantHelper.getPlantCode(location));
        if (!permissionToSetComment && !data.create) {
            return true
        }

        this.setState({
            commentForm: {
                data: data,
                isLoaded: data.create || data.hasBeenCreated ? false : true,
                ...!permissionToSetComment ? {listing: true, isLoaded: true} : {}
            },
            showPopupChoise: data.create && permissionToSetComment ? true : false
        })
    }

    private closePopup(): void {
        this.setState({
            showPopupChoise: false
        })
    }

    private showOrCreateComment(create?: boolean): void {
        const {showPopupChoise, commentForm}: any = this.state;
        this.setState({
            showPopupChoise: !showPopupChoise,
            commentForm: extend(commentForm, {listing: !create, isLoaded: true})
        })
    }

    private setComments(comments: any[]): void {
        let commentsData = filter(comments, (item: any) => {
            return item.plantDataId;
        })

        // We don't want to save havy data in memory
        commentsData = map(commentsData, (item: any) => {
            return omit(item, ['body', 'postDate', 'lastModifiedDate']);
        })

        this.setState({
            comments: commentsData
        })
    }

    private getChart(plantCode: string, categoryCode: string, subCategoryCode: string, onlyNormal: boolean): void {
        const {currentPlant}: any = this.state;
        let startUpDate: any = currentPlant && moment(currentPlant.startUpDate).format('YYYY-MM-DD');

        ChartService.get(plantCode, categoryCode, subCategoryCode, onlyNormal, startUpDate).then((resp: any) => {
            const {match}: any = this.state;
            if (match.params.type == subCategoryCode) {
                const allCharts: any = resp.data && this.buildCharts(resp.data);
                this.setState({
                    charts: allCharts && allCharts.charts,
                    chartsLimit: allCharts && allCharts.chartsLimit,
                    code: resp.code,
                    isLoading: false,
                    chartsSelected: allCharts && allCharts.charts && allCharts.charts.map((item: any) => {
                        return item.name;
                    }),
                    firstDay: allCharts && allCharts.charts && allCharts.charts[0] && allCharts.charts[0].firstDay.format('YYYY-MM-DD')
                });
            }
        })
    }

    private setChartsSelected(selectedCharts: any): void {
        this.setState({
            chartsSelected: selectedCharts
        })
    }

    private buildCharts(apiCharts: any): any {
        let charts: Chart[] = [];
        let chartsMinMax: Chart[] = [];
        apiCharts.forEach((chart: any) => {
            if (chart.plantDatas) {
                charts.push(new Chart(chart.name, chart.dataCode, chart.color, chart.range, chart.unit, chart.plantDatas, chart.minValue, chart.maxValue));
            } else if (chart.operationLimit) {
                if (chart.operationLimit.name == "Conversion") {
                    chartsMinMax.push(new Chart("Min & Max Conversion", chart.dataCode, chart.color, chart.range, chart.unit, chart.plantDatas, chart.minValue, chart.maxValue, chart.operationLimit));
                } else {
                    chartsMinMax.push(new Chart(chart.name, chart.dataCode, chart.color, chart.range, chart.unit, chart.plantDatas, chart.minValue, chart.maxValue, chart.operationLimit));
                }
            }
        })
        return {charts: charts, chartsLimit: chartsMinMax};
    }

    private handleExporting(event: string): void {
        this.handleFileType(event, ["png", "jpeg", "pdf", "svg"]);
    }

    private handleFileType(event: string, eventNames: Array<string>): any {
        eventNames.map((eventName: any) => {
            if (event == eventName) {
                this.setState({exporting: {[eventName]: true}});
            }
        })
    }

    private resetExport(): any {
        this.setState({exporting: false});
    }

    render() {
        const {isLoading, code, comments, showPopupChoise, content}: any = this.state;
        const {updateUnReadComments, unReadComments}: any = this.props;
        const {addComment, seeComment, cancel}: any = content;
        let showLoading = isLoading || code === 802

        if (showLoading) {
            return (
                <div>
                    <Loader isLoading={isLoading} code={code}> </Loader>
                </div>
            );
        }

        return (
            <div>
                <Header {...this.state} {...this.props}
                        {...{unReadComments: unReadComments}}
                        onClickExporting={this.handleExporting}
                        key="header"
                        setComments={this.setComments}
                        setCommentData={this.setCommentData}
                        updateData={this.updateData}
                        updateUnReadComments={updateUnReadComments} />

                {showPopupChoise &&
                    <div className="popup-choise-comment">
                        <div className="popup-choise-comment-content row">
                            <div>
                                <button type="button" onClick={() => this.showOrCreateComment(true)}
                                        className="purple-button add-comment btn btn-primary btn-block"><span
                                    className="plus-icon "><span className="glyphicon glyphicon-plus" /></span><span>{addComment}</span></button>
                            </div>
                            <div>
                                <button onClick={() => this.showOrCreateComment()}
                                        className="comment-listing btn btn-primary btn-block"> {seeComment} </button>
                            </div>
                            <div>
                                <button onClick={() => this.closePopup()}
                                        className="cancel-deletion btn-block"> {cancel} </button>
                            </div>
                        </div>
                    </div>
                }

                {comments &&
                    <LinearChart {...this.state}
                                 key="performance-overview"
                                 onChangeDate={this.getChart}
                                 setCommentData={this.setCommentData}
                                 onResetExport={this.resetExport}
                                 setOnlyNormal={this.setOnlyNormal}
                                 updateChartData={this.updateData}
                                 setChartsSelected={this.setChartsSelected} />
                }
            </div>
        );
    }
}

function mapStateToProps(state:any) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedPChartPage = withRouter(connect(mapStateToProps)(ChartPage) as React.ComponentType<any>);
export { connectedPChartPage as ChartPage };
